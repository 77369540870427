<div #divCardPrincipal class="div-card-principal">
  <div>
    <div class="row">
      <div class="col-sm-4">
      </div>
    </div>
    <div class="row">
      <div class="col-sm-3">
        <div class="div-info">
          <volvo-typography variant='heading6'>
            {{'Limite desconto' | translate}}
          </volvo-typography>
          <volvo-system-icon icon="info" size="24" class="margin-left-10 min-width-30" ngbTooltip="{{'Tooltip Limite desconto' | translate }}"></volvo-system-icon>
        </div>
      </div>
      <div class="col-sm-2 div-input">
        <input class="input-percent" type="number" [maxlength]="3" [(ngModel)]="discountLimit" min="0" max="100" step="1" (blur)="onDiscountLimitBlur($event)">
        <div class="div-text div-percent">%</div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-3">
        <div class="div-info">
          <volvo-typography variant='heading6'>
            {{'Rentabilidade' | translate}}
          </volvo-typography>
          <volvo-system-icon icon="info" size="24" class="margin-left-10 min-width-30" ngbTooltip="{{'Tooltip Rentabilidade' | translate }}"></volvo-system-icon>
        </div>
      </div>
      <div class="col-sm-2 div-input">
        <input class="input-percent" type="number" [maxlength]="3" [(ngModel)]="profitability" min="-999" max="999" step="1" (blur)="onProfitabilityBlur($event)">
        <div class="div-text div-percent">%</div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-3">
        <div class="div-info-functional-box">
          <volvo-typography variant='heading6'>
            {{'Caixa Funcional cadastrada' | translate}}
          </volvo-typography>
          <volvo-system-icon icon="info" size="24" class="margin-left-10 min-width-30" ngbTooltip="{{'Tooltip Caixa Funcional cadastrada' | translate }}"></volvo-system-icon>
        </div>
      </div>
      <div class="col-sm-2 set-width div-input-functional-box">
        <input class="input-functional-box"
          type="text"
          [placeholder]="'email@volvo.com' | translate"
          [(ngModel)]="functionalBox"
          (input)="changeEmail($event)">
        <div class="div-error" *ngIf="invalidFunctionalBox">
            {{'E-mail inválido' | translate}}
         </div>
      </div>
    </div>
  </div>
  <div class="div-filler"></div>
  <div class="row ">
    <div class="div-button">
      <volvo-button variant="primary" size="large" (click)="goBack()" class="back-button">{{'Voltar' | translate}}</volvo-button>
      <volvo-button variant="primary" size="large" (click)="save()">{{'Salvar' | translate}}</volvo-button>
    </div>
  </div>
</div>
