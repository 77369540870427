import { Component, Input } from '@angular/core';
import { WarningPart } from '../../models/warningPart';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-warnings-part',
  templateUrl: './warnings-part.component.html',
  styleUrls: ['./warnings-part.component.css']
})
export class WarningsPartComponent {
  @Input() warningsPart!: WarningPart[];
  @Input() titleText!: string;
  @Input() messageText!: string;

  constructor(private activeModal: NgbActiveModal) { }

  public dismiss() {
    this.activeModal.dismiss();
  }
}
