import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NewsLetter } from '../../models/news-letter';
import { MatTableDataSource } from '@angular/material/table';
import { GridDataSource } from 'src/app/services/grid-data-source.service';
import { DecimalPipe } from '@angular/common';
import { ParametersService } from '../../services/parameters.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommercialParameters } from '../../models/commercial-parameters';
import { GetTranslationService } from 'src/app/shared/utils/get-translation.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { CommercialParametersService } from 'src/app/shared/services/commercial-parameters.service';
import { LocalStorageUtils } from 'src/app/shared/utils/localstorage';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-parameters-products-and-prices',
  templateUrl: './parameters-products-and-prices.component.html',
  styleUrls: ['./parameters-products-and-prices.component.css'],
  providers: [GridDataSource, DecimalPipe]
})
export class ParametersProductsAndPricesComponent implements OnInit {

  @Output() clickMenuItem = new EventEmitter<string>();

  newsLetters!: NewsLetter[];
  dataSource = new MatTableDataSource<NewsLetter>(this.newsLetters);
  displayedColumns: string[] = ['email'];
  filterObject: any = {};
  searchTerm!: string;
  commercialParameters!: CommercialParameters;
  functionalBox!: string;
  editing: boolean = false;
  invalidFunctionalBox: boolean = true;
  volvoDomain: string = '@volvo.com';
  isPricingManager = false;

  public LocalStorage = new LocalStorageUtils();

  constructor(
    public service: GridDataSource<NewsLetter>,
    public dataService: ParametersService,
    private spinner: NgxSpinnerService,
    public getTranslationSvc: GetTranslationService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    public commercialParametersService: CommercialParametersService){
      this.initialize();
  }

  ngOnInit(): void {
    this.clickMenuItem.emit('productsParameters');
  }

  initialize(){
    this.setIsPricingManager();
    this.getFunctionalBox();
    this.getNewsletters();
  }

  getNewsletters(){
    this.spinner.show();
    this.dataService.getNewsletters().subscribe(resp =>{
      this.newsLetters = resp.data;
      this.dataSource = new MatTableDataSource<NewsLetter>(this.newsLetters);
      this.spinner.hide();
    });
  }

  getFunctionalBox(){
    this.spinner.show();
    this.commercialParametersService.getCommercialParameters().subscribe(response =>{
      this.spinner.show();
       this.commercialParameters = response.data;
       this.functionalBox = this.commercialParameters.emailCurrentPromotion;
       this.spinner.hide();
    });
  }

  search(){
    this.spinner.show();
    this.dataService.searchNewsletters(this.searchTerm).subscribe(resp =>{
      this.newsLetters = resp.data;
      this.dataSource = new MatTableDataSource<NewsLetter>(this.newsLetters);
      this.spinner.hide();
    },
    error => {
      this.newsLetters = []
      this.dataSource = new MatTableDataSource<NewsLetter>(this.newsLetters);
      this.toastr.error(error.error.message.toString(), this.getTranslationSvc.getTranslation('Erro'));
      this.spinner.hide();
    });
  }

  clickEdit(){
    this.editing = true;
    this.validateFunctionalBox();
  }

  clickSave(){
    if(this.invalidFunctionalBox){
      return;
    }

    this.confirm(this.getTranslationSvc.getTranslation("Salvar"), this.getTranslationSvc.getTranslation("Deseja salvar a alteração?"))
        .then((confirmed) => {
          if(confirmed)
            this.save();
        });
  }

  changeEmail(event: any){
    this.validateFunctionalBox();
  }

  validateFunctionalBox(){
    this.invalidFunctionalBox = true;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if(!this.functionalBox){
      return;
    }

    if(!emailRegex.test(this.functionalBox)){
      return;
    }

    if(!this.functionalBox.toLowerCase().endsWith(this.volvoDomain)){
      return;
    }

    this.invalidFunctionalBox = false;
  }

  save(){
    this.spinner.show();
    this.commercialParameters.emailCurrentPromotion = this.functionalBox;
    this.dataService.putCommercialParameters(this.commercialParameters).subscribe((success) =>{
      this.saveSuccess(success);
    },
    (error) =>{
      this.showErrorMessage(error);
    }
   );
  }

  showErrorMessage(error: any){
    this.spinner.hide();

    if(error.error.message){
      this.toastr.error(error.error.message.toString(), this.getTranslationSvc.getTranslation('Erro'));
      return;
    }

    if(error.error.data){
      this.toastr.error(error.error.data.toString(), this.getTranslationSvc.getTranslation('Erro'));
      return;
    }

    if(!error.error.errors){
      this.toastr.error(this.getTranslationSvc.getTranslation('Um erro não identificado ocorreu'), this.getTranslationSvc.getTranslation('Erro'));
    }

    if(!error.error.errors.title){
      this.toastr.error(this.getTranslationSvc.getTranslation('Um erro não identificado ocorreu'), this.getTranslationSvc.getTranslation('Erro'));
    }

    this.toastr.error(error.error.errors.title.toString(), this.getTranslationSvc.getTranslation('Erro'));
  }

  saveSuccess(success: any){
    this.spinner.hide();
    if(!success.success)
      return;

    if(success.success == false)
      return;

    this.toastr.success(this.getTranslationSvc.getTranslation('Caixa funcional salva com sucesso!'), this.getTranslationSvc.getTranslation('Sucesso!'));
    this.editing = false;
  }

  confirm(
    title: string,
    message: string,
    btnOkText: string = this.getTranslationSvc.getTranslation("Sim"),
    btnCancelText: string = this.getTranslationSvc.getTranslation("Não"),
    dialogSize: 'sm' | 'lg' = 'lg'): Promise<boolean> {
    const modalRef = this.modalService.open(ConfirmationDialogComponent, { size: dialogSize });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.btnOkText = btnOkText;
    modalRef.componentInstance.btnCancelText = btnCancelText;

    return modalRef.result;
  }

  setIsPricingManager(){
    this.isPricingManager = this.LocalStorage.searchGroup(environment.PRINCING_MANAGER_GROUP_ID);
  }
}
