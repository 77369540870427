import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GridDataSource } from 'src/app/services/grid-data-source.service';
import { DealerGroup } from '../../../models/dealer-group';
import { MatTableDataSource } from '@angular/material/table';
import { DecimalPipe } from '@angular/common';
import { CommercialParameters } from '../../../models/commercial-parameters';
import { GetTranslationService } from 'src/app/shared/utils/get-translation.service';

@Component({
  selector: 'app-parameters-planning',
  templateUrl: './parameters-planning.component.html',
  styleUrls: ['./parameters-planning.component.css'],
  providers: [GridDataSource, DecimalPipe]
})
export class ParametersPlanningComponent {
  @Input() dealerGroups: DealerGroup[] = [];
  @Input() dataSource = new MatTableDataSource<DealerGroup>(this.dealerGroups);
  @Input() commercialParameters!: CommercialParameters;
  @Output() saveEvent = new EventEmitter();
  @Output() editEvent = new EventEmitter();
  @Output() goBackEvent = new EventEmitter();

  firmPeriodChecked: boolean = false;
  firmPeriod!: number;
  weekQuantityChecked: boolean = false;
  weekQuantity!: number;
  displayedColumns: string[] = ['dealerGroupDescription', 'dayWeek'];
  forecastLimit!: number;
  

  weekDays = [
    { name: this.getTranslationSvc.getTranslation('Segunda-feira'), value: 1 },
    { name: this.getTranslationSvc.getTranslation('Terça-feira'), value: 2 },
    { name: this.getTranslationSvc.getTranslation('Quarta-feira'), value: 3 },
    { name: this.getTranslationSvc.getTranslation('Quinta-feira'), value: 4 },
    { name: this.getTranslationSvc.getTranslation('Sexta-feira'), value: 5 }
  ];

  constructor(public getTranslationSvc: GetTranslationService){
  }

  fillParameters(): void {
    if(this.commercialParameters){
      this.weekQuantityChecked = this.commercialParameters.weeksPlanningActive;
      this.firmPeriodChecked = this.commercialParameters.firmPeriodActive;
      

      this.weekQuantity = this.commercialParameters.weeksPlanning;
      this.firmPeriod = this.commercialParameters.firmPeriod;
      this.forecastLimit = this.commercialParameters.forecastLimit;
    }
  }

  weekQuantityCheckChange(){
    this.setEdited();
    this.weekQuantityChecked = !this.weekQuantityChecked;
    this.commercialParameters.weeksPlanningActive = this.weekQuantityChecked;
  }

  firmPeriodCheckChange(){
    this.setEdited();
    this.firmPeriodChecked = !this.firmPeriodChecked;
    this.commercialParameters.firmPeriodActive = this.firmPeriodChecked;
  }

  onWeekQuantityBlur(event: Event) {
    this.setEdited();
    this.weekQuantity = this.sanitizeValue(event);
    this.commercialParameters.weeksPlanning = this.weekQuantity;
  }

  onFirmPeriodBlur(event: Event) {
    this.setEdited();
    this.firmPeriod = this.sanitizeValue(event);
    this.commercialParameters.firmPeriod = this.firmPeriod;
  }

  sanitizeValue(event: Event): number {
    const inputValue = (event.target as HTMLInputElement).value;
    const sanitizedValue = inputValue.split('.')[0].replace(/[^\d]/g, '');

    let numberSanitized = Number(sanitizedValue)

    if(numberSanitized > 25)
      numberSanitized = 25;

      return numberSanitized;
  }

  save(){
    this.saveEvent.emit();
  }

  setEdited(){
    this.editEvent.emit();
  }

  changeDayWeek(){
    this.setEdited();
  }

  goBack(){
    this.goBackEvent.emit();
  }

  onForecastLimitBlur(event: Event) {
    this.setEdited();
    this.forecastLimit = this.sanitizeValue(event);
    this.commercialParameters.forecastLimit = this.forecastLimit;
  }


}
