<div class="div-card-principal">
  <h1 class="brand-title">{{'Gerenciar Vínculos Usuários X Concessionárias' | translate}}</h1>
  <div class="separator-breadcrumb border-top"></div>
  <div>
    <div>
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="['../home']" routerLinkActive="router-link-active">{{'Home'}}</a>
            </li>
            <li class="active breadcrumb-item" aria-current="page">{{'Gerenciar Vínculos Usuários X Concessionárias' | translate}}</li>
          </ol>
        </nav>
      </div>
      <app-user-dealer-grid *ngIf="isSalesRepresentativeAdmin"></app-user-dealer-grid>
      <app-not-found *ngIf="!isSalesRepresentativeAdmin" firstMessage="Apenas Representante Comercial com perfil administrador tem acesso a esta função." secondMessage="Caso você precise de acesso, deverá solicitar via Service Now."></app-not-found>
  </div>
</div>
