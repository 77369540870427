import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseService } from "src/app/services/base.service";
import { PagedResponse } from "src/app/services/grid-data-source.service";
import { Observable, catchError, map } from "rxjs";
import { environment } from "src/environments/environment";
import { Dealer } from "../models/dealer";

@Injectable({
  providedIn: 'root'
})

export class DealerService extends BaseService {

  constructor(private http: HttpClient) {
    super();
  }

  listDealersByUser(): Observable<PagedResponse<Dealer>> {
    return this.http
    .get<PagedResponse<Dealer>>(`${environment.UrlApiPartsOrderBook}Dealer/byUser`, {headers: super.GetAuthTokenHeader().headers})
    .pipe(
      map(super.extract),
      catchError(super.serviceError));
  }
}
