<div class="modal-header">
  <h1 class="brand-title">{{title}}</h1>
  <button type="button" class="close" aria-label="Close" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <span style="white-space: pre-wrap">{{ message }}</span>
</div>
<div class="modal-footer">
  <volvo-button variant="secondary" class="button-icon" (click)="decline()">{{ btnCancelText }}</volvo-button>
  <volvo-button variant="primary" class="button-icon" (click)="accept()">{{ btnOkText }}</volvo-button>
</div>
