<div class="div-card-principal">
  <div class="row col-sm-12">
    <div class="col-sm-9">
      <h1 class="brand-title">{{'Parâmetros - Produtos e Preços' | translate}}</h1>
    </div>
  </div>
  <div class="separator-breadcrumb border-top"></div>
  <div>
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['../home']" routerLinkActive="router-link-active">{{'Home' | translate}}</a>
        </li>
        <li class="active breadcrumb-item" aria-current="page">{{'Parâmetros - Produtos e Preços' | translate}}</li>
      </ol>
    </nav>
  </div>
  <app-not-found *ngIf="!isPricingManager" firstMessage="Apenas Pricing Manager tem acesso a esta função." secondMessage="Caso você precise de acesso, deverá solicitar via Service Now."></app-not-found>
  <div *ngIf="isPricingManager">
    <div class="margin-top-20">
      <div>
        <volvo-typography variant='heading6'>
          {{'Caixa Funcional cadastrada' | translate}}
        </volvo-typography>
      </div>
      <div class="div-functional-box">
        <div class="set-width div-input-functional-box">
          <input class="input-functional-box"
            type="text"
            [placeholder]="'email@volvo.com' | translate"
            [(ngModel)]="functionalBox"
            [disabled]="!editing"
            (input)="changeEmail($event)">
        </div>
        <div class="div-input-functional-box">
            <volvo-system-icon *ngIf="!editing" (click)="clickEdit()" class="icon-edit" icon="pen" size="24"></volvo-system-icon>
            <volvo-system-icon *ngIf="editing" (click)="clickSave()" class="icon-edit" icon="floppy-disk" size="24"></volvo-system-icon>
        </div>
      </div>
      <div class="div-error" *ngIf="invalidFunctionalBox && editing">
        {{'E-mail inválido' | translate}}
     </div>
    </div>
    <div class="margin-top-20">
      <div>
        <volvo-typography variant='heading6'>
          {{'E-mails cadastrados Boletim' | translate}}
        </volvo-typography>
      </div>
      <div class="set-width">
        <div class="div-search">
          <input class="input-search" type="text"
          placeholder="{{'Buscar e-mail' | translate}}"
          ngbTooltip="{{'Para buscar, digite um dado, conforme título da tabela abaixo' | translate}}"
          [(ngModel)]="searchTerm"
          (keyup.enter)="search()">
          <volvo-system-icon class="icon-search" icon="search" size="24" (click)="search()"></volvo-system-icon>
        </div>
      </div>
    </div>
    <div class="set-width div-newsletter-table">
      <mat-card>
        <mat-card-content>
          <table mat-table [dataSource]="dataSource">
            <ng-container matColumnDef="email">
              <th mat-header-cell *matHeaderCellDef> {{'Endereço e-mail' | translate}} </th>
              <td mat-cell *matCellDef="let newsLetterPromotion"> {{newsLetterPromotion.email}} </td>
            </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row  class="queue-element-row" *matRowDef="let row; columns: displayedColumns;"></tr>
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="displayedColumns.length">{{'E-mail não encontrado.' | translate}}</td>
            </tr>
          </table>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <div class="row " *ngIf="isPricingManager">
    <div class="div-button">
      <volvo-button variant="primary" size="large" class="back-button" [routerLink]="['/home']">{{'Voltar' | translate}}</volvo-button>
    </div>
  </div>
</div>
