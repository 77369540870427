<div class="modal-header">
  <h1 class="brand-title">{{'Excluir planejamento' | translate}}</h1>
  <button type="button" class="close" aria-label="Close" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <span style="white-space: pre-wrap">{{ 'Deseja excluir:' | translate }}</span>
</div>
<div class="options">
  <div class="row option">
    <label>
      <input type="radio" [(ngModel)]="selectedOption" value="false" class="radio-option">{{ 'Somente o planejamento' | translate }}
    </label>
  </div>
  <div class="row option">
    <label>
      <input type="radio" [(ngModel)]="selectedOption" value="true" class="radio-option">{{ 'Planejamento e o orçamento relacionado' | translate }}
    </label>
  </div>
</div>
<div class="modal-footer">
  <volvo-button variant="secondary" class="button-icon" (click)="cancel()">{{ 'Cancelar' | translate }}</volvo-button>
  <volvo-button variant="primary" class="button-icon" (click)="deletePlanning()">{{ 'OK' | translate }}</volvo-button>
</div>
