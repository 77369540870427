<div #divCardPrincipal class="div-card-principal">
  <h1 class="brand-title">{{headerTitle}}</h1>
  <div class="separator-breadcrumb border-top"></div>
  <div class="div-breadcrumb">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['../../home']" routerLinkActive="router-link-active">{{'Home'}}</a>
        </li>
        <li class="breadcrumb-item"><a [routerLink]="['../../planning']" routerLinkActive="router-link-active">{{'Gerenciar Planejamentos' | translate}}</a>
        </li>
        <li class="active breadcrumb-item" aria-current="page">{{headerTitle}}</li>
      </ol>
    </nav>
    <div class="div-export">
      <div class="div-export-icon" (click)="downloadReport()"><volvo-system-icon icon="export" size="24"></volvo-system-icon></div>
      <div class="div-export-span" (click)="downloadReport()"><span>{{'Gerar arquivo csv' | translate}}</span></div>
    </div>
  </div>
  <app-not-found *ngIf="!canAccess" firstMessage="Apenas Representante Comercial tem acesso a esta função." secondMessage="Caso você precise de acesso, deverá solicitar via Service Now."></app-not-found>
  <div *ngIf="canAccess">
    <div class="div-header" *ngIf="planning">
      <div class="div-header-item">
        <div class="row first-row-header-item">
          <volvo-typography variant='heading6'>
            {{'Grupo Econômico' | translate}}
          </volvo-typography>
        </div>
        <div class="row">
          <volvo-typography variant='body'>
            {{planning.quotation.dealer?.dealerGroupName}}
          </volvo-typography>
        </div>
      </div>
      <div class="div-header-item">
        <div class="row first-row-header-item">
          <volvo-typography variant='heading6'>
            {{'Concessionária' | translate}}
          </volvo-typography>
        </div>
        <div class="row">
          <volvo-typography variant='body'>
            {{planning.quotation.dealer?.corporateName}}
          </volvo-typography>
        </div>
      </div>
      <div class="div-header-item">
        <div class="row first-row-header-item">
          <volvo-typography variant='heading6'>
            {{'Orçamento' | translate}}
          </volvo-typography>
        </div>
        <div class="row">
          <volvo-typography variant='body'>
            {{planning.quotation.name}}
          </volvo-typography>
        </div>
      </div>
      <div class="div-header-item">
        <div class="row first-row-header-item">
          <volvo-typography variant='heading6'>
            {{'Código Orçamento' | translate}}
          </volvo-typography>
        </div>
        <div class="row">
          <volvo-typography variant='body' class="click-cell" (click)="onQuotationClick(planning.quotation.id)">
            {{planning.quotation.code}}
          </volvo-typography>
        </div>
      </div>
      <div class="div-header-item">
        <div class="row first-row-header-item">
          <volvo-typography variant='heading6'>
            {{'Status Planejamento' | translate}}
          </volvo-typography>
        </div>
        <div class="row">
          <volvo-typography variant='body' [ngClass]="getStatusColorClass(planning.status?.id)">
            {{planning.status.name | translate}}
          </volvo-typography>
        </div>
      </div>
      <div class="div-header-item">
        <div class="row first-row-header-item">
          <volvo-typography variant='heading6'>
            {{'Dt. Planejamento' | translate}}
          </volvo-typography>
        </div>
        <div class="row">
          <volvo-typography variant='body'>
            {{planning.createdAt | date:'dd/MM/yyyy'}}
          </volvo-typography>
        </div>
      </div>
    </div>
    <div class="div-subtitles">
      <span class="span-subtitle">{{'Legenda:' | translate}}</span>
      <div class="subtitle-container padding-top-5">
        <div class="subtitle-input-example blue-border margin-right-5"></div>
        <span>{{'Semana Atual' | translate}}</span>
      </div>
      <div class="subtitle-container padding-top-5">
        <div class="subtitle-input-example blue-border margin-right-5"></div>
        <span class="span-plus">+</span>
        <div class="subtitle-input-example orange-border margin-right-5"></div>
        <span>{{'Período firme' | translate}}</span>
      </div>
      <div class="subtitle-container">
        <i class="bi bi-exclamation-circle-fill orange-alert exclamation-icon-size margin-right-5"></i>
        <span class="padding-top-5">{{'Peça em outros planejamentos' | translate}}</span>
      </div>
      <div class="subtitle-container">
        <i class="bi bi-exclamation-triangle-fill orange-alert exclamation-icon-size margin-right-5"></i>
        <span class="padding-top-5">{{'Situação da peça alterada' | translate}}</span>
      </div>
      <div class="subtitle-container">
        <i class="bi bi-exclamation-diamond-fill red-alert exclamation-icon-size margin-right-5"></i>
        <span class="padding-top-5">{{'Peça devolvida pelo mainframe' | translate}}</span>
      </div>
    </div>
    <!-- <div class="div-mat-card">
      <mat-card class="mat-card-style">
        <mat-card-content class="mat-card-content-style"> -->
          <div #divPlanningGrid class="div-planning-grid" (scroll)="onDivScroll()">
            <div #planningGrid class="planning-grid">
              <table class="table-grid-planning" mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)">
                <ng-container matColumnDef="partAlert" sticky>
                  <th mat-header-cell *matHeaderCellDef></th>
                  <td mat-cell *matCellDef="let partPlanning">
                    <div class="alert-content">
                      <i class="bi bi-exclamation-circle-fill orange-alert exclamation-icon-size cursor-pointer" *ngIf="showOtherPlannings(partPlanning.planningsWithPart)" (click)="openOtherPlanningsModal(partPlanning.planningsWithPart)"></i>
                      <i class="bi bi-exclamation-triangle-fill orange-alert exclamation-icon-size cursor-pointer" *ngIf="showWarning(partPlanning.warningsPart)" (click)="openWarningsModal(partPlanning.warningsPart)"></i>
                    </div>
                  </td>
                </ng-container>
                <ng-container matColumnDef="partCode" sticky>
                  <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by partCode" class="min-width-120"> {{'Código da peça' | translate}} </th>
                  <td mat-cell *matCellDef="let partPlanning" class="click-cell" (click)="openPartDetailsModal(partPlanning)"> {{partPlanning.partQuotation.part.partCode}} </td>
                </ng-container>
                <ng-container matColumnDef="description" sticky>
                  <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by dealer" class="min-width-180"> {{'Descrição' | translate}} </th>
                  <td mat-cell *matCellDef="let partPlanning"> {{partPlanning.partQuotation.part.description}} </td>
                </ng-container>
                <ng-container matColumnDef="quotationQuantity" sticky>
                  <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by quotationQuantity" class="min-width-110"> {{'Qtd Orç' | translate}} </th>
                  <td mat-cell *matCellDef="let partPlanning"> {{partPlanning.partQuotation.amount}} </td>
                </ng-container>
                <ng-container matColumnDef="planningQuantity" sticky>
                  <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by planningQuantity" class="min-width-110"> {{'Qtd Plan' | translate}} </th>
                  <td mat-cell *matCellDef="let partPlanning"> {{partPlanning.amount}} </td>
                </ng-container>
                <div *ngFor="let week of weekColumns; let columnIndex = index">
                  <ng-container matColumnDef="{{week.amountColumnDef}}">
                    <th mat-header-cell *matHeaderCellDef class="min-width-110"> {{week.amountColumnName | translate}} </th>
                    <td mat-cell *matCellDef="let partPlanning; let rowIndex = index">
                      <input class="value-input" type="number" matInput min="0" max="999999" step="1"
                      matTooltip="{{ getTooltipContent(rowIndex, columnIndex) }}"
                      matTooltipClass="white-space-pre-line"
                      [(ngModel)]="planning.partsPlanning[rowIndex].weeksPartPlanning[columnIndex].amount"
                      (blur)="onInputChange($event, rowIndex, columnIndex)"
                      [ngStyle]="{
                        'border': getStatusBorder(planning.partsPlanning[rowIndex].weeksPartPlanning[columnIndex])
                      }"
                      [disabled]="disableWeek(planning.partsPlanning[rowIndex].weeksPartPlanning[columnIndex].status.id)">
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="{{week.dateColumnDef}}">
                    <th mat-header-cell *matHeaderCellDef class="min-width-110"> {{week.dateColumnName | translate}} </th>
                    <td mat-cell *matCellDef="let partPlanning; let rowIndex = index">
                      <mat-form-field class="date-filter">
                        <input matInput
                        [matDatepicker]="pickerReleaseDate"
                        (dateInput)="inputReleaseDate('input', $event)"
                        (click)="pickerReleaseDate.open()"
                        [placeholder]="'Dt. Lib' | translate"
                        [min]="planning.partsPlanning[rowIndex].weeksPartPlanning[columnIndex].initialDateWeek"
                        [max]="planning.partsPlanning[rowIndex].weeksPartPlanning[columnIndex].finalDateWeek"
                        [(ngModel)]="planning.partsPlanning[rowIndex].weeksPartPlanning[columnIndex].releaseDate" class="height30"
                        [disabled]="disableWeek(planning.partsPlanning[rowIndex].weeksPartPlanning[columnIndex].status.id)">
                        <!-- <mat-datepicker-toggle matSuffix [for]="pickerReleaseDate"></mat-datepicker-toggle> -->
                        <mat-datepicker #pickerReleaseDate></mat-datepicker>
                      </mat-form-field>
                    </td>

                  </ng-container>
                </div>
              <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
              <tr mat-row  class="queue-element-row" *matRowDef="let row; columns: displayedColumns;"></tr>
                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="displayedColumns.length" *ngIf="!loadingParts">{{'Planejamento não encontrado.' | translate}}</td>
                </tr>
              </table>
            </div>
          </div>
          <volvo-progress-bar *ngIf="loadingParts"></volvo-progress-bar>

          <!-- <mat-paginator [length]="service.total | async" [pageSizeOptions]="[10,20,50,100]" (page)="onPageChange($event)"
          showFirstLastButtons
          aria-label="Select page of quotations">
          </mat-paginator> -->
        <!-- </mat-card-content>
      </mat-card>
    </div> -->
    <div #fillerPlanningGrid class="filler-planning-grid"></div>
    <div class="div-footer-planing">
      <div class="div-links-footer-planning">
        <div class="div-inconsistencies" (click)="openInconsistenciesModal()" *ngIf="numberOfInconsistencies !== ''">
          <i class="bi bi-exclamation-diamond-fill red-alert exclamation-icon-size"></i>
          <div class="text-link red-link">
            {{'Inconsistências' | translate}}
          </div>
          <div class="circle">
            {{numberOfInconsistencies}}
          </div>
        </div>
        <div class="div-parts-info" (click)="openPartsInfoModal()">
          <i class="bi bi-exclamation-circle-fill blue-alert exclamation-icon-size"></i>
          <div class="text-link blue-link">
            {{'Informações gerais das peças' | translate}}
          </div>
        </div>
      </div>
      <div class="button-panel">
        <div class="div-button">
          <volvo-button variant="primary" class="button-icon" (click)="onCancelClick()">{{'Cancelar' | translate}}</volvo-button>
        </div>
        <div class="div-button">
          <volvo-button variant="primary" class="button-icon" (click)="onSaveClick()">{{'Salvar' | translate}}</volvo-button>
        </div>
        <div class="div-button">
          <volvo-button variant="primary" class="button-icon" (click)="onSendClick()">{{'Enviar' | translate}}</volvo-button>
        </div>
        <div class="div-button" *ngIf="isPlanningTransmitted(planning?.status?.id)">
          <volvo-button variant="primary" class="button-icon" (click)="onFinalizeClick()">{{'Finalizar' | translate}}</volvo-button>
        </div>
        <div class="div-button" *ngIf="isPlanningRegistered(planning?.status?.id)">
          <volvo-button variant="primary" class="button-icon" (click)="onDeleteClick()">{{'Excluir' | translate}}</volvo-button>
        </div>
      </div>
    </div>
  </div>
</div>
