<mat-card class="card-principal col-sm-11">
  <mat-card-content>
    <div class="card-content">
      <div class="row">
        <h1 class="brand-title">{{'Nova Promoção' | translate}}</h1>
      </div>
      <div class="row">
          <form [formGroup]="form">
              <div class="dropdown-container">
                <div class="dropdown-40">
                  <label for="promotionTypedropdown">{{'Modelo' | translate}}</label>
                  <ng-multiselect-dropdown #promotionTypedropdown
                     [placeholder]="'Selecione' | translate"
                     [settings]="promotionTypeDropdownSettings" [data]="promotionTypes"
                     (onSelect)="onpromotionTypeSelect($event)"
                     class="singleselect"
                  >
                  </ng-multiselect-dropdown>
                  <div *ngIf="erroMsg" class="div-error" >
                    <div *ngIf="form.controls['promotionTypeId'].errors">{{'Selecione o modelo' | translate}}</div>
                 </div>
                </div>
                <div class="dropdown-60" >
                  <label for="promotion-name">{{'Nome da promoção' | translate}}</label><br>
                  <input id="promotion-name"
                     class="input-promotion-name"
                     type="text"
                     formControlName="name"
                     [placeholder]="'Digite' | translate"
                  >
                  <div *ngIf="erroMsg" class="div-error" >
                    <div *ngIf="form.controls['name'].errors">{{'Digite o nome da promoção' | translate}}</div>
                 </div>
                </div>
              </div>
              <div class="date-container">
                <div class="dropdown-50">
                  <label for="pickerInitial">{{'Inicio da vigência' | translate}}</label><br>
                  <mat-form-field appearance="outline" class = "promotion-Date">
                    <input matInput
                      [matDatepicker]="pickerInitial"
                      (dateInput)="addEventInitialDate('input', $event)"
                      (click)="pickerInitial.open()"
                      [placeholder]="'Selecione' | translate"
                      [max]="maxDate">
                    <mat-datepicker-toggle matSuffix [for]="pickerInitial"></mat-datepicker-toggle>
                    <mat-datepicker #pickerInitial></mat-datepicker>
                  </mat-form-field>
                  <div *ngIf="erroMsg" class="div-error" >
                    <div *ngIf="form.controls['initialDate'].errors">{{'Selecione o início da vigência' | translate}}</div>
                    <div *ngIf="form.hasError('invalidDates')">{{'O Início da vigência precisa ser menor que o Fim da vigência' | translate}}</div>
                 </div>
                </div>
                <div class="dropdown-50">
                  <label for="pickerFinal">{{'Fim da vigência' | translate}}</label><br>
                  <mat-form-field appearance="outline" class = "promotion-Date" >
                    <input matInput
                       [matDatepicker]="pickerFinal"
                       (dateInput)="addEventFinalDate('input', $event)"
                       (click)="pickerFinal.open()"
                       [placeholder]="'Selecione' | translate"
                       [min]="minDate">
                  <mat-datepicker-toggle matIconSuffix [for]="pickerFinal"></mat-datepicker-toggle>
                  <mat-datepicker #pickerFinal></mat-datepicker>
                  </mat-form-field>
                  <div *ngIf="erroMsg" class="div-error" >
                    <div *ngIf="form.controls['finalDate'].errors">{{'Selecione o fim da vigência' | translate}}</div>
                 </div>
                </div>
              </div>
          </form>
      </div>
    </div>
  </mat-card-content>
</mat-card>
<br>
<mat-card class="card-principal col-sm-11">
  <mat-card-content>
    <div class="card-content">
      <div class="row">
        <h1 class="brand-title">{{'Adicione as Peças' | translate}}</h1>
      </div>
      <div class="row">
        <div class="div-card-promotion-create-import">
          <div>
              <div class="dropdown-100">
                <div class="div-import-list">
                  <div class="div-heading6">
                    <volvo-typography variant='heading6'>
                      {{'ATUALIZAR LISTA DE PEÇAS / RISCO' | translate}}
                    </volvo-typography>
                  </div>
                  <div class="div-import">
                    <div class="div-import-icon">
                      <span class="div-import-span" (click)="fileUpload.click()">
                        <volvo-system-icon icon="import" size="24"></volvo-system-icon>
                        &nbsp;{{fileName || "Importar lista de peças"  | translate}}
                      </span>
                    </div>
                    <div>
                      <volvo-button size="small" variant="primary" [disabled]="importDisabled" (click)="handleFileInput()">
                        {{'Atualizar' | translate}}
                      </volvo-button></div>
                  </div>
                  <div class="div-import-label">
                    {{'(Tipo do arquivo: .xlsx - tamanho máx: 2MB)' | translate}}
                  </div>
                </div>
                <input type="file" class="file-input" accept=".xlsx"
                       (change)="onFileSelected($event)" #fileUpload>
              </div>
           </div>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
<div class="div-button">
  <volvo-button variant="primary" class="button-icon" size="large" [routerLink]="['/promotionGrid']" >{{'CANCELAR' | translate}}</volvo-button>
</div>
