<footer class="border-top footer text-muted">
  <div style = "margin:0 auto 0 10px;">{{'© Copyright Volvo 2023'}}</div>
  <div style = "margin:auto;">{{'Parts Commercial - Volvo do Brasil' }} <span style="font-size: 15px;"> - {{ environmentName }}</span></div>
  <div style = "margin:0 10px 0 auto;">
      <a style="color: rgb(83,86,90)" href="https://www.volvogroup.com/" target="_blank">www.volvogroup.com</a>
      | <a style="color: rgb(83,86,90)" href="https://www.volvogroup.com/en/tools/privacy.html">Privacy</a>
      | <a style="color: rgb(83,86,90)" href="">Cookies</a>
  </div>
</footer>



