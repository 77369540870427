<div class="div-card-principal">
  <h1 class="brand-title">{{'Selecione um orçamento' | translate}}</h1>
  <div class="separator-breadcrumb border-top"></div>
    <div>
      <div class="search-container">
        <div class="div-search">
          <input class="input-search" type="text" [(ngModel)]="searchTerm" (keyup.enter)="search()"
          placeholder="{{'Buscar orçamento' | translate}}"
          ngbTooltip="{{'Para buscar, digite um dado, conforme título da tabela abaixo.' | translate }}">
          <volvo-system-icon class="icon-search" icon="search" size="24" (click)="search()"></volvo-system-icon>
        </div>
      </div>
      <div>
        <mat-card>
          <mat-card-content>
            <table mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)">
              <ng-container matColumnDef="checked">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let quotation">
                  <input
                    type="checkbox"
                    [ngModel]="quotation.id === selectedQuotation"
                    (ngModelChange)="onQuotationChange(quotation.id)"
                    [name]="'quotation-' + quotation.id">
                </td>
              </ng-container>
              <ng-container matColumnDef="dealer.corporateName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by dealer.corporateName"> {{'Concessionária' | translate}} </th>
                <td mat-cell *matCellDef="let quotation"> {{quotation.dealer.corporateName}} </td>
              </ng-container>
              <ng-container matColumnDef="code">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by code"> {{'Cód Orçamento' | translate}} </th>
                <td mat-cell *matCellDef="let quotation"> {{quotation.code}} </td>
              </ng-container>
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name"> {{'Nome Orçamento' | translate}} </th>
                <td mat-cell *matCellDef="let quotation"> {{quotation.name}} </td>
              </ng-container>
              <ng-container matColumnDef="statusQuotation.name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by statusQuotation.name"> {{'Status' | translate}} </th>
                <td mat-cell *matCellDef="let quotation" [ngClass]="getStatusColorClass(quotation.statusQuotation.id)"> {{quotation.statusQuotation.name | translate}} </td>
              </ng-container>
              <ng-container matColumnDef="createdAt">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by createdAt"> {{'Data Orçamento' | translate}} </th>
                <td mat-cell *matCellDef="let quotation"> {{quotation.createdAt | date: maskDate}} </td>
              </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row  class="queue-element-row" *matRowDef="let row; columns: displayedColumns;"></tr>
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="displayedColumns.length">{{'Orçamento não encontrado.' | translate}}</td>
              </tr>
            </table>
            <mat-paginator [length]="service.total | async" [pageSizeOptions]="[10,20,50,100]" (page)="onPageChange($event)"
              showFirstLastButtons
              aria-label="Select page of quotations">
            </mat-paginator>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="button-panel">
        <div class="div-button">
          <volvo-button variant="secondary" class="button-icon" (click)="cancel()">{{'Cancelar' | translate}}</volvo-button>
        </div>
        <div class="div-button">
          <volvo-button variant="primary" class="button-icon" (click)="copy()" [disabled]="disableCopy">{{'Copiar' | translate}}</volvo-button>
        </div>
      </div>
    </div>
</div>
