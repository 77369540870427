<div class="dropdown-container">
  <div class="row">
    <div class="col-md-3">
      <!-- <label for="dealer-group-dropdown">{{'Grupo Econômico' | translate}}</label> -->
      <ng-multiselect-dropdown [(ngModel)]="selectedDealerGroup"
        id="dealer-group-dropdown" [settings]="dealerGroupDropdownSettings" [data]="dealerGroupFilters"
        (onSelect)="onDealerGroupSelect($event)"
        (onDeSelect)="onDealerGroupDeSelect($event)"
        [placeholder]="'Filtrar por Grupo Econômico' | translate"
        class="singleselect">
      </ng-multiselect-dropdown>
    </div>
    <div class="col-md-3">
      <!-- <label for="dealer-dropdown">{{'Concessionária' | translate}}</label> -->
      <ng-multiselect-dropdown [(ngModel)]="selectedDealer"
        id="dealer-dropdown" [settings]="dealerDropdownSettings" [data]="dealerFilters"
        (onSelect)="onDealerSelect($event)"
        (onDeSelect)="onDealerDeSelect($event)"
        [placeholder]="'Filtrar por Concessionária' | translate"
        class="singleselect">
      </ng-multiselect-dropdown>
    </div>
  </div>
</div>
<div class="wrapper">
  <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort (matSortChange)="announceSortChange($event)">
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> {{'Nome' | translate}} </th>
      <td mat-cell *matCellDef="let userdealers" class="mat-cell-regular"> {{userdealers.name}} </td>
    </ng-container>
    <ng-container matColumnDef="expand">
      <th mat-header-cell *matHeaderCellDef> {{'Expandir' | translate}} </th>
      <td mat-cell *matCellDef="let userdealers">
        <div class="mat-cell-end">
          <volvo-system-icon icon="{{userdealers.icon}}" size="24"></volvo-system-icon>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length" class="mat-cell-expanded">
        <div class="element-detail" [@detailExpand]="element.expanded ? 'expanded' : 'collapsed'">
          <table mat-table [dataSource]="element.dealers" multiTemplateDataRows matSort (matSortChange)="announceSortChange($event)" class="mat-table-expanded">
            <ng-container matColumnDef="dealerGroupName">
              <th mat-header-cell *matHeaderCellDef>
                <div class="div-dealer-group-header">
                  {{'Grupo Econômico' | translate}}
                </div>
              </th>
              <td mat-cell *matCellDef="let userdealers">{{userdealers.dealerGroupName}}</td>
            </ng-container>
            <ng-container matColumnDef="corporateName">
              <th mat-header-cell *matHeaderCellDef>
                <div class="div-edit">
                  <div class="div-edit-start">{{'Concessionária' | translate}}</div>
                  <div class="div-edit-end">
                    <div>
                      <volvo-system-icon class="edit-icon" icon="agenda" size="24" (click)="openUpdateModal(this.element)"></volvo-system-icon>
                    </div>
                    <div class="div-edit-text" (click)="openUpdateModal(this.element)">
                      {{'Editar' | translate}}
                    </div>
                  </div>
                </div>
              </th>
              <td mat-cell *matCellDef="let userdealers">{{userdealers.corporateName}}</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedExpandedColumns"></tr>
            <tr mat-row class="queue-element-row" *matRowDef="let row; columns: displayedExpandedColumns;"></tr>
          </table>
        </div>
      </td>
    </ng-container>
    <tr mat-row *matRowDef="let element; columns: displayedColumns;"
      [class.element-expanded-row]="element.expanded" (click)="toggleRow(element)">
    </tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="element-detail-row"></tr>
  </table>
  <mat-paginator [length]="service.total | async" [pageSizeOptions]="[10,20,50,100]" (page)="service.page = $event.pageIndex+1"
      showFirstLastButtons
      aria-label="Select page of users">
  </mat-paginator>
  <div class="div-button">
    <volvo-button variant="primary" class="button-icon" (click)="openCreateModal()">{{'Novo Vínculo' | translate}}</volvo-button>
  </div>
</div>
