import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { InconsistencyPlanning } from '../../models/planning';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PlanningService } from '../../services/planning.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-planning-inconsistencies',
  templateUrl: './planning-inconsistencies.component.html',
  styleUrls: ['./planning-inconsistencies.component.css']
})
export class PlanningInconsistenciesComponent implements OnInit {

  displayedColumns: string[] = ['partCode', 'reason', 'releaseDate', 'checked'];
  inconsistencies: InconsistencyPlanning[] = [];
  dataSource = new MatTableDataSource<InconsistencyPlanning>(this.inconsistencies);

  constructor(
    private modalService: NgbModal,
    public dataService: PlanningService,
    private translateService: TranslateService,
    private toastr: ToastrService) {}

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource<InconsistencyPlanning>(this.inconsistencies);
  }

  warningActiveChange(inconsistency: InconsistencyPlanning){
    let inconsistencies = this.inconsistencies.filter(i => i.id.toLowerCase() === inconsistency.id.toLowerCase());

    if(!inconsistencies){
      return;
    }

    inconsistencies.forEach(i => {
      i.warningActive = !i.warningActive;
      this.updateWarningActive(i);
    });
  }

  close() {
    this.modalService.dismissAll();
  }

  updateWarningActive(inconsistency: InconsistencyPlanning) {
    this.dataService.putInconsistency(inconsistency).subscribe((error) =>{
      this.error(error);
    }
   );
  }

  error(error: any){
    if(!error.error){
      return;
    }

    if(error.error.message){
      this.toastr.error(error.error.message.toString(), this.translateService.instant('Erro'));
    }

    if(error.error.title){
      this.toastr.error(error.error.title.toString(), this.translateService.instant('Erro'));
    }
  }
}
