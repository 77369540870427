import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PartPlanning } from '../../models/planning';

@Component({
  selector: 'app-part-details',
  templateUrl: './part-details.component.html',
  styleUrls: ['./part-details.component.css']
})
export class PartDetailsComponent {
  partPlanning!: PartPlanning;

  constructor(
    public activeModal: NgbActiveModal){
  }

  close(){
    this.activeModal.close();
  }
}
