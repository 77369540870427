<div class="app-top-bar">
  <img class = "brand-logo" src = "/assets/img/Volvo-Spread-Word-Mark-Black.svg"/>
  <div class="service-name">
    Parts Commercial | {{country | translate}}
  </div>
  <div class="header-info">
    <div class="header-info-content">
      {{profile?.givenName}} {{profile?.surname}}
      <volvo-system-icon icon="user" size="24" style="margin-left:0.5rem;"></volvo-system-icon>
      <volvo-system-icon icon="hamburger-menu" size="24" style="margin-left:0.5rem;" (click)="_toggleSidebar()"></volvo-system-icon>
    </div>
  </div>
</div>
<div style="margin-top: 56px">
  <div class="side-menu">
    <volvo-side-menu class = "side-menu-volvo" mode = "permanent" [open]="_opened">
      <volvo-side-menu-top-bar (toggle) = "_toggleSidebar()">Menu</volvo-side-menu-top-bar>
      <volvo-side-menu-item icon="home" [routerLink]="['/home']" matTooltip="{{'Home' | translate}}" [active]="isHomeActive" (click)="clickMenuItem('home')" matTooltipPosition="after"><span class="span-side-menu-item">{{'Home' | translate}}</span></volvo-side-menu-item>
      <volvo-side-menu-group
        *ngFor="let menuGroup of menuGroups; let i = index"
        [attr.text]="menuGroup.text | translate"
        [attr.expanded]="menuGroup.expanded ? '' : null"
        (click)="toggleExpanded(i)">
        <volvo-side-menu-item *ngFor="let menuItem of menuGroup.menuItems" [active]="menuItem.active" (click)="clickMenuItem(menuItem.id)" icon="{{menuItem.icon}}" [routerLink]="[menuItem.link]" matTooltip="{{menuItem.text | translate}}" matTooltipPosition="after"><span class="span-side-menu-item">{{menuItem.text | translate}}</span></volvo-side-menu-item>
      </volvo-side-menu-group>
      <volvo-side-menu-item icon="logout" (click)="logout(); _toggleSidebar()" matTooltip="{{'Logout' | translate}}" matTooltipPosition="after"><span class="span-side-menu-item">{{'Logout' | translate}}</span></volvo-side-menu-item>
      <volvo-side-menu-section-header class="settings-space">{{'Configurações' | translate}}</volvo-side-menu-section-header>
      <div *ngIf="_opened" class="settings" >
        <div id="parent">
          <volvo-image matTooltip="{{'Português' | translate}}" matTooltipPosition="above" class="child" src = "/assets/img/flags/br.svg" (click)="changeLanguage('br')" alt="Portuguese"></volvo-image>
          <volvo-image matTooltip="{{'Inglês' | translate}}" matTooltipPosition="above" class="child" src = "/assets/img/flags/us.svg" (click)="changeLanguage('us')" alt="English"></volvo-image>
          <volvo-image matTooltip="{{'Espanhol' | translate}}" matTooltipPosition="above" class="child" src = "/assets/img/flags/es.svg" (click)="changeLanguage('es')" alt="Spanish"></volvo-image>
        </div>
      </div>
    </volvo-side-menu>
    <div class="add-background">
      <router-outlet (activate)="highlightMenuItem($event)"></router-outlet>
    </div>
  </div>
</div>
