import { Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PlanningHasPart } from 'src/app/shared/models/planningHasPart';

@Component({
  selector: 'app-part-other-plannings',
  templateUrl: './part-other-plannings.component.html',
  styleUrls: ['./part-other-plannings.component.css']
})
export class PartOtherPlanningsComponent {
  planningsWithPart!: PlanningHasPart[];
  dataSource = new MatTableDataSource<PlanningHasPart>(this.planningsWithPart);
  displayedColumns: string[] = ['quotationCode', 'code', 'status', 'date', 'dealer'];

  constructor(
    public activeModal: NgbActiveModal){
  }

  close(){
    this.activeModal.close();
  }

  loadPlannings(planningsWithPart: PlanningHasPart[]){
    this.planningsWithPart = planningsWithPart;
    this.dataSource = new MatTableDataSource<PlanningHasPart>(this.planningsWithPart);

  }
}
