<div class="div-card-principal">
  <div class="row col-sm-12">
    <div class="col-sm-9">
      <h1 class="brand-title">{{'Parâmetros - Sistemas' | translate}}</h1>
    </div>
  </div>
  <div class="separator-breadcrumb border-top"></div>
  <div>
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['../home']" routerLinkActive="router-link-active">{{'Home' | translate}}</a>
        </li>
        <li class="active breadcrumb-item" aria-current="page">{{'Parâmetros - Sistemas' | translate}}</li>
      </ol>
    </nav>
  </div>
  <app-not-found *ngIf="!isIt" firstMessage="Apenas IT tem acesso a esta função." secondMessage="Caso você precise de acesso, deverá solicitar via Service Now."></app-not-found>
  <div *ngIf="isIt">
  </div>
</div>
