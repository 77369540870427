import { DecimalPipe } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { PartPromotion } from 'src/app/modules/promotion/models/partPromotion';
import { GridDataSource } from 'src/app/services/grid-data-source.service';


@Component({
  selector: 'app-part-promotion',
  templateUrl: './part-promotion.component.html',
  styleUrls: ['./part-promotion.component.css'],
  providers: [GridDataSource, DecimalPipe]
})
export class PartPromotionComponent implements OnInit {
  @Output() clickMenuItem = new EventEmitter<string>();

  constructor(
    public service: GridDataSource<PartPromotion>
  ) { }

  ngOnInit(): void {
    this.clickMenuItem.emit('promotion');
    this.service.datasource.subscribe(resp => {
		});
  }

  PageChange(_event: Event){

  }
}
