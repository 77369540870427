import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuComponent } from './Component/menu/menu.component';
import { NotFoundComponent } from './Component/not-found/not-found.component';
import { HomeComponent } from './Component/home/home.component';
import { FooterComponent } from './Component/footer/footer.component';
import { RouterModule } from '@angular/router';
import { ProfileComponent } from './Component/profile/profile.component';
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { HttpClient } from "@angular/common/http";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { environment } from "src/environments/environment";
import { FormsModule } from '@angular/forms';

import '@volvo/vcdk'; // <---

//Angular Material Modules
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import {MatFormFieldModule, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatTooltipModule} from '@angular/material/tooltip';


export function HttpLoaderFactory(http: HttpClient) {
  if (environment.production == true || environment.EnvironmentPlaceName == 'QA')
  {
    return new TranslateHttpLoader(http, "./assets/i18n/", ".txt");
  }
  else
  {
    return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
  }
}


@NgModule({
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {appearance: 'outline'}
    }
  ],
  declarations: [
    MenuComponent,
    NotFoundComponent,
    HomeComponent,
    FooterComponent,
    ProfileComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatFormFieldModule,
    MatSelectModule,
    MatTooltipModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
  })
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],  // <---
  exports:[
    MenuComponent,
    NotFoundComponent,
    HomeComponent,
    FooterComponent,
    TranslateModule
  ]
})

export class NavigationModule { }
