import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { PlanningService } from '../../services/planning.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-delete-planning',
  templateUrl: './delete-planning.component.html',
  styleUrls: ['./delete-planning.component.css']
})

export class DeletePlanningComponent {

  @Input() planningId!: string;
  selectedOption: string = 'false';

  constructor(
    private activeModal: NgbActiveModal,
    private translateService: TranslateService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    public dataService: PlanningService) { }

  ngOnInit() {
  }

  public cancel() {
    this.activeModal.close(false);
  }

  public dismiss() {
    this.activeModal.close(false);
  }

  deletePlanning(){
    if(!this.planningId){
      return;
    }

    this.spinner.show();
    this.dataService.deletePlanning(this.planningId, this.getSelectedOption()).subscribe(
      () =>{
        this.spinner.hide();
        this.toastr.success(this.translateService.instant("Planejamento excluído com sucesso!"));
        this.activeModal.close(true);
      },
      (error) =>{
        this.spinner.hide();
        let erro = <any>error;
        if(error?.status == 400){
          this.toastr.error(erro.error.details[0].erros[0], this.translateService.instant('Erro'), { timeOut: 5000, extendedTimeOut: 5000 });
        }
        else{
          this.toastr.error(error.error.message, this.translateService.instant('Erro'), { timeOut: 5000, extendedTimeOut: 5000 });
          this.activeModal.close(false);
        }
      }
    );

  }

  getSelectedOption(): boolean {
    if(!this.selectedOption){
      return false;
    }

    if(this.selectedOption.toLowerCase() === 'false'){
      return false;
    }

    return true;
  }

  error(error: any){
    this.spinner.hide();
    if(!error.error){
      return;
    }

    if(error.error.message){
      this.toastr.error(error.error.message.toString(), this.translateService.instant('Erro'));
    }

    if(error.error.title){
      this.toastr.error(error.error.title.toString(), this.translateService.instant('Erro'));
    }
  }
}
