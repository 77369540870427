import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType } from '@azure/msal-browser';
import { filter } from 'rxjs/operators';
import { SetDefaultLanguageService } from 'src/app/shared/utils/setDefaultLanguage';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  @Output() clickMenuItem = new EventEmitter<string>();

  loginDisplay = false;

  constructor(private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private router:Router) {
     }

  ngOnInit(): void {

    this.clickMenuItem.emit('home');

    this.msalBroadcastService.msalSubject$
    .pipe(
      filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
    )
    .subscribe((result: EventMessage) => {
      console.log(result);
    });
    this.setLoginDisplay();
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
    // document.location.href="/contract";
  }

}
