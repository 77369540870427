import { Component, Input } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DealerGroup } from 'src/app/shared/models/dealerGroup';
import { Dealer } from 'src/app/shared/models/dealer';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { StatusPlanning } from 'src/app/shared/models/statusPlanning';

@Component({
  selector: 'app-planning-grid-filter',
  templateUrl: './planning-grid-filter.component.html',
  styleUrls: ['./planning-grid-filter.component.css']
})
export class PlanningGridFilterComponent {
  @Input() filterObject: any = {};
  @Input() dealerGroupFilters!: DealerGroup[];
  @Input() dealerList!: Dealer[];
  @Input() statusFilters!: StatusPlanning[];

  dealerGroupDropdownSettings!: IDropdownSettings;
  dealerDropdownSettings!: IDropdownSettings;
  statusDropdownSettings!: IDropdownSettings;
  selectedDealerGroup!: DealerGroup[];
  dealerFilters!: Dealer[];
  selectedDealer!: Dealer[];
  selectedStatus!: StatusPlanning[];
  minDate!: Date;
  maxDate!: Date;
  selectedDealerGroupId: string = "";
  selectedDealerId: string = "";
  selectedStatusId: string = "";
  initialDateValue: Date|null = null;
  finalDateValue: Date|null = null;
  datePipe: DatePipe = new DatePipe('en-US');
  selectedInitialDate!: Date | null;
  selectedFinalDate!: Date | null;

  constructor(private translateService: TranslateService,
    public activeModal: NgbActiveModal){
    this.initializeSettings();

  }

  initializeSettings() {
    this.dealerGroupDropdownSettings = {
      singleSelection: true,
      idField: 'dealerGroupId',
      textField: 'description',
      selectAllText: this.translateService.instant('Selecionar tudo'),
      unSelectAllText: this.translateService.instant('Limpar'),
      noDataAvailablePlaceholderText: this.translateService.instant('Nenhum dado encontrado'),
      itemsShowLimit: 1,
      allowSearchFilter: false,
      closeDropDownOnSelection: true
    };

    this.dealerDropdownSettings = {
      singleSelection: true,
      idField: 'dealerId',
      textField: 'corporateName',
      selectAllText: this.translateService.instant('Selecionar tudo'),
      unSelectAllText: this.translateService.instant('Limpar'),
      noDataAvailablePlaceholderText: this.translateService.instant('Selecione o grupo econômico'),
      itemsShowLimit: 2,
      allowSearchFilter: false,
      closeDropDownOnSelection: true
    };

    this.statusDropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      selectAllText: this.translateService.instant('Selecionar tudo'),
      unSelectAllText: this.translateService.instant('Limpar'),
      noDataAvailablePlaceholderText: this.translateService.instant('Nenhum dado encontrado'),
      itemsShowLimit: 2,
      allowSearchFilter: false,
      closeDropDownOnSelection: true
    };
  }

  onDealerGroupSelect(item: any){
    this.selectedDealerGroupId = item.dealerGroupId;
    this.addDealersFromGroups();
  }

  onDealerGroupDeSelect(item:any){
    this.selectedDealerGroupId = "";
    this.selectedDealerId = "";
    this.selectedDealer = [];
    this.addDealersFromGroups();
  }

  onDealerSelect(item: any){
    this.selectedDealerId = item.dealerId;
  }

  onDealerDeSelect(item:any){
    this.selectedDealerId = "";
  }

  onStatusSelect(item: any){
    this.selectedStatusId = item.id;
  }

  onStatusDeSelect(item:any){
    this.selectedStatusId = "";
  }

  setInitialDate(type: string, event: MatDatepickerInputEvent<Date>) {
    delete this.filterObject.initialDate;
    delete this.filterObject.selectedInitialDate;
    this.initialDateValue = event.value;
    if(this.initialDateValue){
      this.filterObject.initialDate = this.datePipe.transform(this.initialDateValue, 'yyyy-MM-dd');
      this.minDate = new Date(this.initialDateValue);
      this.minDate.setDate(this.minDate.getDate());
      this.filterObject.selectedInitialDate = this.selectedInitialDate;
    }
  }

  setFinalDate(type: string, event: MatDatepickerInputEvent<Date>) {
    delete this.filterObject.finalDate;
    delete this.filterObject.selectedFinalDate;
    this.finalDateValue = event.value;
    if(this.finalDateValue){
      this.filterObject.finalDate = this.datePipe.transform(this.finalDateValue, 'yyyy-MM-dd');
      this.maxDate = new Date(this.finalDateValue);
      this.maxDate.setDate(this.maxDate.getDate());
      this.filterObject.selectedFinalDate = this.selectedFinalDate;
    }
  }

  addDealersFromGroups(){
    this.dealerFilters = [];

    if(!this.dealerList)
      return;

    if(!this.selectedDealerGroupId)
      return;

    if(this.selectedDealerGroupId === "")
      return;

    this.dealerFilters = this.dealerList.filter(d => d.dealerGroupId === this.selectedDealerGroupId);
  }

  cleanFilters(){
    this.selectedDealerGroup = [];
    this.selectedDealerGroupId = "";
    this.selectedDealer = [];
    this.selectedDealerId = "";
    this.selectedStatus = [];
    this.selectedStatusId = "";
    this.selectedInitialDate = null;
    this.initialDateValue = null;
    this.selectedFinalDate = null;
    this.finalDateValue = null;
  }

  filter(){
    delete this.filterObject.dealerGroupId;
    delete this.filterObject.dealerId;
    delete this.filterObject.statusPlanningId;
    delete this.filterObject.initialDate;
    delete this.filterObject.finalDate;
    delete this.filterObject.selectedInitialDate;
    delete this.filterObject.selectedFinalDate;

    if(!this.selectedDealerGroupId){
      this.selectedDealerGroupId = "";
    }

    if(!this.selectedDealerId){
      this.selectedDealerId = "";
    }

    if(!this.selectedStatusId){
      this.selectedStatusId = "";
    }

    if(this.selectedDealerGroupId !== ""){
      this.filterObject.dealerGroupId = this.selectedDealerGroupId;
    }

    if(this.selectedDealerId !== ""){
      this.filterObject.dealerId = this.selectedDealerId;
    }

    if(this.selectedStatusId !== ""){
      this.filterObject.statusPlanningId = this.selectedStatusId;
    }

    if(this.initialDateValue){
      this.filterObject.initialDate = this.initialDateValue;
      this.filterObject.selectedInitialDate = this.selectedInitialDate;
    }

    if(this.finalDateValue){
      this.filterObject.finalDate = this.finalDateValue;
      this.filterObject.selectedFinalDate = this.selectedFinalDate;
    }

    this.activeModal.close(this.filterObject);
  }

  setFilters(){
    if(!this.filterObject)
      return;

    if(this.filterObject.dealerGroupId){
      this.selectedDealerGroupId = this.filterObject.dealerGroupId;
      this.selectedDealerGroup = this.dealerGroupFilters.filter(d => d.dealerGroupId === this.selectedDealerGroupId);
      this.addDealersFromGroups();
    }

    if(this.filterObject.dealerId){
      this.selectedDealerId = this.filterObject.dealerId;
      this.selectedDealer = this.dealerFilters.filter(d => d.dealerId === this.selectedDealerId);
    }

    if(this.filterObject.selectedStatusId){
      this.selectedStatusId = this.filterObject.statusPlanningId;
      this.selectedStatus = this.statusFilters.filter(s => s.id === this.selectedStatusId);
    }

    if(this.filterObject.selectedInitialDate){
      this.selectedInitialDate = this.filterObject.selectedInitialDate;
    }

    if(this.filterObject.selectedFinalDate){
      this.selectedFinalDate = this.filterObject.selectedFinalDate;
    }
  }
}
