import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Binary } from '@angular/compiler';

const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';
const GRAPH_ENDPOINT_AVATAR = 'GET https://graph.microsoft.com/v1.0/me/photo/'

type ProfileType = {
  givenName?: string,
  surname?: string,
  userPrincipalName?: string,
  id?: string
  photo?: Binary,
  displayName?: string
}

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  profile!: ProfileType;
  apiResponse!: string;
  apiReponsePick!: string;

  constructor(
    private http: HttpClient
  ) { }

  ngOnInit() {
    this.getProfile();
  }

  getProfile() {
    this.http.get(GRAPH_ENDPOINT)
      .subscribe(profile => {
        this.profile = profile;
      });
  }

  getProfileJson(){
    this.http.get(GRAPH_ENDPOINT)
        .subscribe(resp => {
          this.apiResponse = JSON.stringify(resp)
        })
  }

  getProfilePic(){
    this.http.get(GRAPH_ENDPOINT_AVATAR)
        .subscribe(resp => {
          this.apiReponsePick = JSON.stringify(resp)
        })
  }
}
