<div>
  <div class="row">
    <h1 class="brand-title">{{promotionName | translate}}</h1>
  </div>
  <div class="row">
    <h1 class="brand-subtitle">{{promotionInitialDate | date: maskDate }} {{'à' | translate}} {{promotionFinalDate | date: maskDate }}</h1>
  </div>
  <div class="separator-breadcrumb border-top"></div>
  <div>
    <div>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a [routerLink]="['../home']" routerLinkActive="router-link-active">{{'Home'}}</a></li>
          <li class="breadcrumb-item"><a [routerLink]="['../../promotionGrid']" routerLinkActive="router-link-active">{{'Promoção de Peças' | translate}}</a></li>
          <li class="active breadcrumb-item" aria-current="page">{{promotionName | translate}}</li>
        </ol>
      </nav>
    </div>
  </div>
</div>
<div>
  <div>
    <mat-card>
      <mat-card-content>
        <app-part-promotion-grid (downloadReportEvent)="downloadReport()"></app-part-promotion-grid>
      </mat-card-content>
    </mat-card>
  </div>
</div>
<div class="row">
  <div class="col-sm-6">

  </div>
  <div class="col-sm-6">
    <div class="row" *ngIf="isPricingManager && (!isInactiveOrClosed)">
      <div class="col-sm-6">
        <div class="div-inactive">
          <div class="div-export-icon" (click)="inactivePromotion()"><volvo-system-icon icon="activate-off" size="24"></volvo-system-icon></div>
          <div class="div-export-span" (click)="inactivePromotion()"><span>{{'Inativar promoção' | translate}}</span></div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="div-export">
          <div class="div-export-icon" (click)="deleteAllPartPromotion()"><volvo-system-icon icon="trash" size="24"></volvo-system-icon></div>
          <div class="div-export-span" (click)="deleteAllPartPromotion()"><span>{{'Excluir todas as peças' | translate}}</span></div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row ">
  <div class="div-button">
    <volvo-button class="button" variant="primary"  size="large" (click)="sendNewsletter()" *ngIf="isPricingManager && (!isInactiveOrClosed)" >{{'Enviar Boletim' | translate}}</volvo-button>
    <volvo-button class="button" variant="primary"  size="large" (click)="openCreateModal()" *ngIf="isPricingManager && (!isInactiveOrClosed)" >{{'Incluir Peça' | translate}}</volvo-button>
    <volvo-button class="button" variant="primary"  size="large" [routerLink]="['/promotionGrid']" >{{'Fechar' | translate}}</volvo-button>
  </div>
</div>


