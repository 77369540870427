import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-planning-dialog',
  templateUrl: './planning-dialog.component.html',
  styleUrls: ['./planning-dialog.component.css']
})
export class PlanningDialogComponent {
  @Input() goToPlanning!: Function;
  @Input() saveAndGoToPlanning!: Function;

  constructor(private activeModal: NgbActiveModal) { }

  public dismiss() {
    this.activeModal.dismiss();
  }

  public discard() {
    if (this.goToPlanning) {
      this.goToPlanning();
      this.dismiss();
    }
  }

  public save() {
    if (this.saveAndGoToPlanning) {
      this.saveAndGoToPlanning(true);
      this.dismiss();
    }
  }
}
