import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { GetTranslationService } from 'src/app/shared/utils/get-translation.service';
import { PartRestrictionService } from '../../services/part-restriction.service';
import { SetDefaultLanguageService } from 'src/app/shared/utils/setDefaultLanguage';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-part-restriction-import',
  templateUrl: './part-restriction-import.component.html',
  styleUrls: ['./part-restriction-import.component.css']
})
export class PartRestrictionImportComponent implements OnInit {
  @Output() refreshGrid = new EventEmitter<void>();

  fileName = '';
  importDisabled: boolean = true;
  selectedFile: File = new File([], '');

  constructor(
    private http: HttpClient,
    public dataService: PartRestrictionService,
    public getTranslationSvc: GetTranslationService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private translateService: TranslateService,
    private setLang: SetDefaultLanguageService) {
      this.translateService.setDefaultLang(this.setLang.setDefaultLanguage())
      this.translateService.use(localStorage.getItem('language') || this.setLang.setDefaultLanguage())
    }

  ngOnInit(): void {
  }

  onFileSelected(event: any) {

    this.selectedFile = event.target.files[0];
    this.importDisabled = true;

    if (this.selectedFile) {
      this.importDisabled = false;
      this.fileName = this.selectedFile.name;
    }

    event.target.value = null;
  }

  handleFileInput() {
    this.spinner.show();
    const formData = new FormData();

    if(this.selectedFile){
      formData.append('file', this.selectedFile, this.selectedFile.name);

      console.log(this.selectedFile.type);

      this.dataService.upload(formData).subscribe({
        next: (success: any) => this.processSuccess(success),
        error: (fail: any) => this.processFail(fail, this.selectedFile.type),
        // complete: () => this.formResult = this.getTranslationSvc.getTranslation("Edição Realizada")
      });
    }
  }

  processSuccess(response: any){
    let fileName = this.parseHeaders(response.headers)
		this.dataService.downloadFile(response.body, 'text/csv', fileName);
    this.toastr.success("Arquivo Processado",this.getTranslationSvc.getTranslation('Sucesso!'));
    this.importCompleted();
    this.spinner.hide();
  }

  processFail(response: any, fileType: string) {
    if (fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'){
      this.toastr.error(this.getTranslationSvc.getTranslation("Houve algum erro de leitura do arquivo"),this.getTranslationSvc.getTranslation('Erro'));
    }
    else{
      this.toastr.error(this.getTranslationSvc.getTranslation("O arquivo de importação deve ser do tipo excel"),this.getTranslationSvc.getTranslation('Erro'));
    }
    this.spinner.hide();
  }

  parseHeaders(responseHeader: any) {
		let contentDisposition = responseHeader.get('content-disposition');
		return contentDisposition.split(';')[1].split('=')[1];
	}

  importCompleted() {
    this.fileName = '';
    this.importDisabled = true;
    this.refreshGrid.emit();
  }
}
