<div class="div-card-principal">
  <h1 class="brand-title">{{'Informações' | translate}}</h1>
  <div class="separator-breadcrumb border-top"></div>
    <div>
      <div class="dropdown-container">
        <div class="dropdown-100">
          <label for="user-dropdown">{{'Representante Comercial' | translate}}</label><span *ngIf="!updateMode" class="required">*</span>
          <ng-multiselect-dropdown
            id="user-dropdown" [settings]="userDropdownSettings" [data]="userFilters"
            (onSelect)="onUserSelect($event)"
            (onDeSelect)="onUserDeSelect($event)"
            (onSelectAll)="onUserSelect($event)"
            (onDeSelectAll)="onUserSelect($event)"
            [disabled]="userDisable"
            [placeholder]="userDropdownText"
            class="singleselect">
          </ng-multiselect-dropdown>
        </div>
      </div>
      <div class="dropdown-container">
        <div class="dropdown-40">
          <label for="dealer-group-dropdown">{{'Grupo Econômico' | translate}}</label><span *ngIf="!updateMode" class="required">*</span>
          <ng-multiselect-dropdown [(ngModel)]="selectedDealerGroup"
            id="dealer-group-dropdown" [settings]="dealerGroupDropdownSettings" [data]="dealerGroupFilters"
            (onSelect)="onDealerGroupSelect($event)"
            (onDeSelect)="onDealerGroupSelect($event)"
            (onSelectAll)="onDealerGroupSelectAll(true)"
            (onDeSelectAll)="onDealerGroupSelectAll(false)"
            [placeholder]="dropdownText">
          </ng-multiselect-dropdown>
        </div>
        <div class="dropdown-60">
          <label for="dealer-dropdown">{{'Concessionária' | translate}}</label><span *ngIf="!updateMode" class="required">*</span>
          <ng-multiselect-dropdown [(ngModel)]="selectedDealer"
            id="dealer-dropdown" [settings]="dealerDropdownSettings" [data]="dealerFilters"
            (onSelect)="onDealerSelect($event)"
            (onDeSelect)="onDealerSelect($event)"
            (onSelectAll)="onDealerSelectAll($event)"
            (onDeSelectAll)="onDealerSelectAll($event)"
            [placeholder]="dropdownText">
          </ng-multiselect-dropdown>
        </div>
      </div>
      <div class="button-panel">
        <div class="div-button">
          <volvo-button variant="secondary" class="button-icon" (click)="closeModal()">{{'Cancelar' | translate}}</volvo-button>
        </div>
        <div class="div-button">
          <volvo-button variant="primary" class="button-icon" (click)="saveData()" [disabled]="!canSave">{{saveButtonTitle | translate}}</volvo-button>
        </div>
      </div>
    </div>
</div>
<div #falseClick class="hidden"></div>
