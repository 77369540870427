import { Injectable } from "@angular/core";
import { BaseService } from 'src/app/services/base.service';
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { PartRestriction } from "../models/part-restriction";
import { PagedResponse } from "src/app/services/grid-data-source.service";

@Injectable({
  providedIn: 'root'
})
export class PartRestrictionService extends BaseService {

  constructor(private http: HttpClient) {
    super();
  }

  upload(formData: FormData) {
    return this.http.post(`${environment.UrlApiPartsOrderBook}PartRestriction/Upload`, formData, {headers: super.GetAuthTokenHeader().headers, responseType: 'arraybuffer', observe: 'response'})
    .pipe(
      map(this.extract),
      catchError(this.serviceError));
  }

  getAllPartsRestriction(page: number, pageSize: number, filterObject:any): Observable<PagedResponse<PartRestriction>> {

    return this.http
    .get<PagedResponse<PartRestriction>>(`${environment.UrlApiPartsOrderBook}PartRestriction/${page}/${pageSize}`, {params: filterObject, headers: super.GetAuthTokenHeader().headers})
    .pipe(
      map(super.extract),
      catchError(super.serviceError));
  }

  downloadReport() {
    const headers = {
      'Content-Type': 'application/json',
      'Language-Code': `${this.LocalStorage.getCurrentLanguage()}`,
      'Authorization': `Bearer ${this.LocalStorage.getUserToken()}`,
    }
    return this.http
      .get(`${environment.UrlApiPartsOrderBook}PartRestriction/Download`, {headers: super.GetAuthTokenHeader().headers, responseType: 'arraybuffer', observe: 'response'})
  }

  downloadFile(data: any, type: string, fileName: string) {
    let blob = new Blob([data], { type: type });
    let url = window.URL.createObjectURL(blob);
    let downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(blob);
    downloadLink.setAttribute('download', fileName);
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }
}
