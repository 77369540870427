<div class="div-card-principal">
  <div class="row">
    <h1 class="brand-title">{{'Inclusão de Peça' | translate}}</h1>
    <label class="label-title">{{'Informe a peça' | translate}}</label>
  </div>
  <div>
    <form [formGroup]="form">
      <div class="dropdown-container">
        <div class="dropdown-100">
          <label for="part-dropdown">{{'Selecione a peça' | translate}}</label>
          <ng-multiselect-dropdown #partdropdown
            id="partdropdown" [settings]="partDropdownSettings" [data]="partsFilters"
            (onSelect)="onPartSelect($event)"
            (onDeSelect)="onPartDeSelect($event)"
            (onFilterChange)="onFilterChange($event)"
            class="singleselect"
            [placeholder]="'Selecione' | translate"
            >
          </ng-multiselect-dropdown>
        </div>
      </div>
      <div class="dropdown-container">
        <div class="dropdown-50-1">
          <label for="dealer-group-dropdown">{{'De' | translate}}</label>
          <input class="input-price"
          type="text"
          formControlName="price"
          currencyMask [options]="{ prefix: ' ', thousands: '.', decimal: ',', allowNegative: false }"
          (blur)="revalidateForm()"
          >
        </div>
        <div class="dropdown-50">
          <label for="dealer-dropdown">{{'Por' | translate}}</label>
          <input class="input-price"
          type="text"
          formControlName="discountPrice"
          currencyMask [options]="{ prefix: ' ', thousands: '.', decimal: ',', allowNegative: false }"
          (blur)="revalidateForm()"
          >
        </div>
      </div>
      <div class="dropdown-container">
        <div class="dropdown-50-1">
          <label for="dealer-dropdown">{{'Desconto(%)' | translate}}</label>
          <input class="input-price"
          type="number"
          min="1"
          max="100"
          formControlName="discountPercent"
          (blur)="revalidateForm()"
          >
        </div>
        <div class="dropdown-50">
          <label for="dealer-dropdown">{{'Vigente na promo a partir de' | translate}}</label>
          <div class="date-filters">
            <mat-form-field class="date-filter">
              <input matInput
              [matDatepicker]="pickerInitial"
              (click)="pickerInitial.open()"
              [placeholder]="'Data' | translate"
              (blur)="revalidateForm()"
              (dateInput)="setDate($event)"
              formControlName="initialDate">
              <mat-datepicker-toggle matSuffix [for]="pickerInitial"></mat-datepicker-toggle>
              <mat-datepicker #pickerInitial></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div *ngIf="errorMsg" class="div-error" >
        <div *ngIf="form.hasError('invalidValues')">{{'Os valores "De", "Por", "Desconto" e "Data de vigência" são obrigatórios e devem ser diferentes de zero.' | translate}}</div>
        <div *ngIf="form.hasError('invalidDate')">{{'"Data de vigência" inválida.' | translate}}</div>
     </div>
      <div class="button-panel">
        <div class="div-button">
          <volvo-button variant="secondary" class="button-icon" (click)="closeModal()">{{'Cancelar' | translate}}</volvo-button>
          <div class="div-spacing"></div>
          <volvo-button variant="primary" class="button-icon" (click)="save()" [disabled]="buttonDisabled">{{'Confirmar' | translate}}</volvo-button>
        </div>
      </div>
    </form>
  </div>
</div>
