import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseService } from "src/app/services/base.service";
import { PagedResponse } from "src/app/services/grid-data-source.service";
import { Observable, catchError, map } from "rxjs";
import { environment } from "src/environments/environment";
import { DealerGroup } from "../models/dealerGroup";

@Injectable({
  providedIn: 'root'
})

export class DealerGroupService extends BaseService {

  constructor(private http: HttpClient) {
    super();
  }

  listDealerGroupsByUser(): Observable<PagedResponse<DealerGroup>> {
    return this.http
    .get<PagedResponse<DealerGroup>>(`${environment.UrlApiPartsOrderBook}DealerGroup/byUser`, {headers: super.GetAuthTokenHeader().headers})
    .pipe(
      map(super.extract),
      catchError(super.serviceError));
  }
}
