import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommercialParameters } from '../../../models/commercial-parameters';

@Component({
  selector: 'app-parameters-quotation',
  templateUrl: './parameters-quotation.component.html',
  styleUrls: ['./parameters-quotation.component.css']
})
export class ParametersQuotationComponent {
  @Input() commercialParameters!: CommercialParameters;
  @Output() saveEvent = new EventEmitter();
  @Output() editEvent = new EventEmitter();
  @Output() goBackEvent = new EventEmitter();
  @Output() changeInvalidFunctionalBox = new EventEmitter<boolean>();

  discountLimitChecked: boolean = false;
  discountLimit!: number;
  profitabilityChecked: boolean = true;
  profitability!: number | null;
  invalidFunctionalBox: boolean = false;
  functionalBox!: string | null;
  volvoDomain: string = '@volvo.com';

  fillParameters(): void {
    if(this.commercialParameters){
      this.discountLimitChecked = this.commercialParameters.discountlimitActive;
      this.profitabilityChecked = this.commercialParameters.profitabilityActive;

      this.discountLimit = this.commercialParameters.discountLimit;
      this.profitability = this.commercialParameters.profitability;

      this.functionalBox = this.commercialParameters.emailFunctionalBox;

      this.validateFunctionalBox();
    }
  }

  //discountLimitCheckChange(){
  //  this.setEdited();
  //  this.discountLimitChecked = !this.discountLimitChecked;
  //  this.commercialParameters.discountlimitActive = this.discountLimitChecked;
  //}

  onDiscountLimitBlur(event: Event) {
    this.setEdited();
    this.discountLimit = this.sanitizeValue(event);
    this.commercialParameters.discountLimit = this.discountLimit;
  }

  onProfitabilityBlur(event: Event) {
    this.setEdited();
    this.profitability = this.sanitizeprofitability(event);
    this.commercialParameters.profitability = this.profitability;
  }

  sanitizeValue(event: Event): number {
    const inputValue = (event.target as HTMLInputElement).value;
    const sanitizedValue = inputValue.split('.')[0].replace(/[^\d]/g, '');

    let numberSanitized = Number(sanitizedValue)

    if(numberSanitized > 100)
      numberSanitized = 100;

    return numberSanitized;
  }

  save(){
    this.saveEvent.emit();
  }

  setEdited(){
    this.editEvent.emit();
  }

  goBack(){
    this.goBackEvent.emit();
  }

  sanitizeprofitability(event: Event): number | null {
    const inputValue = (event.target as HTMLInputElement).value;

    if(!inputValue){
      return null;
    }

    const sanitizedValue = inputValue.split('.')[0].replace(/[^-\d]/g, '');

    let numberSanitized = Number(sanitizedValue)

    if(numberSanitized > 999)
      numberSanitized = 999;

    if(numberSanitized < -999)
      numberSanitized = -999;

    return numberSanitized;
  }

  changeEmail(event: any){

    this.commercialParameters.emailFunctionalBox = null;

    if(this.functionalBox) {
      this.functionalBox = this.functionalBox.trim();
      this.commercialParameters.emailFunctionalBox = this.functionalBox.trim();
    }

    this.validateFunctionalBox();
  }

  validateFunctionalBox(){
    this.setInvalidFunctionalBox(true);
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if(!this.functionalBox){
      this.setNullFunctionalBox();
      this.setInvalidFunctionalBox(false);
      return;
    }

    if(this.functionalBox === ''){
      this.setNullFunctionalBox();
      this.setInvalidFunctionalBox(false);
      return;
    }

    if(this.isWhitespace(this.functionalBox)){
      this.setNullFunctionalBox();
      this.setInvalidFunctionalBox(false);
      return;
    }

    if(!emailRegex.test(this.functionalBox)){
      return;
    }

    if(!this.functionalBox.toLowerCase().endsWith(this.volvoDomain)){
      return;
    }

    this.setInvalidFunctionalBox(false);
  }

  changeInvalidFunctionalBoxEvent(){
    this.changeInvalidFunctionalBox.emit(this.invalidFunctionalBox);
  }

  setInvalidFunctionalBox(invalidFunctionalBox: boolean){
    this.invalidFunctionalBox = invalidFunctionalBox;
    this.changeInvalidFunctionalBoxEvent();
  }

  isWhitespace(str: string): boolean {
    return str.trim() === '';
  }

  setNullFunctionalBox(){
    this.commercialParameters.emailFunctionalBox = null;
  }

}
