<div class="div-card-principal">
  <div class="row filter-header">
    <volvo-typography variant='subtitle1'>
      {{'Pesquisar em orçamentos' | translate}}
    </volvo-typography>
  </div>
  <div class="separator-breadcrumb border-top"></div>
    <div>
      <div class="dropdown-container">
        <label for="dealer-group-dropdown">{{'Grupo Econômico' | translate}}</label>
          <ng-multiselect-dropdown
            id="dealer-group-dropdown"
            [settings]="dealerGroupDropdownSettings"
            [data]="dealerGroupFilters"
            (onSelect)="onDealerGroupSelect($event)"
            (onDeSelect)="onDealerGroupDeSelect($event)"
            [placeholder]="'Grupo Econômico' | translate"
            [(ngModel)]="selectedDealerGroup"
            [searchable]="true"
            class="singleselect">
          </ng-multiselect-dropdown>
      </div>
      <div class="dropdown-container">
        <label for="dealer-dropdown">{{'Concessionária' | translate}}</label>
          <ng-multiselect-dropdown
            id="dealer-dropdown"
            [settings]="dealerDropdownSettings"
            [data]="dealerFilters"
            (onSelect)="onDealerSelect($event)"
            (onDeSelect)="onDealerDeSelect($event)"
            [placeholder]="'Concessionária' | translate"
            [(ngModel)]="selectedDealer"
            [searchable]="true"
            class="singleselect">
          </ng-multiselect-dropdown>
      </div>
      <div class="dropdown-container">
        <label for="status-dropdown">{{'Status Orçamento' | translate}}</label>
          <ng-multiselect-dropdown
            id="status-dropdown"
            [settings]="statusDropdownSettings"
            [data]="statusFilters"
            (onSelect)="onStatusSelect($event)"
            (onDeSelect)="onStatusDeSelect($event)"
            [placeholder]="'Status' | translate"
            [(ngModel)]="selectedStatus"
            [searchable]="true"
            class="singleselect">
          </ng-multiselect-dropdown>
      </div>

      <label>{{'Data entre' | translate}}</label>
      <div class="date-filters">
        <mat-form-field class="date-filter">
            <input matInput
            [matDatepicker]="pickerInitial"
            (dateInput)="setInitialDate('input', $event)"
            (click)="pickerInitial.open()"
            [placeholder]="'Data Início' | translate"
            [max]="maxDate"
            [(ngModel)]="selectedInitialDate">
          <mat-datepicker-toggle matSuffix [for]="pickerInitial"></mat-datepicker-toggle>
          <mat-datepicker #pickerInitial></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="date-filter">
            <input matInput
            [matDatepicker]="pickerFinal"
            (dateInput)="setFinalDate('input', $event)"
            (click)="pickerFinal.open()"
            [placeholder]="'Data Fim' | translate"
            [min]="minDate"
            [(ngModel)]="selectedFinalDate">
          <mat-datepicker-toggle matSuffix [for]="pickerFinal"></mat-datepicker-toggle>
          <mat-datepicker #pickerFinal></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="button-panel">
        <div class="div-button">
          <volvo-button variant="primary" (click)="filter()" fullwidth="">{{'Filtrar' | translate}}</volvo-button>
        </div>
        <div class="div-button">
          <volvo-button variant="secondary" (click)="cleanFilters()" fullwidth="">{{'Limpar Filtros' | translate}}</volvo-button>
        </div>
      </div>
    </div>
</div>
