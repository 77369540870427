<div class="div-card-principal">
  <h1 class="brand-title">{{'Peças com bloqueio' | translate}}</h1>
  <div class="separator-breadcrumb border-top"></div>
    <div>
      <div class="div-export">
        <div class="div-export-icon" (click)="downloadReport()"><volvo-system-icon icon="export" size="24"></volvo-system-icon></div>
        <div class="div-export-span" (click)="downloadReport()"><span>{{'Gerar arquivo csv' | translate}}</span></div>
      </div>
      <div>
        <mat-card>
          <mat-card-content>
            <table mat-table [dataSource]="dataSource">
              <ng-container matColumnDef="partCode">
                <th mat-header-cell *matHeaderCellDef> {{'Cód. da peça' | translate}} </th>
                <td mat-cell *matCellDef="let part"> {{part.partCode}} </td>
              </ng-container>
              <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef> {{'Descrição' | translate}} </th>
                <td mat-cell *matCellDef="let part"> {{part.description}} </td>
              </ng-container>
              <ng-container matColumnDef="reason">
                <th mat-header-cell *matHeaderCellDef> {{'Motivo do bloqueio' | translate}} </th>
                <td mat-cell *matCellDef="let part"> {{part.reasonBlocked}} </td>
              </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row  class="queue-element-row" *matRowDef="let row; columns: displayedColumns;"></tr>
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="displayedColumns.length">{{'Peça não encontrada.' | translate}}</td>
              </tr>
            </table>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="button-panel">
        <div class="div-button">
          <volvo-button variant="primary" class="button-icon" (click)="onCloseClick()">{{'Fechar' | translate}}</volvo-button>
        </div>
      </div>
    </div>
</div>
