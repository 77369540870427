import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, catchError, map } from "rxjs";
import { CommercialParameters } from "src/app/modules/parameters/models/commercial-parameters";
import { BaseService } from "src/app/services/base.service";
import { ResponseResult, PagedResponse } from "src/app/services/grid-data-source.service";
import { environment } from "src/environments/environment";
import { DealerGroup } from "../models/dealerGroup";

@Injectable({
  providedIn: 'root'
})

export class CommercialParametersService extends BaseService {

  constructor(private http: HttpClient) {
    super();
  }

  getCommercialParameters(): Observable<ResponseResult<CommercialParameters>> {
    return this.http
    .get<PagedResponse<DealerGroup>>(`${environment.UrlApiPartsOrderBook}CommercialParameters`, {headers: super.GetAuthTokenHeader().headers})
    .pipe(
      map(super.extract),
      catchError(super.serviceError));
  }
}
