import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PartPlanning } from '../../models/planning';
import { KitListComponent } from 'src/app/shared/component/kit-list/kit-list.component';
import { TranslateService } from '@ngx-translate/core';
import { PlanningHasPart } from 'src/app/shared/models/planningHasPart';
import { PartOtherPlanningsComponent } from 'src/app/shared/component/part-other-plannings/part-other-plannings.component';

@Component({
  selector: 'app-parts-information',
  templateUrl: './parts-information.component.html',
  styleUrls: ['./parts-information.component.css']
})
export class PartsInformationComponent implements OnInit{
  displayedColumns: string[] = ['partCode', 'description', 'fixedPrice', 'discountPercent', 'priceWithoutDiscount', 'priceWithDiscount', 'profitabilityWithoutDiscount', 'profitabilityWithDiscount', 'promotion', 'boPart', 'lastPromotion', 'functionGroup', 'productGroup', 'kitInclude', 'c3stock', 'ranking', 'bulkpackOut', 'unitMeasurement', 'replacedBy', 'risk', 'monthlyForecast', 'stockedLpa', 'planningPart'];
  partsPlanning: PartPlanning[] = [];
  dataSource = new MatTableDataSource<PartPlanning>(this.partsPlanning);

  constructor(
    private modalService: NgbModal,
    private translateService: TranslateService){
      this.dataSource = new MatTableDataSource<PartPlanning>(this.partsPlanning);
  }

  ngOnInit(){
    this.dataSource = new MatTableDataSource<PartPlanning>(this.partsPlanning);
  }

  close(){
    this.modalService.dismissAll();
  }

  openKitList(kitCodes: string[]){
    const modalRef = this.modalService.open(KitListComponent, {size: 'sm' });
    modalRef.componentInstance.kitCodes = kitCodes;
    modalRef.componentInstance.titleText = this.translateService.instant('Atenção');
    modalRef.componentInstance.messageText = this.translateService.instant('Esta peça faz parte dos seguintes kits:');
  }

  openOtherPlanningsModal(planningsWithPart: PlanningHasPart[]){
    const modalRef = this.modalService.open(PartOtherPlanningsComponent, {size: 'lg' });
    modalRef.componentInstance.loadPlannings(planningsWithPart);
  }

  showOtherPlannings(otherPlannings: PlanningHasPart[]) : boolean{
    if(!otherPlannings)
      return false;

    if(otherPlannings.length == 0)
      return false;

    return true;
  }
}
