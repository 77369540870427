// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  ENVIRONMENT_PLACE_NAME: 'Dev',
  IS_PRODUCTION: 'false',
  CLIENT_ID: '401ff8b8-3234-4572-8f78-f25ee4fd18e7',
  ENVIRONMENT_AUTHORITY: 'https://login.microsoftonline.com/f25493ae-1c98-41d7-8a33-0be75f5fe603',
  REDIRECT_URI: 'https://partscommercial-dev.volvo.com/',
  POST_LOGOUT: 'https://login.microsoftonline.com/common/oauth2/v2.0/logout',
  OcpToken: '',
  GRAPH_ENDPOINT: 'https://graph.microsoft.com/v1.0/me',
  URL_API_PARTS_ORDER_BOOK: 'https://partsorderbook-api-dev.volvo.com/v1/',
  LOGISTIC_MANAGER_GROUP_ID: '82bfb3ae-b0d6-4840-8f55-7b1c006354fb',
  PRINCING_MANAGER_GROUP_ID: '7dce5e43-8160-4d25-afa8-d26b7db55ab7',
  SALES_REPRESENTATIVE_ADMIN_GROUP_ID: '0f315c00-f966-4e79-a49e-c601c8568d9c',
  SALES_REPRESENTATIVE_GROUP_ID: '74af3b60-a1be-4ab7-bf52-419b22f0efff',
  IT_GROUP_ID: '8add5a1d-ba95-4c60-b3cc-26e0cd9382a1',
  EXTERNAL_USERS_GROUP_ID: '8c13aa3b-531e-46ae-8350-d3fb4d8f75d3',
  APPLICATIONINSIGHTS_INSTRUMENTATION_KEY: "3f33e5f9-06e7-460f-bad6-9ff4afb57e05",
  APPLICATIONINSIGHTS_CONNECTION_STRING: "InstrumentationKey=3f33e5f9-06e7-460f-bad6-9ff4afb57e05;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/;ApplicationId=6d2241ad-8b8d-4de0-81d1-bd9d86b24b7d"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

