<mat-card class="card-principal col-sm-12">
    <mat-card-content>
      <div class="card-content">        
        <div class="row">
          <div class="div-heading6">
            <volvo-typography variant='heading6'>
              {{'QUERO RECEBER BOLETIM DE PEÇAS EM PROMOÇÃO' | translate}}
            </volvo-typography>
          </div>
        </div>
        <div class="row">
          <div class="div-card-promotion-create">      
              <form [formGroup]="form">
                  <div class="dropdown-container">
                    <div class="dropdown-100">
                      <label for="email">{{'Informe seu e-mail' | translate}}</label><br>
                      <input id="newsletter-name" 
                        class="input-name"
                        id="email"
                        type="email" 
                        formControlName="email" 
                       > 
                    </div>
                  </div>
              </form>
       
          </div>	             
        </div>
      </div>
      <div class="row">
        <div class="div-button">     
          <volvo-button variant="primary" class="button-icon" size="large" (click)="save()" >{{'CONFIRMAR' | translate}}</volvo-button> 
          <volvo-button variant="primary" class="button" size="large" (click)="closeModal()" >{{'CANCELAR' | translate}}</volvo-button>
        </div>            
      </div>
    </mat-card-content>
</mat-card>
