<div class="div-card-principal">
  <div class="row col-sm-12">
    <div class="col-sm-9">
      <h1 class="brand-title">{{'Promoção de Peças' | translate}}</h1>
    </div>
    <div class="div-export col-sm-3">
      <div class="div-export-icon" (click)="openNewsletterModal()" *ngIf="registedNewsletter"><volvo-system-icon icon= "bell-off" size="24" ></volvo-system-icon></div>
      <div class="div-export-icon" (click)="openNewsletterModal()" *ngIf="!registedNewsletter"><volvo-system-icon icon= "bell" size="24" ></volvo-system-icon></div>
      <div class="div-export-span" (click)="openNewsletterModal()"><span>{{labelRegisterNewsletter}}</span></div>
    </div>
  </div>
  <div class="separator-breadcrumb border-top"></div>
    <div>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a [routerLink]="['../home']" routerLinkActive="router-link-active">{{'Home'}}</a>
          </li>
          <li class="active breadcrumb-item" aria-current="page">{{'Promoção de Peças' | translate}}</li>
        </ol>
      </nav>
    </div>
    <div>
      <div>
        <mat-card>
          <mat-card-content>
            <div class="search-header ">
              <div class="header-container">
                <div class="col-sm-6 form-group mb-8 padding">
                  <div class="div-search">
                    <input class="input-search" type="text" [(ngModel)]="searchTerm" (keyup.enter)="search()"
                    placeholder="{{'Buscar por Modelo, Nome, Status, Código e descrição da peça' | translate}}"
                    ngbTooltip="{{'Para buscar, digite um dado, conforme título da tabela abaixo.' | translate }}">
                    <volvo-system-icon class="icon-search" icon="search" size="24" (click)="search()"></volvo-system-icon>
                  </div>
                </div>
                <div class="col-sm-6 form-group mb-8">
                  <div class="date-filters-div">
                    <div class="date-filters-label-div">
                      <label class="date-filters-label" for="pickerInitial">{{'Promoções ocorridas entre:' | translate}}</label><br>
                      <div *ngIf="!validDates" class="div-error" >
                        {{'A Data Fim deve ser maior que a Data Início' | translate}}
                     </div>
                    </div>
                    <div class="date-filters">
                      <mat-form-field class="date-filter">
                          <input matInput
                          [matDatepicker]="pickerInitial"
                          (dateInput)="setInitialDate('input', $event)"
                          (click)="pickerInitial.open()"
                          [placeholder]="'Data Início' | translate"
                          [max]="maxDate">
                        <mat-datepicker-toggle matSuffix [for]="pickerInitial"></mat-datepicker-toggle>
                        <mat-datepicker #pickerInitial></mat-datepicker>
                      </mat-form-field>
                      <div class="spacing"></div>
                      <mat-form-field class="date-filter">
                          <input matInput
                          [matDatepicker]="pickerFinal"
                          (dateInput)="setFinalDate('input', $event)"
                          (click)="pickerFinal.open()"
                          [placeholder]="'Data Fim' | translate"
                          [min]="minDate">
                        <mat-datepicker-toggle matSuffix [for]="pickerFinal"></mat-datepicker-toggle>
                        <mat-datepicker #pickerFinal></mat-datepicker>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <table mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)">
              <ng-container matColumnDef="promotionType.name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort"> {{'Modelo' | translate}} </th>
                <td mat-cell class="click-cell" *matCellDef="let promotion" [routerLink]="['../partpromotion', promotion.id]"> {{promotion.promotionType.name}} </td>
              </ng-container>
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort"> {{'Nome da promoção' | translate}} </th>
                <td mat-cell *matCellDef="let promotion"> {{promotion.name}} </td>
              </ng-container>
              <ng-container matColumnDef="initialDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by risk"> {{'Inicio da vigência' | translate}} </th>
                <td mat-cell *matCellDef="let promotion"> {{promotion.initialDate | date: maskDate}} </td>
              </ng-container>
              <ng-container matColumnDef="finalDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort"> {{'Fim vigência' | translate}} </th>
                <td mat-cell *matCellDef="let promotion"> {{promotion.finalDate | date: maskDate}} </td>
              </ng-container>
              <ng-container matColumnDef="promotionStatus.name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort"> {{'Status' | translate}} </th>
                <td mat-cell *matCellDef="let promotion"><span [ngStyle]="{'color': promotion.promotionStatus.name === 'Vigente' ? 'green' : promotion.promotionStatus.name === 'Inativada' ? 'orange' : 'black'}">{{ promotion.promotionStatus.name | translate}}</span>
                </td>
              </ng-container>
              <ng-container matColumnDef="updatedAt">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort"> {{'Data última atualização' | translate}} </th>
                <td mat-cell *matCellDef="let promotion">
                  {{(promotion.updatedAt ? promotion.updatedAt : promotion.createdAt) | date: maskDate}}
                </td>
              </ng-container>
              <ng-container matColumnDef="newsletterSendDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort"> {{'Boletim enviado em' | translate}} </th>
                <td mat-cell *matCellDef="let promotion">
                  {{promotion.newsletterSendDate | date: maskDate}}
                  <div *ngIf="!promotion.newsletterSendDate" class="alert-content">
                    <div class="alert" matTooltip="{{'E-mail contendo o boletim não foi enviado' | translate}}">
                      <volvo-system-icon class="icon" icon="exclamation-point" filled size="18"  style="color: #ffffff;"></volvo-system-icon>
                    </div>
                  </div>
                </td>
              </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row  class="queue-element-row" *matRowDef="let row; columns: displayedColumns;"></tr>
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="displayedColumns.length">{{'Promoção não encontrada.' | translate}}</td>
              </tr>
            </table>
            <mat-paginator [length]="service.total | async" [pageSizeOptions]="[10,20,50,100]" (page)="onPageChange($event)"
            showFirstLastButtons
            aria-label="Select page of promotions">
            </mat-paginator>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  <div class="row ">
    <div class="div-button">
      <volvo-button variant="primary"  size="large" [routerLink]="['/promotion']" *ngIf="isPricingManager" >{{'Nova promoção' | translate}}</volvo-button>
      <volvo-button variant="primary" class="button" size="large" [routerLink]="['/']" >{{'Fechar' | translate}}</volvo-button>
    </div>
  </div>
</div>
