import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { GridDataSource } from 'src/app/services/grid-data-source.service';
import { PartRestriction } from '../models/part-restriction';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'app-part-restriction',
  templateUrl: './part-restriction.component.html',
  styleUrls: ['./part-restriction.component.css'],
  providers: [GridDataSource, DecimalPipe]
})
export class PartRestrictionComponent implements OnInit {

  @Output() clickMenuItem = new EventEmitter<string>();

  constructor(
    public service: GridDataSource<PartRestriction>
  ) { }

  ngOnInit(): void {
    this.clickMenuItem.emit('restriction');
    this.service.datasource.subscribe(resp => {
		});
  }

  PageChange(_event: Event){

  }
}
