<div #divCardPrincipal class="div-card-principal">
  <div class="quotation-main">
    <div class="quotation-data">
      <div class="div-row">
        <div class="div-dealer-group-dropdown">
          <div class="row-item">
            <ng-multiselect-dropdown
              id="dealer-group-dropdown"
              [settings]="dealerGroupDropdownSettings"
              [data]="dealerGroupFilters"
              (onSelect)="onDealerGroupSelect($event)"
              (onDeSelect)="onDealerGroupDeSelect($event)"
              [placeholder]="'Grupo Econômico' | translate"
              [(ngModel)]="selectedDealerGroup"
              [disabled]="!creating"
              class="singleselect quotation-font-size">
            </ng-multiselect-dropdown>
          </div>
        </div>
        <div class="second-col">
          <div class="div-icon"></div>
          <div class="row-item">
            <ng-multiselect-dropdown
              id="dealer-dropdown"
              [settings]="dealerDropdownSettings"
              [data]="dealerFilters"
              (onSelect)="onDealerSelect()"
              (onDeSelect)="onDealerDeSelect()"
              [placeholder]="'Concessionária' | translate"
              [(ngModel)]="selectedDealer"
              [disabled]="!creating"
              class="singleselect quotation-font-size">
            </ng-multiselect-dropdown>
          </div>
          <div class="div-icon"></div>
          <div class="row-item">
            <input class="input-quotation-name"
                type="text"
                [placeholder]="'Nome para o orçamento' | translate"
                [(ngModel)]="quotationName"
                (input)="inputName()"
                [disabled]="!creating"
                [maxlength]="50">
          </div>
        </div>
      </div>
      <div *ngIf="!isDealerStateValid()" class="div-row">
        <span class="span-red-color span-margin-left-5">{{'Não será possível carregar os preços nem calcular a rentabilidade pois a concessionária' | translate}} {{this.getThisDealerName()}} {{'não possui estado cadastrado.' | translate}}</span>
      </div>
      <div class="div-row" [ngClass]="{'disabled': !validQuotationData}">
        <div class="div-part-dropdown">
          <div class="row-item">
            <span class="span-red-color" *ngIf="showDuplicatePartMessage">{{duplicatePartMessage}}</span>
            <ng-multiselect-dropdown
              id="part-dropdown"
              [settings]="partDropdownSettings"
              [data]="partFilters"
              (onSelect)="onPartSelect($event)"
              (onDeSelect)="onPartDeSelect()"
              [placeholder]="partPlaceholder | translate"
              [searchable]="true"
              (onFilterChange)="onFilterChange($event)"
              [(ngModel)]="selectedParts"
              class="singleselect quotation-font-size"
              [disabled]="blocked || (!validQuotationData)"
              (onDropDownClose)="onPartDropdownClose()">
            </ng-multiselect-dropdown>
          </div>
        </div>
        <div class="second-col">
          <div class="div-icon">
            <volvo-system-icon filled icon="plus-circle" size="30" class="plus-icon" (click)="getPartsQuotationFromPartId()" [ngClass]="{'opacity': validQuotationData}"></volvo-system-icon>
          </div>
          <div class="copy-and-import" *ngIf="!hasParts">
            <div class="row-item">
              <div class="div-link-new-quotation" [ngClass]="{'underline': validQuotationData}" (click)="fileUpload.click()">
                <div class="div-link-new-quotation-icon" [ngClass]="{'cursor-pointer': validQuotationData}"><volvo-system-icon icon="import" size="36"></volvo-system-icon></div>
                <div class="div-link-new-quotation-span" [ngClass]="{'cursor-pointer': validQuotationData}"><span>{{'Importar lista de peças' | translate}}</span></div>
              </div>
            </div>
            <div class="div-icon"></div>
            <div class="row-item">
              <div class="div-link-new-quotation" [ngClass]="{'underline': validQuotationData}" (click)="openCopyModal()">
                <div class="div-link-new-quotation-icon" [ngClass]="{'cursor-pointer': validQuotationData}"><volvo-system-icon icon="copy" size="36"></volvo-system-icon></div>
                <div class="div-link-new-quotation-span" [ngClass]="{'cursor-pointer': validQuotationData}"><span>{{'Copiar peças de orçamentos anteriores' | translate}}</span></div>
              </div>
            </div>
          </div>
          <div class="package-discount" *ngIf="hasParts && !blocked">
            <input class="input-discount" type="number" [maxlength]="3" [(ngModel)]="discountPackage" min="0" max="100" step="1" (blur)="discountPackageInput($event)" [disabled]="blocked">
            <label>
              <input class="checkbox-discount" type="checkbox" [(ngModel)]="applyPackageDiscount" (change)="changePackageDiscount()" [disabled]="blocked">
              {{'Aplicar desconto ao pacote' | translate}}
            </label>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="hasParts" class="quotation-summary">
      <div class="display-flex">
        <div class="summary-no-discount">
          <span class="summary">{{'Resumo sem desconto' | translate}}</span>
          <div class="summary-container-1">
            <div class="summary-row">
              <div class="summary-item-1">
                {{'Total pedido' | translate}}
              </div>
              <div class="summary-item-2">
                R$ {{quotation?.totalWithoutDiscount | CurrencyWithoutSymbol: 'BRL'}}
              </div>
            </div>
            <div class="summary-row">
              <div class="summary-item-1">
                {{'Rentabilidade' | translate}}
              </div>
              <div class="summary-item-2">
                {{quotation?.profitabilityWithoutDiscount | numberWithoutRounding: 2}} %
              </div>
            </div>
          </div>
        </div>
        <div class="summary-discount">
          <span class="summary">{{'Resumo com desconto' | translate}}</span>
          <div class="summary-container-2">
            <div class="summary-row">
              <div class="summary-item-1">
                {{'Total pedido' | translate}}
              </div>
              <div class="summary-item-2">
                R$ {{quotation?.totalWithDiscount | CurrencyWithoutSymbol: 'BRL'}}
              </div>
            </div>
            <div class="summary-row">
              <div class="summary-item-1">
                {{'Rentabilidade' | translate}}
              </div>
              <div class="summary-item-2">
                {{quotation?.profitabilityWithDiscount | numberWithoutRounding: 2 }} %
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="display-flex">
        <div class="total-discount">
          <div class="summary-item-3">
            <span class="summary">{{'Total desconto' | translate}}</span>
          </div>
          <div class="summary-item-2">
            R$ {{quotation?.totalDiscount | CurrencyWithoutSymbol: 'BRL'}}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div #divQuotationGrid class="div-quotation-grid" (scroll)="onDivScroll()">
    <div #quotationGrid class="quotation-grid" *ngIf="hasParts">
      <table #matTable mat-table matSort [dataSource]="dataSource" (matSortChange)="announceSortChange($event)">
        <ng-container matColumnDef="fixedPrice" sticky>
          <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by fixedPrice"> {{'Preço fixo' | translate}} </th>
          <td mat-cell *matCellDef="let partQuotation; let i = index">
            <input [disabled]=" blocked || partQuotation.fixedPriceDisabled" class="value-input" type="text" [maxlength]="9" matInput [(ngModel)]="partQuotation.fixedPrice" currencyMask [options]="{ prefix: ' ', thousands: '.', decimal: ',', allowNegative: false }" (blur)="onFixedPriceChange(i)"
            [ngClass]="{'red-border': !isPartPriceOk(partQuotation)}">
          </td>
        </ng-container>
        <ng-container matColumnDef="discountPercent" sticky>
          <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by discountPercent"> {{'% Desc.' | translate}} </th>
          <td mat-cell *matCellDef="let partQuotation; let i = index">
            <input [disabled]=" blocked || partQuotation.discountPercentDisabled" class="value-input" type="number" [maxlength]="7" matInput [(ngModel)]="partQuotation.discountPercent" min="0" max="100" step="1" (blur)="onDiscountChange($event, i)"
            [ngClass]="{'red-border': invalidDiscountValue(partQuotation.discountPercent) || (!isPartPriceOk(partQuotation))}">
          </td>
        </ng-container>
        <ng-container matColumnDef="partCode" sticky>
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by partCode" class="width-110"> {{'Código da peça' | translate}} </th>
          <td mat-cell *matCellDef="let partQuotation" [ngClass]="warningText(partQuotation.warningsPart)"> {{partQuotation.partCode}} </td>
        </ng-container>
        <ng-container matColumnDef="warning" sticky>
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let partQuotation" class="no-padding">
            <div class="cursor-pointer" *ngIf="showWarning(partQuotation.warningsPart)">
              <i class="bi bi-exclamation-circle-fill orange-alert exclamation-icon-size" (click)="openWarningsModal(partQuotation.warningsPart)"></i>
            </div>
            <div class="cursor-pointer" *ngIf="showBlockedWarning(partQuotation.blocked)">
              <i class="bi bi-exclamation-diamond-fill red-alert exclamation-icon-size" (click)="openBlockedWarningsModal(partQuotation.reasonBlocked)" matTooltip="{{'Peça com bloqueio' | translate}}"></i>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="description" sticky>
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by description" class="width-150"> {{'Descrição peça' | translate}} </th>
          <td mat-cell *matCellDef="let partQuotation"> {{partQuotation.description}} </td>
        </ng-container>
        <ng-container matColumnDef="amount">
          <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by amount"> {{'Qtd. Peças' | translate}} </th>
          <td mat-cell *matCellDef="let partQuotation; let i = index">
            <input class="value-input" type="number" [maxlength]="7" matInput [(ngModel)]="partQuotation.amount" min="0" max="10000" step="1" [disabled]="blocked" (blur)="onAmountChange(i)"
            [ngClass]="{'red-border': !isPartAmountOk(partQuotation)}"
            [matTooltip]="partQuotation.errorMessage"
            [matTooltipDisabled]="partQuotation.errorMessage===''">
          </td>
        </ng-container>
        <ng-container matColumnDef="priceWithoutDiscount">
          <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by priceWithoutDiscount"
            class="width-150"
            matTooltip="{{'Preço de venda da peça para a concessionária sem desconto comercial' | translate}}">
            {{'Preço s/desc.' | translate}}
          </th>
          <td mat-cell *matCellDef="let partQuotation"> {{partQuotation.priceWithoutDiscount | CurrencyWithoutSymbol: 'BRL'}} </td>
        </ng-container>
        <ng-container matColumnDef="priceWithDiscount">
          <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by priceWithDiscount"
            class="width-150"
            matTooltip="{{'Preço de venda da peça para a concessionária com desconto comercial' | translate}}">
            {{'Preço c/desc.' | translate}} </th>
          <td mat-cell *matCellDef="let partQuotation"> {{partQuotation.priceWithDiscount | CurrencyWithoutSymbol: 'BRL'}} </td>
        </ng-container>
        <ng-container matColumnDef="profitabilityWithoutDiscount">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by profitabilityWithoutDiscount"
            class="width-150"
            matTooltip="{{'Rentabilidade da peça antes da aplicação do desconto' | translate}}">
            {{'Rentab. s/desc.' | translate}} </th>
          <td mat-cell *matCellDef="let partQuotation" [ngClass]="{'orange-text': invalidProfitabilityValue(partQuotation.profitabilityWithoutDiscount)}"> {{partQuotation.profitabilityWithoutDiscount | numberWithoutRounding: 3}} </td>
        </ng-container>
        <ng-container matColumnDef="profitabilityWithDiscount">
          <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by profitabilityWithDiscount"
            class="width-150"
            matTooltip="{{'Rentabilidade da peça após a aplicação do desconto' | translate}}">
            {{'Rentab. c/desc.' | translate}} </th>
          <td mat-cell *matCellDef="let partQuotation" [ngClass]="{'orange-text': invalidProfitabilityValue(partQuotation.profitabilityWithDiscount)}"> {{partQuotation.profitabilityWithDiscount | numberWithoutRounding: 3}} </td>
        </ng-container>
        <ng-container matColumnDef="promotion">
          <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by promotion" [ngClass]="getCurrentPromotionWidth()"> {{'Promo vigente' | translate}} </th>
          <td mat-cell *matCellDef="let partQuotation"> {{partQuotation.currentPromotion | partPromotionData: 'BRL'}} </td>
        </ng-container>
        <ng-container matColumnDef="boPart">
          <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by boPart"
            class="width-110"
            matTooltip="{{'Quantidade de peças atualmente em Back Order (BO)' | translate}}">
            {{'Peças em BO' | translate}} </th>
          <td mat-cell *matCellDef="let partQuotation"> {{partQuotation.part?.boPart}} </td>
        </ng-container>
        <ng-container matColumnDef="lastPromotion">
          <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by lastPromotion" class="width-150"> {{'Promo anterior' | translate}} </th>
          <td mat-cell *matCellDef="let partQuotation"> {{partQuotation.previousPromotion | partPromotionData: 'BRL'}} </td>
        </ng-container>
        <ng-container matColumnDef="functionGroup">
          <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by functionGroup"
            class="width-110"
            matTooltip="{{'Grupo de função da peça' | translate}}">
            {{'Grupo Função' | translate}} </th>
          <td mat-cell *matCellDef="let partQuotation"> {{partQuotation.part?.functionGroup}} </td>
        </ng-container>
        <ng-container matColumnDef="productGroup">
          <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by productGroup"
            class="width-110"
            matTooltip="{{'Aplicação da peça: V - Linha VM; F - Linha FH; B - BUS' | translate}}">
            {{'Aplic Peça' | translate}} </th>
          <td mat-cell *matCellDef="let partQuotation"> {{partQuotation.productGroup}} </td>
        </ng-container>
        <ng-container matColumnDef="kitInclude">
          <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by kitInclude"
            class="width-110"
            matTooltip="{{'Peça incluída em KIT e código do(s) kit(s).' | translate}}">
            {{'Incluída em Kit' | translate}} </th>
          <td mat-cell *matCellDef="let partQuotation">
            <div class="click-cell" *ngIf="partQuotation.part?.kitInclude" (click)="openKitList(partQuotation.partKits)">
              {{'Sim' | translate}}
            </div>
            <div *ngIf="!partQuotation.part?.kitInclude">
              {{ 'Não' | translate}}
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="part.c3stock">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by c3stock" class="width-110"> {{'Estoque C3' | translate}} </th>
          <td mat-cell *matCellDef="let partQuotation"> {{partQuotation.part?.c3stock}} </td>
        </ng-container>
        <ng-container matColumnDef="ranking">
          <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by ranking"
            class="width-110"
            matTooltip="{{'Ranking de venda da peça' | translate}}">
            {{'Ranking de venda' | translate}} </th>
          <td mat-cell *matCellDef="let partQuotation"> {{partQuotation.ranking}} </td>
        </ng-container>
        <ng-container matColumnDef="bulkpackOut">
          <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by bulkpackOut"
            class="width-110"
            matTooltip="{{'Quantidade de peças na embalagem mínima que pode ser vendida. Ex: 25 peças por embalagem.' | translate}}">
            {{'Embalagem mínima (bulkpack out)' | translate}} </th>
          <td mat-cell *matCellDef="let partQuotation"> {{partQuotation.part?.bulkpackOut}} </td>
        </ng-container>
        <ng-container matColumnDef="unitMeasurement">
          <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by unitMeasurement"
            class="width-110"
            matTooltip="{{'Unidade de medida. Ex: Caixa, litro, metro, etc.' | translate}}">
            {{'Unidade de medida (caixa, litro, metro, etc)' | translate}} </th>
          <td mat-cell *matCellDef="let partQuotation"> {{partQuotation.part?.unitMeasurement}} </td>
        </ng-container>
        <ng-container matColumnDef="replacedBy">
          <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by replacedBy"
            class="width-110"
            matTooltip="{{'Código da peça que foi substituída.' | translate}}">
            {{'Substituída a' | translate}} </th>
          <td mat-cell *matCellDef="let partQuotation"> {{partQuotation.replacedToPart?.partCode}} </td>
        </ng-container>
        <ng-container matColumnDef="risk">
          <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by risk"
            class="width-150"
            matTooltip="{{'Risco de restrição logística da peça.' | translate}}"> {{'Peça crítica risco restrição' | translate}} </th>
          <td mat-cell *matCellDef="let partQuotation"> {{partQuotation.risk | translate}} </td>
        </ng-container>
        <ng-container matColumnDef="monthlyPrognosis">
          <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by monthlyPrognosis"
            class="width-110"
            matTooltip="{{'Prognóstico mensal da peça. Mostra a estimatíva de giro por grupo econômico/concessionária. O quanto consome mensalmente de uma determinada peça em uma determinada concessionária.(ex. a peça X são 3,4 por mês para concessionária Y)' | translate}}">
            {{'Prognóstico mensal' | translate}} </th>
          <td mat-cell *matCellDef="let partQuotation"> {{partQuotation.monthlyPrognosis}} </td>
        </ng-container>
        <ng-container matColumnDef="monthlyForecast">
          <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by monthlyForecast"
            class="width-110"
            matTooltip="{{'Previsão mensal de consumo da peça' | translate}}">
            {{'Fcast. mensal' | translate}} </th>
          <td mat-cell *matCellDef="let partQuotation"> {{partQuotation.part?.monthlyForecast}} </td>
        </ng-container>
        <ng-container matColumnDef="dealerStock">
          <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by dealerStock"
            class="width-110"
            matTooltip="{{'Quantidade da peça no estoque da concessionária.' | translate}}">
            {{'Estoque rede' | translate}} </th>
          <td mat-cell *matCellDef="let partQuotation"> {{partQuotation.dealerStock}} </td>
        </ng-container>
        <ng-container matColumnDef="stockedLpa">
          <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by stockedLpa"
            class="width-110"
            matTooltip="{{'Se está na lista de peças que o LPA abastece automaticamente.' | translate}}">
            {{'Abastecida LPA' | translate}} </th>
          <td mat-cell *matCellDef="let partQuotation"> {{partQuotation.part?.stockedLpa ? 'Sim' : 'Não' | translate}} </td>
        </ng-container>
        <ng-container matColumnDef="stockedLpaDealer">
          <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by stockedLpaDealer"
            class="width-150"
            matTooltip="{{'Se para a concessionária deste orçamento a peça é abastecida automaticamente' | translate}}">
            {{'Rede abastecido LPA' | translate}} </th>
          <td mat-cell *matCellDef="let partQuotation"> {{partQuotation.stockedLpaDealer}} </td>
        </ng-container>
        <ng-container matColumnDef="planningPart">
          <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by planningPart"
            class="width-150"
            matTooltip="{{'Se a peça consta em algum planejamento em andamento.' | translate}}">
            {{'Peça em planejamento' | translate}} </th>
          <td mat-cell *matCellDef="let partQuotation">
            <div class="click-cell" *ngIf="showOtherPlannings(partQuotation.planningsWithPart)" (click)="openOtherPlanningsModal(partQuotation.planningsWithPart)">
              {{'Sim' | translate}}
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="partQuotationId">
          <th mat-header-cell *matHeaderCellDef class="width-40"></th>
          <td mat-cell *matCellDef="let partQuotation" class="td-delete">
            <span class="span-delete" (click)="removePartQuotation(partQuotation.partId)" matTooltip="{{'Remover peça do orçamento' | translate}}" *ngIf="!blocked">
              <i>
                <volvo-system-icon icon="trash" size="24"></volvo-system-icon>
              </i>
            </span>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></tr>
        <tr mat-row  class="queue-element-row" *matRowDef="let row; columns: displayedColumns;"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="displayedColumns.length">{{'Orçamento não encontrado.' | translate}}</td>
        </tr>
      </table>
    </div>
  </div>
  <div *ngIf="loadingParts"><volvo-progress-bar></volvo-progress-bar></div>
  <div #fillerQuotationGrid class="filler-quotation-grid"></div>
  <div class="display-flex">
    <div class="status-panel">
      <div>
        <span *ngIf="statusQuotation">{{'Status Orçamento: ' | translate}}</span>&nbsp;
        <span *ngIf="statusQuotation" [ngStyle]="{'color': statusQuotation === 'In negociation' ? 'green' : statusQuotation === 'Finished' ? 'red' : 'orange'}">{{ statusQuotation | translate}}</span>
      </div>
      <div>
        <span *ngIf="quotation?.planning">{{'Código Planejamento: ' | translate}}</span>&nbsp;
        <span *ngIf="quotation?.planning" class="click-cell" matTooltip="{{'Acessar pedido' | translate}}" (click)="onGoToPlanningClick()">{{ quotation?.planning?.code}}</span>
      </div>
    </div>
    <div class="button-panel">
      <div class="div-button">
        <volvo-button variant="primary" class="button-icon" (click)="onCancelClick()">{{'Cancelar' | translate}}</volvo-button>
      </div>
      <div class="div-button">
        <volvo-button variant="primary" class="button-icon" [disabled]="(!allowSave) || blocked || (!(creating || allLoaded))" (click)="onSaveClick()" [matTooltip]="!(creating || allLoaded) ? getTranslate('DisabledQuotationSaveButtonToolTipText') : ''">{{'Salvar' | translate}}</volvo-button>
      </div>
      <div class="div-button" *ngIf="!hasPlanning">
        <volvo-button variant="primary" class="button-icon" [disabled]="(!allowSave) || (!hasParts) || blocked || (!(creating || allLoaded))" (click)="onPlanClick()" [matTooltip]="!(creating || allLoaded) ? getTranslate('DisabledQuotationPlanningButtonToolTipText') : ''">{{'Planejar pedido' | translate}}</volvo-button>
      </div>
      <div class="div-button" *ngIf="hasPlanning">
        <volvo-button variant="primary" class="button-icon" (click)="onGoToPlanningClick()" [disabled]="!(creating || allLoaded)" [matTooltip]="!(creating || allLoaded) ? getTranslate('DisabledQuotationGoToPlanningButtonToolTipText') : ''">{{'Acessar pedido' | translate}}</volvo-button>
      </div>
    </div>
  </div>
</div>
<div #falseClick class="hidden"></div>
<input type="file" class="file-input" accept=".xlsx"
       (change)="onFileSelected($event)" #fileUpload
       [disabled]="!validQuotationData">
