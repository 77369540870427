import { Component } from '@angular/core';

@Component({
  selector: 'app-promotion-newsletter-delete',
  templateUrl: './promotion-newsletter-delete.component.html',
  styleUrls: ['./promotion-newsletter-delete.component.css']
})
export class PromotionNewsletterDeleteComponent {

}
