import { Component, Injector, Input, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Part } from 'src/app/modules/promotion/models/part';
import { GetTranslationService } from 'src/app/shared/utils/get-translation.service';
import { PartPromotionService } from '../../services/part-promotion.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { MultiSelectComponent } from 'ng-multiselect-dropdown';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';



@Component({
  selector: 'app-part-promotion-create',
  templateUrl: './part-promotion-create.component.html',
  styleUrls: ['./part-promotion-create.component.css']
})
export class PartPromotionCreateComponent {
  @Input() promotionId!: number;
  @ViewChild('partdropdown') dropdown!: MultiSelectComponent;


  form!: FormGroup;
  partDropdownSettings!: IDropdownSettings;
  partsFilters!: Part[];
  ShowFilter = false;
  price!: number;
  filterObject: any = {};
  buttonDisabled = true;
  errorMsg: boolean= false;

  constructor(
    private translateService: TranslateService,
    public getTranslationSvc: GetTranslationService,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    public dataService: PartPromotionService,
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private http: HttpClient){

      this.buildForm();

  }

   ngOnInit(): void {
    this.fillParts(this.filterObject);
    this.initializeSettings();
  }



  protected buildForm(): void {
    this.form = this.formBuilder.group({
      price: ['', [Validators.required]],
      partId: ['', [Validators.required]],
      discountPrice: ['', [Validators.required]],
      discountPercent: ['', [Validators.required]],
      initialDate: ['', [Validators.required]],
      promotionId: [''],
      createdBy: ['']
    }, { validators: this.validateValues });

  };

  initializeSettings() {
    this.partDropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'description',
      selectAllText: this.translateService.instant('Selecionar tudo'),
      unSelectAllText: this.translateService.instant('Limpar'),
      noDataAvailablePlaceholderText: this.translateService.instant('Nenhum dado encontrado'),
      itemsShowLimit: 2,
      allowSearchFilter: true
    };
  }

  onFilterChange(event: any): void {

    this.filterObject.searchKey = event;
    this.fillParts(this.filterObject);



  }

  toogleShowFilter() {
    this.ShowFilter = !this.ShowFilter;
    this.partDropdownSettings = Object.assign({}, this.partDropdownSettings, { allowSearchFilter: this.ShowFilter });
}

    fillParts(filterObject: any){
      this.dataService.listParts(1, 20,filterObject).subscribe(response => {
        this.partsFilters = response.data.data;
        this.partsFilters.forEach((item) => {
          item.description = item.partCode.toString() + ' - ' + item.description;
        });
      });
    }

    closeModal() {
      this.modalService.dismissAll();
    }

    check(confirmed: boolean) {
      if (confirmed == true) {
        return;
      } else {
        return;
      }
    }

    save(){
      this.spinner.show();
      this.form.get('promotionId')?.setValue(this.promotionId);
      this.dataService.createPartPromotion(this.form.value).subscribe(
       (sucess) =>{
        this.toastr.success(sucess.message,this.getTranslationSvc.getTranslation('Sucesso!'));
        location.reload();
       },
       (error) =>{
        this.spinner.hide();
        this.toastr.error(error.error.data.toString(), this.getTranslationSvc.getTranslation('Erro'));
       }
      );

    }


  onPartSelect(item: any){
    this.form.get('partId')?.setValue(item['id']);
    this.dropdown.closeDropdown();
    this.revalidateForm();
  }

  onPartDeSelect(item: any){
    this.form.get('partId')?.setValue(null);
    this.revalidateForm();
  }

  validateValues(formGroup: FormGroup){
    const partId = formGroup.get('partId')?.value;
    if(partId ==='')
      return null;

    const price = formGroup.get('price')?.value;
    const discountPrice = formGroup.get('discountPrice')?.value;
    const discountPercent = formGroup.get('discountPercent')?.value;
    const initialDate = formGroup.get('initialDate')?.value;

    if(price === "" ||
      price === 0 ||
      discountPrice === "" ||
      discountPrice === 0 ||
      discountPercent === "" ||
      discountPercent === null)
        return { invalidValues: true };

    if(initialDate === null ||
      initialDate === "")
        return { invalidDate: true };

    if(initialDate.getFullYear() < 1900 || initialDate.getFullYear() > 2100)
      return { invalidDate: true };

    return null;
  }

  revalidateForm(){
    this.errorMsg = !this.form.valid;
    this.buttonDisabled = !this.form.valid;
  }

  setDate(event: MatDatepickerInputEvent<Date>) {
    this.revalidateForm();
  }
}
