<div class="div-card-principal">
  <h1 class="brand-title">{{'Informações das peças' | translate}}</h1>
  <div class="separator-breadcrumb border-top"></div>
    <div>
      <div class="div-table">
        <mat-card>
          <mat-card-content>
            <table mat-table [dataSource]="dataSource">
              <ng-container matColumnDef="partCode" sticky>
                <th mat-header-cell *matHeaderCellDef> {{'Código da peça' | translate}} </th>
                <td mat-cell *matCellDef="let partPlanning"> {{partPlanning.partQuotation?.partCode}} </td>
              </ng-container>
              <ng-container matColumnDef="description" sticky>
                <th mat-header-cell *matHeaderCellDef class="width-150"> {{'Descrição peça' | translate}} </th>
                <td mat-cell *matCellDef="let partPlanning"> {{partPlanning.partQuotation?.description}} </td>
              </ng-container>
              <ng-container matColumnDef="fixedPrice">
                <th mat-header-cell *matHeaderCellDef> {{'Preço fixo' | translate}} </th>
                <td mat-cell *matCellDef="let partPlanning">{{partPlanning.partQuotation?.fixedPrice | CurrencyWithoutSymbol: 'BRL'}} </td>
              </ng-container>
              <ng-container matColumnDef="discountPercent">
                <th mat-header-cell *matHeaderCellDef> {{'% Desc.' | translate}} </th>
                <td mat-cell *matCellDef="let partPlanning">{{partPlanning.partQuotation?.discountPercent}} </td>
              </ng-container>
              <ng-container matColumnDef="priceWithoutDiscount">
                <th mat-header-cell *matHeaderCellDef class="width-110">
                  {{'Preço s/desc.' | translate}}
                </th>
                <td mat-cell *matCellDef="let partPlanning"> {{partPlanning.partQuotation?.priceWithoutDiscount | CurrencyWithoutSymbol: 'BRL'}} </td>
              </ng-container>
              <ng-container matColumnDef="priceWithDiscount">
                <th mat-header-cell *matHeaderCellDef class="width-110">
                  {{'Preço c/desc.' | translate}} </th>
                <td mat-cell *matCellDef="let partPlanning"> {{partPlanning.partQuotation?.priceWithDiscount | CurrencyWithoutSymbol: 'BRL'}} </td>
              </ng-container>
              <ng-container matColumnDef="profitabilityWithoutDiscount">
                <th mat-header-cell *matHeaderCellDef class="width-110">
                  {{'Rentab. s/desc.' | translate}} </th>
                <td mat-cell *matCellDef="let partPlanning"> {{partPlanning.partQuotation?.profitabilityWithoutDiscount | number: '1.3-3'}} </td>
              </ng-container>
              <ng-container matColumnDef="profitabilityWithDiscount">
                <th mat-header-cell *matHeaderCellDef class="width-110">
                  {{'Rentab. c/desc.' | translate}} </th>
                <td mat-cell *matCellDef="let partPlanning"> {{partPlanning.partQuotation?.profitabilityWithDiscount | number: '1.3-3'}} </td>
              </ng-container>
              <ng-container matColumnDef="promotion">
                <th mat-header-cell *matHeaderCellDef class="width-150"> {{'Promo vigente' | translate}} </th>
                <td mat-cell *matCellDef="let partPlanning"> {{partPlanning.partQuotation?.currentPromotion | partPromotionData: 'BRL'}} </td>
              </ng-container>
              <ng-container matColumnDef="boPart">
                <th mat-header-cell *matHeaderCellDef class="width-110">
                  {{'Peças em BO' | translate}} </th>
                <td mat-cell *matCellDef="let partPlanning"> {{partPlanning.partQuotation?.part?.boPart}} </td>
              </ng-container>
              <ng-container matColumnDef="lastPromotion">
                <th mat-header-cell *matHeaderCellDef class="width-150"> {{'Promo anterior' | translate}} </th>
                <td mat-cell *matCellDef="let partPlanning"> {{partPlanning.partQuotation?.previousPromotion | partPromotionData: 'BRL'}} </td>
              </ng-container>
              <ng-container matColumnDef="functionGroup">
                <th mat-header-cell *matHeaderCellDef class="width-110">
                  {{'Grupo Função' | translate}} </th>
                <td mat-cell *matCellDef="let partPlanning"> {{partPlanning.partQuotation?.part?.functionGroup}} </td>
              </ng-container>
              <ng-container matColumnDef="productGroup">
                <th mat-header-cell *matHeaderCellDef class="width-110">
                  {{'Aplic Peça' | translate}} </th>
                <td mat-cell *matCellDef="let partPlanning"> {{partPlanning.partQuotation?.productGroup}} </td>
              </ng-container>
              <ng-container matColumnDef="kitInclude">
                <th mat-header-cell *matHeaderCellDef class="width-110">
                  {{'Incluída em Kit' | translate}} </th>
                <td mat-cell *matCellDef="let partPlanning">
                  <div class="click-cell" *ngIf="partPlanning.partQuotation?.part?.kitInclude" (click)="openKitList(partPlanning.partQuotation?.partKits)">
                    {{'Sim' | translate}}
                  </div>
                  <div *ngIf="!partPlanning.partQuotation?.part?.kitInclude">
                    {{ 'Não' | translate}}
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="c3stock">
                <th mat-header-cell *matHeaderCellDef class="width-110"> {{'Estoque C3' | translate}} </th>
                <td mat-cell *matCellDef="let partPlanning"> {{partPlanning.partQuotation?.part?.c3stock}} </td>
              </ng-container>
              <ng-container matColumnDef="ranking">
                <th mat-header-cell *matHeaderCellDef class="width-110">
                  {{'Ranking de venda' | translate}} </th>
                <td mat-cell *matCellDef="let partPlanning"> {{partPlanning.partQuotation?.ranking}} </td>
              </ng-container>
              <ng-container matColumnDef="bulkpackOut">
                <th mat-header-cell *matHeaderCellDef class="width-110">
                  {{'Embalagem mínima (bulkpack out)' | translate}} </th>
                <td mat-cell *matCellDef="let partPlanning"> {{partPlanning.partQuotation?.part?.bulkpackOut}} </td>
              </ng-container>
              <ng-container matColumnDef="unitMeasurement">
                <th mat-header-cell *matHeaderCellDef class="width-110">
                  {{'Unidade de medida (caixa, litro, metro, etc)' | translate}} </th>
                <td mat-cell *matCellDef="let partPlanning"> {{partPlanning.partQuotation?.part?.unitMeasurement}} </td>
              </ng-container>
              <ng-container matColumnDef="replacedBy">
                <th mat-header-cell *matHeaderCellDef class="width-110">
                  {{'Substituída a' | translate}} </th>
                <td mat-cell *matCellDef="let partPlanning"> {{partPlanning.partQuotation?.replacedToPart?.partCode}} </td>
              </ng-container>
              <ng-container matColumnDef="risk">
                <th mat-header-cell *matHeaderCellDef class="width-150"> {{'Peça crítica risco restrição' | translate}} </th>
                <td mat-cell *matCellDef="let partPlanning"> {{partPlanning.partQuotation?.risk}} </td>
              </ng-container>
              <ng-container matColumnDef="monthlyPrognosis">
                <th mat-header-cell *matHeaderCellDef class="width-110">
                  {{'Prognóstico mensal' | translate}} </th>
                <td mat-cell *matCellDef="let partPlanning"> {{partPlanning.partQuotation?.monthlyPrognosis}} </td>
              </ng-container>
              <ng-container matColumnDef="monthlyForecast">
                <th mat-header-cell *matHeaderCellDef class="width-110">
                  {{'Fcast. mensal' | translate}} </th>
                <td mat-cell *matCellDef="let partPlanning"> {{partPlanning.partQuotation?.part?.monthlyForecast}} </td>
              </ng-container>
              <ng-container matColumnDef="dealerStock">
                <th mat-header-cell *matHeaderCellDef class="width-110">
                  {{'Estoque rede' | translate}} </th>
                <td mat-cell *matCellDef="let partPlanning"> {{partPlanning.partQuotation?.dealerStock}} </td>
              </ng-container>
              <ng-container matColumnDef="stockedLpa">
                <th mat-header-cell *matHeaderCellDef class="width-110">
                  {{'Abastecida LPA' | translate}} </th>
                <td mat-cell *matCellDef="let partPlanning"> {{partPlanning.partQuotation?.part?.stockedLpa ? 'Sim' : 'Não' | translate}} </td>
              </ng-container>
              <ng-container matColumnDef="stockedLpaDealer">
                <th mat-header-cell *matHeaderCellDef class="width-150">
                  {{'Rede abastecido LPA' | translate}} </th>
                <td mat-cell *matCellDef="let partPlanning"> {{partPlanning.partQuotation?.stockedLpaDealer}} </td>
              </ng-container>
              <ng-container matColumnDef="planningPart">
                <th mat-header-cell *matHeaderCellDef class="width-150">
                  {{'Peça em planejamento' | translate}} </th>
                <td mat-cell *matCellDef="let partPlanning">
                  <div class="click-cell" *ngIf="showOtherPlannings(partPlanning.planningsWithPart)" (click)="openOtherPlanningsModal(partPlanning.planningsWithPart)">
                    {{'Sim' | translate}}
                  </div>
                </td>
              </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row  class="queue-element-row" *matRowDef="let row; columns: displayedColumns;"></tr>
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="displayedColumns.length">{{'Orçamento não encontrado.' | translate}}</td>
              </tr>
            </table>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="button-panel">
        <div class="div-button">
          <volvo-button variant="primary" class="button-icon" (click)="close()">{{'Fechar' | translate}}</volvo-button>
        </div>
      </div>
    </div>
</div>
