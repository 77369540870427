import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { SetDefaultLanguageService } from "src/app/shared/utils/setDefaultLanguage";

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html'
})
export class NotFoundComponent implements OnInit {
  @Input() firstMessage!: string;
  @Input() secondMessage!: string;

  constructor(
    private translateService: TranslateService,
    private setLang: SetDefaultLanguageService
    ) {


    this.translateService.setDefaultLang(this.setLang.setDefaultLanguage());
    this.translateService.use(localStorage.getItem('language') || this.setLang.setDefaultLanguage())
   }

  ngOnInit(): void {
  }

}
