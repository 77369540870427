import { Component, EventEmitter, OnInit} from '@angular/core';

import { AppComponent } from 'src/app/app.component';
import { LocalStorageUtils } from 'src/app/shared/utils/localstorage';
import { MsalService } from '@azure/msal-angular';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from "@ngx-translate/core";
import { SetDefaultLanguageService } from "src/app/shared/utils/setDefaultLanguage";
import { MenuGroup } from 'src/app/shared/models/menuGroup';
import { MenuItem } from 'src/app/shared/models/menuItem';

const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';
type ProfileType = {
  givenName?: string,
  surname?: string,
  userPrincipalName?: string,
  id?: string,
  displayName?: string
}
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  public localStorage = new LocalStorageUtils();

  _opened: boolean = false;
  public isCollapsed: boolean;
  loginDisplay = false;
  closeResult = '';
  isDeveloper = false;
  isSelected = false;
  countryCode = '';
  role!: string;
  country = 'Brasil';
  initials! : string | undefined;
  profile!: ProfileType;
  profileId!: string;
  isHomeActive: boolean = true;

  menuGroups: MenuGroup[] = [];

  constructor(
    public appComponent: AppComponent,
    private authService: MsalService,
    private http: HttpClient,
    private translateService: TranslateService,
    private setLang: SetDefaultLanguageService
    ) {

      this.translateService.setDefaultLang(this.setLang.setDefaultLanguage());
      this.translateService.use(localStorage.getItem('language') || this.setLang.setDefaultLanguage());
      this.isCollapsed = true;

      this.countryCode = this.localStorage.getCurrentCountryCode();
      if(this.countryCode == 'AR'){
        this.country="Argentina";
      }else if(this.countryCode == 'BR'){
        this.country == "Brasil";
      }

      this.initializeMenu();
    }

  ngOnInit(): void {
    this.setLoginDisplay();
    this.getProfile();
    this.getProfileRole();

  }

  setCountryOrganization(event: any){
    localStorage.setItem('countryCode', event.detail.value);
    window.location.reload();
  }

  getProfile() {
    this.http.get(GRAPH_ENDPOINT)
      .subscribe(profile => {
        this.profile = profile;
        this.initials = this.profile.displayName!.charAt(this.profile.displayName!.indexOf(" ")+1) + this.profile.displayName![0];
      });
  }

  getProfileRole(){
    let key = <any>localStorage.getItem('TokenClaims');
    let token = <any>localStorage.getItem(key.toString());
    // this.role = JSON.parse(token).idTokenClaims.roles[0];
  }

  _toggleSidebar() {
    this._opened = !this._opened;
  }

  logout() {
    window.location.href = 'https://login.microsoftonline.com/common/oauth2/v2.0/logout';
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  changeLanguage(language: string) {
    localStorage.setItem('language', language);
    window.location.reload();
  }

  clickMenuItem(menu: string) {
    this.resetMenus();

    if(menu === 'home'){
      this.isHomeActive = true;
      return;
    }

    for (const group of this.menuGroups) {
      const menuItem = group.menuItems.find(item => item.id === menu);
      if (menuItem) {
        menuItem.active = true;
        break;
      }
    }
  }

  resetMenus() {
    this.isHomeActive = false;
    for (const group of this.menuGroups) {
      for (const item of group.menuItems) {
        item.active = false;
      }
    }
  }

  initializeMenu(){
    this.menuGroups = [];

    let menuItems: MenuItem[] = [];

    menuItems.push(this.newMenuItem('quotation', 'agenda', '/quotation', 'Orçamento'));
    menuItems.push(this.newMenuItem('planning', 'timeline-gantt', '/planning', 'Planejamento'));
    menuItems.push(this.newMenuItem('discount', 'credit-card', '/home', 'Simulação de Desconto'));
    menuItems.push(this.newMenuItem('search', 'search', '/home', 'Consultas'));
    menuItems.push(this.newMenuItem('userDealer', 'add-user', '/userdealer', 'Vínculo Concessionárias'));

    this.menuGroups.push(this.newMenuGroup('Parts Order Book', menuItems));
    menuItems = [];

    // menuItems.push(this.newMenuItem('classicParts', 'battery', '/home', 'Peças Clássicas'));
    // menuItems.push(this.newMenuItem('clients', 'fuel-efficient-driver-training', '/home', 'Clientes Diretos'));
    menuItems.push(this.newMenuItem('promotion', 'newspaper', '/promotionGrid', 'Promoção de Peças'));
    menuItems.push(this.newMenuItem('restriction', 'lock', '/restrictions', 'Peças com Restrição Logística'));

    this.menuGroups.push(this.newMenuGroup('Tabelas Comerciais', menuItems));
    menuItems = [];

    menuItems.push(this.newMenuItem('commercialParameters', 'truck-front-check', '/parametersCommercial', 'Comercial'));
    menuItems.push(this.newMenuItem('productsParameters', 'truck-front-add', '/parametersProductsAndPrices', 'Produtos & Preços'));
    menuItems.push(this.newMenuItem('systemsParameters', 'truck-front-settings', '/parametersSystems', 'Sistemas'));
    menuItems.push(this.newMenuItem('smlParameters', 'truck-front-template', '/parametersSml', 'SML'));

    this.menuGroups.push(this.newMenuGroup('Parâmetros', menuItems));
    menuItems = [];
  }

  newMenuItem(id: string, icon: string, link: string, text: string): MenuItem {
    return {id: id, active: false, icon: icon, link: link, text: text};
  }

  newMenuGroup(text: string, menuItems: MenuItem[]): MenuGroup{
    return {text: text, menuItems: menuItems, expanded: false};
  }

  highlightMenuItem(component: any) {
    if (component.clickMenuItem instanceof EventEmitter) {
      component.clickMenuItem.subscribe((menu: string) => {
        setTimeout(() => {
          this.clickMenuItem(menu);
        });
      });
    }
  }

  toggleExpanded(index: number) {
    this.menuGroups.forEach((menuGroup, i) => {
      menuGroup.expanded = i === index;
    });
  }
}
