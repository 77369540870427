<div #divCardPrincipal class="div-card-principal">
  <div class="div-param-planning">
    <div class="row">
      <div class="div-week-day col-sm-6">
        <div class="div-info">
          <volvo-typography variant='heading6'>
            {{'Dia da semana para liberação de pedido' | translate}}
          </volvo-typography>
          <volvo-system-icon icon="info" size="24" class="margin-left-10" ngbTooltip="{{'Tooltip Dia da semana para liberação de pedido' | translate }}"></volvo-system-icon>
        </div>
        <div class="row">
          <mat-card>
            <mat-card-content>
              <table mat-table [dataSource]="dataSource">
                <ng-container matColumnDef="dealerGroupDescription">
                  <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by dealer"> {{'Grupo Econômico' | translate}} </th>
                  <td mat-cell *matCellDef="let dealerGroup"> {{dealerGroup.dealerGroupDescription}} </td>
                </ng-container>
                <ng-container matColumnDef="dayWeek">
                  <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by dealer"> {{'Dia da Semana' | translate}} </th>
                  <td mat-cell *matCellDef="let dealerGroup">
                    <select [(ngModel)]="dealerGroup.dayWeek" class="select-week-day" (click)="changeDayWeek()">
                      <option *ngFor="let weekDay of weekDays" [value]="weekDay.value">{{ weekDay.name }}</option>
                    </select>
                  </td>
                </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row  class="queue-element-row" *matRowDef="let row; columns: displayedColumns;"></tr>
                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="displayedColumns.length">{{'Grupo Econômico não encontrado.' | translate}}</td>
                </tr>
              </table>
            </mat-card-content>
          </mat-card>
        </div>
        <!----------->
      </div>
      <div class="div-params col-sm-6">
        <div class="div-info">
          <volvo-typography variant='heading6'>
            {{'Período firme' | translate}}
          </volvo-typography>
          <volvo-system-icon icon="info" size="24" class="margin-left-10" ngbTooltip="{{'Tooltip Período firme' | translate }}"></volvo-system-icon>
        </div>
        <div class="row div-week-params">
          <div class="div-input-week col-sm-6">
            <input class="input-week" type="number" [maxlength]="3" [(ngModel)]="firmPeriod" min="0" max="52" step="1" (blur)="onFirmPeriodBlur($event)">
            <div class="div-text">
              <volvo-typography variant='subtitle2'>
                {{'Semanas' | translate}}
              </volvo-typography>
            </div>
          </div>
        </div>
        <div class="margin-top-50 div-info">
          <volvo-typography variant='heading6'>
            {{'Quantidade de semanas do planejamento' | translate}}
          </volvo-typography>
          <volvo-system-icon icon="info" size="24" class="margin-left-10" ngbTooltip="{{'Tooltip Quantidade de semanas do planejamento' | translate }}"></volvo-system-icon>
        </div>
        <div class="row div-week-params">
          <div class="div-input-week col-sm-6">
            <input class="input-week" type="number" [maxlength]="3" [(ngModel)]="weekQuantity" min="1" max="25" step="1" (blur)="onWeekQuantityBlur($event)">
            <div class="div-text">
              <volvo-typography variant='subtitle2'>
                {{'Semanas' | translate}}
              </volvo-typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="div-filler"></div>
  <div class="row ">
    <div class="div-button">
      <volvo-button variant="primary" size="large" (click)="goBack()" class="back-button">{{'Voltar' | translate}}</volvo-button>
      <volvo-button variant="primary" size="large" (click)="save()">{{'Salvar' | translate}}</volvo-button>
    </div>
  </div>
</div>
