<div class="div-card-principal">
  <h1 class="brand-title">{{'Peças Trucks/Bus com Restrição Logística' | translate}}</h1>
  <div class="separator-breadcrumb border-top"></div>
  <div>
      <div>
          <nav class="" aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a [routerLink]="['../home']" routerLinkActive="router-link-active">{{'Home'}}</a>
              </li>
              <li class="active breadcrumb-item" aria-current="page">{{'Peças com Restrição Logística' | translate}}</li>
            </ol>
          </nav>
        </div>
        <app-part-restriction-grid></app-part-restriction-grid>
  </div>
</div>
