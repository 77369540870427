import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map } from 'rxjs';
import { BaseService } from 'src/app/services/base.service';
import { PartPromotion } from '../models/partPromotion';
import { environment } from 'src/environments/environment';
import { PagedResponse, ResponseResult } from 'src/app/services/grid-data-source.service';
import { Promotion } from '../models/promotion';
import { PromotionType } from '../models/promotionType';
import { Newsletter } from '../../promotion-newsletter/models/newsletter';

@Injectable({
  providedIn: 'root'
})
export class PromotionService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }

  getAllPromotion(filterObject:any): Observable<PagedResponse<Promotion>> {

    return this.http
    .get<PagedResponse<Promotion>>(`${environment.UrlApiPartsOrderBook}Promotion`, {params: filterObject, headers: super.GetAuthTokenHeader().headers})
    .pipe(
      map(super.extract),
      catchError(super.serviceError));
  }

  getAllPromotionType(): Observable<PagedResponse<PromotionType>> {

    return this.http
    .get<PagedResponse<PromotionType>>(`${environment.UrlApiPartsOrderBook}PromotionType`, {headers: super.GetAuthTokenHeader().headers})
    .pipe(
      map(super.extract),
      catchError(super.serviceError));
  }

  sendNewsletter(promotionId: string): Observable<ResponseResult<Promotion>> {

    return this.http
    .post<ResponseResult<Promotion>>(`${environment.UrlApiPartsOrderBook}Promotion/sendEmail/${promotionId}`, null, {headers: super.GetAuthTokenHeader().headers})
    .pipe(
      map(super.extract),
      catchError(super.serviceError));
  }
  deleteNewsletter(email: any): Observable<ResponseResult<Promotion>> {

    return this.http
    .delete<ResponseResult<Newsletter>>(`${environment.UrlApiPartsOrderBook}NewsletterPromotion/${email}`, {headers: super.GetAuthTokenHeader().headers})
    .pipe(
      map(super.extract),
      catchError(super.serviceError));
  }

  getByEmailNewsletter(email: any): Observable<ResponseResult<Newsletter>> {

    return this.http
    .get<ResponseResult<Newsletter>>(`${environment.UrlApiPartsOrderBook}NewsletterPromotion/${email}`, {headers: super.GetAuthTokenHeader().headers})
    .pipe(
      map(super.extract),
      catchError(super.serviceError));
  }

  public createPromotion(promotion: Promotion, formData: FormData) {

    return this.http
      .post(`${environment.UrlApiPartsOrderBook}Promotion?promotionTypeId=${promotion.promotionTypeId}&name=${promotion.name}&initialDate=${promotion.initialDate}&finalDate=${promotion.finalDate}`, formData, {headers: super.GetAuthTokenHeader().headers, responseType: 'arraybuffer', observe: 'response'})
      .pipe(
        map(super.extract),
        catchError(super.serviceError));
  }

  deletePromotion(id : string, reason: string): Observable<ResponseResult<Promotion>> {

    return this.http
      .delete<ResponseResult<Promotion>>(`${environment.UrlApiPartsOrderBook}Promotion/${id}/${reason}`, {headers: super.GetAuthTokenHeader().headers})
      .pipe(
        map(super.extract),
        catchError(super.serviceError));
  }


  getPromotionById(Id: string): Observable<ResponseResult<Promotion>> {

    return this.http
    .get<ResponseResult<Promotion>>(`${environment.UrlApiPartsOrderBook}Promotion/${Id}`, {headers: super.GetAuthTokenHeader().headers})
    .pipe(
      map(super.extract),
      catchError(super.serviceError));
  }

  downloadReport(promotionId: string) {
    const headers = {
      'Content-Type': 'application/json',
      'Language-Code': `${this.LocalStorage.getCurrentLanguage()}`,
      'Authorization': `Bearer ${this.LocalStorage.getUserToken()}`,
    }
    return this.http
      .get(`${environment.UrlApiPartsOrderBook}PartPromotion/Download/${promotionId}`, {headers: super.GetAuthTokenHeader().headers, responseType: 'arraybuffer', observe: 'response'})
  }


  downloadFile(data: any, type: string, fileName: string) {
    let blob = new Blob([data], { type: type });
    let url = window.URL.createObjectURL(blob);
    let downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(blob);
    downloadLink.setAttribute('download', fileName);
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

}
