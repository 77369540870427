import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { PartPromotion } from 'src/app/modules/promotion/models/partPromotion';
import { GridDataSource, PagedRequest } from 'src/app/services/grid-data-source.service';
import {Sort} from '@angular/material/sort';
import { LocalStorageUtils } from 'src/app/shared/utils/localstorage';
import { PartPromotionService } from '../../services/part-promotion.service';
import { TranslateService } from '@ngx-translate/core';
import { SetDefaultLanguageService } from 'src/app/shared/utils/setDefaultLanguage';
import { NgxSpinnerService } from 'ngx-spinner';
import { GetTranslationService } from 'src/app/shared/utils/get-translation.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { PartPromotionCreateComponent } from '../part-promotion-create/part-promotion-create.component';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { MatPaginator } from '@angular/material/paginator';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { PromotionService } from 'src/app/modules/promotion/services/promotion.service';
import { DatePipe } from '@angular/common';
import { Promotion } from 'src/app/modules/promotion/models/promotion';
import { PartPromotionDeleteComponent } from '../part-promotion-delete/part-promotion-delete.component';
import { EnumPromotionStatus } from 'src/app/shared/models/enumPromotionStatus';


@Component({
  selector: 'app-part-promotion-grid',
  templateUrl: './part-promotion-grid.component.html',
  styleUrls: ['./part-promotion-grid.component.css']
})
export class PartPromotionGridComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator!: MatPaginator;
  @Output() downloadReportEvent: EventEmitter<any> = new EventEmitter();

  totalItems = 100;
  currentPage = 0;
  pageSize = 5;
  maskDate: string = 'dd/MM/YYYY';
  panelOpenState = false;
  displayedColumns: string[] = ['part.partCode', 'part.description', 'price', 'discountPrice', 'discountPercent', 'isActive', 'initialDate', 'finalDate', 'createdAt', 'updatedAt', 'promotionId'];
  filterObject: any = {};
  partpromotion: PartPromotion[] = [];
  updatedAt!: Date;
  showLastUpdate = false;
  promotionName: string ='';
  public LocalStorage = new LocalStorageUtils();
  promotionId!: string;
  datePipe: DatePipe = new DatePipe('en-US');
  promotion!: Promotion;
  isPricingManager = false;
  searchTerm!: string;
  activePromotion = false;

  dataSource = new MatTableDataSource<PartPromotion>(this.partpromotion);

  constructor(
    private http: HttpClient,
    public service: GridDataSource<PartPromotion>,
    public dataService: PartPromotionService,
    public promotionDataService: PromotionService,
    private translateService: TranslateService,
    private setLang: SetDefaultLanguageService,
    private spinner: NgxSpinnerService,
    private getTranslationSvc: GetTranslationService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private modalService: NgbModal) {

    this.setIsPricingManager();
    this.translateService.setDefaultLang(this.setLang.setDefaultLanguage());
    this.translateService.use(localStorage.getItem('language') || this.setLang.setDefaultLanguage());

    this.route.params.subscribe(params => {
      this.promotionId = params['promotionId'];
      this.filterObject.promotionId = this.promotionId;
    });

    var _dataService = this.dataService;
    this.filterObject.limit = 10;
    this.service.configureDataSource((request: PagedRequest<PartPromotion>) => _dataService.getAllPromotion(this.filterObject));
    this.service.datasource.subscribe(
      response => {
       this.spinner.show();
       this.partpromotion = response;
       this.dataSource = new MatTableDataSource<PartPromotion>(this.partpromotion);
       this.lastUpdated();
       setTimeout(() => {
         this.spinner.hide();
       }, 1000);
      });


  }

  ngOnInit(): void {
   this.getPromotion();
   this.spinner.show();
   setTimeout(() => {
      this.spinner.hide();
   }, 1000);


  }

  getPromotion(){
    this.promotionDataService.getPromotionById(this.promotionId).subscribe(
      response => {
        this.promotion = response?.data;
        this.setActivePromotion(this.promotion);
      }, error => {
        this.toastr.error(this.getTranslationSvc.getTranslation("Problema ao tentar fazer download do relatório"), this.getTranslationSvc.getTranslation('Erro'), { timeOut: 3000, extendedTimeOut: 3000 });
      })
  }

  getDate(partPromotionCreatedAt : Date): Date{

   return this.datePipe.transform(partPromotionCreatedAt, 'yyyy-MM-dd') !== this.datePipe.transform(this.promotion.createdAt, 'yyyy-MM-dd')
         ? partPromotionCreatedAt
         : this.promotion.initialDate;
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
   }

   ngAfterViewChecked(){
    this.paginator._intl.itemsPerPageLabel = this.getTranslationSvc.getTranslation("Itens por página:");
    this.paginator._intl.firstPageLabel = this.getTranslationSvc.getTranslation("Primeira página");
    this.paginator._intl.lastPageLabel = this.getTranslationSvc.getTranslation("Última página");
    this.paginator._intl.nextPageLabel = this.getTranslationSvc.getTranslation("Próxima página");
    this.paginator._intl.previousPageLabel = this.getTranslationSvc.getTranslation("Página anterior");
  }

  onStatusSelect(item: any) {

    this.refreshGrid();
  }

  onPageChange(event: any) {
    this.filterObject.page = event.pageIndex+1
    this.refreshGrid();
  }


  search() {
    delete this.filterObject.searchKey;
    if(this.searchTerm)
      this.filterObject.searchKey = this.searchTerm;
    this.refreshGrid();
  }


  refreshGrid(){
    this.filterObject.limit = this.paginator.pageSize;
    this.service.refresh();
    this.lastUpdated();
  }

  lastUpdated(){

    this.showLastUpdate = false;
    if(this.partpromotion.length > 0){
      this.updatedAt = this.partpromotion[0].updatedAt;
      this.showLastUpdate = true;
    }
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction == 'asc') {
      this.filterObject.fieldNameOrderBy = sortState.active;
      this.filterObject.isOrderByDesc = false;
    } else if (sortState.direction == 'desc') {
      this.filterObject.fieldNameOrderBy = sortState.active;
      this.filterObject.isOrderByDesc = true;
    } else {
      this.filterObject.fieldNameOrderBy = 'id';
      this.filterObject.isOrderByDesc = false;
    }
    this.refreshGrid();
  }

  parseHeaders(responseHeader: any) {
		let contentDisposition = responseHeader.get('content-disposition');
		return contentDisposition.split(';')[1].split('=')[1];
	}

  openCreateModal() {
    const modalRef = this.modalService.open(PartPromotionCreateComponent, { size: 'lg' });
  }

  deletePartPromotion(id: string){
    const modalRef = this.modalService.open(PartPromotionDeleteComponent, {size: 'md' });
    modalRef.componentInstance.partId = id;
    modalRef.componentInstance.promotionId = '00000000-0000-0000-0000-000000000000';
    modalRef.componentInstance.title = this.getTranslationSvc.getTranslation("Exclusão de Peça");
    modalRef.componentInstance.message = this.getTranslationSvc.getTranslation("Confirma a exclusão da peça da promoção?");
    modalRef.componentInstance.dateLabel = this.getTranslationSvc.getTranslation("Removida da promo a partir de");
    modalRef.componentInstance.refreshGrid.subscribe(() => {
      this.refreshGrid();
    });
  }

  confirm(
    title: string,
    message: string,
    btnOkText: string = this.getTranslationSvc.getTranslation("Sim"),
    btnCancelText: string = this.getTranslationSvc.getTranslation("Não"),
    dialogSize: 'sm' | 'lg' = 'lg'): Promise<boolean> {
    const modalRef = this.modalService.open(ConfirmationDialogComponent, { size: dialogSize });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.btnOkText = btnOkText;
    modalRef.componentInstance.btnCancelText = btnCancelText;

    return modalRef.result;
  }

  setIsPricingManager(){
    this.isPricingManager = this.LocalStorage.searchGroup(environment.pricingManagerGroupId);
  }

  downloadReport(){
    this.downloadReportEvent.emit();
  }

  setActivePromotion(promotion: Promotion) {
    this.activePromotion = false;

    if(!promotion)
      return;

    if(!promotion.promotionStatus)
      return;

    if(!promotion.promotionStatus.id)
      return;

    this.activePromotion = this.isActivePromotion(promotion.promotionStatus.id.toString());
  }

  isActivePromotion(statusId: string){
    if(statusId.toLowerCase() === EnumPromotionStatus.Inactive.toLowerCase())
      return false;

    if(statusId.toLowerCase() === EnumPromotionStatus.Closed.toLowerCase())
      return false;

    return true;
  }

  getFinalDate(partPromotion: PartPromotion): Date | null{
    if(!partPromotion){
      return null;
    }

    if(partPromotion.finalDate){
      return partPromotion.finalDate;
    }

    if(partPromotion.promotionFinalDate){
      return partPromotion.promotionFinalDate;
    }

    return null;
  }
}
