<div class="div-import-list">
  <div class="div-heading6">
    <volvo-typography variant='heading6'>
      {{'ATUALIZAR LISTA DE PEÇAS / RISCO' | translate}}
    </volvo-typography>
  </div>
  <div class="div-import">
    <div class="div-import-icon">
      <span class="div-import-span" (click)="fileUpload.click()">
        <volvo-system-icon icon="import" size="24"></volvo-system-icon>
        &nbsp;{{fileName || "Importar lista de peças"  | translate}}
      </span>
    </div>
    <div>
      <volvo-button size="small" variant="primary" [disabled]="importDisabled" (click)="handleFileInput()">
        {{'Atualizar' | translate}}
      </volvo-button></div>
  </div>
  <div class="div-import-label">
    {{'(Tipo do arquivo: .xlsx - tamanho máx: 2MB)' | translate}}
  </div>
</div>
<input type="file" class="file-input" accept=".xlsx"
       (change)="onFileSelected($event)" #fileUpload>
