import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, catchError, map } from "rxjs";
import { BaseService } from "src/app/services/base.service";
import { PagedResponse, ResponseResult } from "src/app/services/grid-data-source.service";
import { environment } from "src/environments/environment";
import { ExportQuotationToCsvModel, UpInsertQuotationModel } from "../models/quotation";
import { GetTranslationService } from "src/app/shared/utils/get-translation.service";
import { Dealer } from "src/app/shared/models/dealer";
import { Part } from "src/app/shared/models/part";
import { Quotation } from "src/app/shared/models/quotation";
import { StatusQuotation } from "src/app/shared/models/statusQuotation";
import { DealerGroup } from "src/app/shared/models/dealerGroup";
import { PartQuotation } from "src/app/shared/models/partQuotation";

@Injectable({
  providedIn: 'root'
})

export class QuotationService extends BaseService {

  constructor(private http: HttpClient,
    public getTranslationSvc: GetTranslationService) {
    super();
  }

  getParts(page: number, pageSize: number, filterObject:any): Observable<PagedResponse<Part>> {

    return this.http
    .get<PagedResponse<Part>>(`${environment.UrlApiPartsOrderBook}Part/${page}/${pageSize}`, {params: filterObject, headers: super.GetAuthTokenHeader().headers})
    .pipe(
      map(super.extract),
      catchError(super.serviceError));
  }

  getAllQuotation(filterObject:any): Observable<PagedResponse<Quotation>> {

    return this.http
    .get<PagedResponse<Quotation>>(`${environment.UrlApiPartsOrderBook}Quotation/${filterObject.page}/${filterObject.limit}`, {params: filterObject, headers: super.GetAuthTokenHeader().headers})
    .pipe(
      map(super.extract),
      catchError(super.serviceError));
  }

  updateQuotation(model: UpInsertQuotationModel): Observable<ResponseResult<UpInsertQuotationModel>> {
    return this.http
      .put<ResponseResult<UpInsertQuotationModel>>(`${environment.UrlApiPartsOrderBook}Quotation`, model, {headers: super.GetAuthTokenHeader().headers})
      .pipe(
        map(super.extract),
        catchError(super.serviceError));
  }

  deleteQuotation(id: string): Observable<ResponseResult<Quotation>> {
    return this.http
      .delete<ResponseResult<Quotation>>(`${environment.UrlApiPartsOrderBook}Quotation/${id}`, {headers: super.GetAuthTokenHeader().headers})
      .pipe(
        map(super.extract),
        catchError(super.serviceError));
  }

  getQuotation(id: string): Observable<ResponseResult<Quotation>> {
    return this.http
      .get<ResponseResult<Quotation>>(`${environment.UrlApiPartsOrderBook}Quotation/${id}`, {headers: super.GetAuthTokenHeader().headers})
      .pipe(
        map(super.extract),
        catchError(super.serviceError));
  }

  copyFromQuotation(id: string, stateAddress: string): Observable<ResponseResult<Quotation>> {
    return this.http
      .get<ResponseResult<Quotation>>(`${environment.UrlApiPartsOrderBook}PartQuotation/CopyFromQuotation/${id}/${stateAddress}`, {headers: super.GetAuthTokenHeader().headers})
      .pipe(
        map(super.extract),
        catchError(super.serviceError));
  }

  getPartsQuotationFromFormData(model: FormData, stateAddress: string): Observable<ResponseResult<Quotation>> {
    return this.http
      .post<ResponseResult<Quotation>>(`${environment.UrlApiPartsOrderBook}PartQuotation/Upload/${stateAddress}`, model, {headers: super.GetAuthTokenHeader().headers})
      .pipe(
        map(super.extract),
        catchError(super.serviceError));
  }

  insertQuotation(model: UpInsertQuotationModel): Observable<ResponseResult<UpInsertQuotationModel>> {
    return this.http
      .post<ResponseResult<UpInsertQuotationModel>>(`${environment.UrlApiPartsOrderBook}Quotation`, model, {headers: super.GetAuthTokenHeader().headers})
      .pipe(
        map(super.extract),
        catchError(super.serviceError));
  }

  getImportPartsWithReason(model: PartQuotation[]) {
    return this.http
      .post(`${environment.UrlApiPartsOrderBook}PartQuotation/ImportPartsWithReason`, {partsQuotation: model}, {headers: super.GetAuthTokenHeader().headers, responseType: 'arraybuffer', observe: 'response'})
      .pipe(
        map(super.extract),
        catchError(super.serviceError));
  }

  downloadFile(data: any, type: string, fileName: string) {
    let blob = new Blob([data], { type: type });
    let url = window.URL.createObjectURL(blob);
    let downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(blob);
    downloadLink.setAttribute('download', fileName);
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  calculateProfitability(model: Quotation): Observable<ResponseResult<Quotation>> {
    return this.http
      .post(`${environment.UrlApiPartsOrderBook}Quotation/CalculateProfitability`, model, {headers: super.GetAuthTokenHeader().headers})
      .pipe(
        map(super.extract),
        catchError(super.serviceError));
  }

  addPartQuotation(partId: string, model: Quotation): Observable<ResponseResult<Quotation>> {
    return this.http
      .post(`${environment.UrlApiPartsOrderBook}PartQuotation/AddPartQuotation/${partId}`, model, {headers: super.GetAuthTokenHeader().headers})
      .pipe(
        map(super.extract),
        catchError(super.serviceError));
  }

  exportQuotationToCsv(model: ExportQuotationToCsvModel) {
    return this.http
      .post(`${environment.UrlApiPartsOrderBook}PartQuotation/ExportQuotationToCsv`, model, {headers: super.GetAuthTokenHeader().headers, responseType: 'arraybuffer', observe: 'response'})
      .pipe(
        map(super.extract),
        catchError(super.serviceError));
  }

  getPartsQuotationFromQuotationId(id: string | null, page: number, pageSize: number): Observable<PagedResponse<PartQuotation>> {
    return this.http
      .get<PagedResponse<PartQuotation>>(`${environment.UrlApiPartsOrderBook}PartQuotation/${id}/${page}/${pageSize}`, {headers: super.GetAuthTokenHeader().headers})
      .pipe(
        map(super.extract),
        catchError(super.serviceError));
  }
}
