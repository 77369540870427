import { Component } from '@angular/core';
import { LocalStorageUtils } from 'src/app/shared/utils/localstorage';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-parameters-systems',
  templateUrl: './parameters-systems.component.html',
  styleUrl: './parameters-systems.component.css'
})
export class ParametersSystemsComponent {
  isIt = false;

  public LocalStorage = new LocalStorageUtils();

  constructor() {
    this.setIsIt();
  }

  setIsIt(){
    this.isIt = this.LocalStorage.searchGroup(environment.itGroupId);
  }
}
