<div class="container div-denied">
  <div class="row justify-content-center">
    <div class="justify-content-center">
      <div>
        <div>
          <div class="row align-items-center">
            <div>
              <div *ngIf="!firstMessage">
                <h3 style="font-family: 'Volvo Novum', sans-serif !important; color: #0D3896 !important; font-size: 80%;">OOOPS :( <br><br> {{ 'Parece que a página não existe ou você não tem permissão para acessar!' | translate}} </h3>
                <p style="font-family: 'Volvo Novum', sans-serif !important; color: #0D3896 !important; font-size: 80%;"> {{ 'Para solicitar acesso,' | translate}}<a href="http://faros.volvo.com/UserAdm/default.aspx">{{'clique aqui' | translate}}</a> </p>
              </div>
              <div *ngIf="firstMessage">
                <h3 style="font-family: 'Volvo Novum', sans-serif !important; color: #0D3896 !important; font-size: 80%;">OOOPS :( <br><br> {{ firstMessage | translate}} </h3>
                <p *ngIf="secondMessage" style="font-family: 'Volvo Novum', sans-serif !important; color: #0D3896 !important; font-size: 80%;"> {{ secondMessage | translate}}</p>
              </div>
            </div>
            <div><img class="img-fluid"  style="max-width: 19rem" src="/assets/img/at-work.JPG"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
