import { CurrencyPipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";



@Pipe({
  name: 'CurrencyWithoutSymbol'
})


export class CurrencyWithoutSymbolPipe   implements PipeTransform {
  transform(value: number | null | undefined, currencyCode: string, digitsInfo?: string): string {
    if(!value){
      value = 0;
    }
    const pipe = new CurrencyPipe('pt-BR');
    const formattedValue = pipe.transform(value, currencyCode, '', digitsInfo);
    return formattedValue!.replace(/[^0-9.,]/g, '');
  }
}
