
import { Component } from '@angular/core'
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html'
})

export class FooterComponent {
  environmentName = environment.EnvironmentPlaceName
}
