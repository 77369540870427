import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotFoundComponent } from './Core/Navigation/Component/not-found/not-found.component';
import { ProfileComponent } from './Core/Navigation/Component/profile/profile.component';
import { HomeComponent } from './Core/Navigation/Component/home/home.component';
import { BrowserUtils } from '@azure/msal-browser';
import { MsalGuard } from '@azure/msal-angular';
import '@volvo/vcdk'; //
import { PartRestrictionComponent } from './modules/part-restriction/component/part-restriction.component';
import { UserDealerComponent } from './modules/user-dealer/component/user-dealer.component';
import { PromotionComponent } from './modules/promotion/component/promotion/promotion.component';
import { PromotionGridComponent } from './modules/promotion/component/promotion-grid/promotion-grid.component';
import { PartPromotionComponent } from './modules/part-promotion/component/part-promotion/part-promotion.component';
import { QuotationComponent } from './modules/quotation/component/quotation.component';
import { QuotationGridComponent } from './modules/quotation/component/quotation-grid/quotation-grid.component';
import { ParametersCommercialComponent } from './modules/parameters/component/parameters-commercial/parameters-commercial.component';
import { ParametersProductsAndPricesComponent } from './modules/parameters/component/parameters-products-and-prices/parameters-products-and-prices.component';
import { CreatePlanningComponent } from './modules/planning/component/create-planning/create-planning.component';
import { PlanningGridComponent } from './modules/planning/component/planning-grid/planning-grid.component';
import { ParametersSmlComponent } from './modules/parameters/component/parameters-sml/parameters-sml.component';
import { ParametersSystemsComponent } from './modules/parameters/component/parameters-systems/parameters-systems.component';

const routes: Routes = [
  { path: '', component: HomeComponent, canActivate: [MsalGuard]},
  { path: 'home', component: HomeComponent, canActivate: [MsalGuard]},
  { path: 'restrictions', component: PartRestrictionComponent, canActivate: [MsalGuard]},
  { path: 'userdealer', component: UserDealerComponent, canActivate: [MsalGuard]},
  { path: 'promotion', component: PromotionComponent, canActivate: [MsalGuard]},
  { path: 'promotionGrid', component: PromotionGridComponent, canActivate: [MsalGuard]},
  { path: 'partpromotion/:promotionId', component: PartPromotionComponent, canActivate: [MsalGuard]},
  { path: 'quotation', component: QuotationGridComponent, canActivate: [MsalGuard]},
  { path: 'newQuotation', component: QuotationComponent, canActivate: [MsalGuard]},
  { path: 'editQuotation/:quotationId', component: QuotationComponent, canActivate: [MsalGuard]},
  { path: 'parametersCommercial', component: ParametersCommercialComponent, canActivate: [MsalGuard]},
  { path: 'parametersProductsAndPrices', component: ParametersProductsAndPricesComponent, canActivate: [MsalGuard]},
  { path: 'parametersSml', component: ParametersSmlComponent, canActivate: [MsalGuard]},
  { path: 'createPlanning/:quotationId', component: CreatePlanningComponent, canActivate: [MsalGuard]},
  { path: 'editPlanning/:planningId', component: CreatePlanningComponent, canActivate: [MsalGuard]},
  { path: 'planning', component: PlanningGridComponent, canActivate: [MsalGuard]},
  { path: 'parametersSystems', component: ParametersSystemsComponent, canActivate: [MsalGuard]},

    //You need to keep this line in last
    { path: '**', component: NotFoundComponent}
  ];

  const isIframe = window !== window.parent && !window.opener;

  @NgModule({
    imports: [RouterModule.forRoot(routes, {
      // Don't perform initial navigation in iframes
      //initialNavigation: !isIframe ? 'enabled' : 'disabled'
      initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled' // Set to enabledBlocking to use Angular Universal

    })],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    exports: [RouterModule]
  })
  export class AppRoutingModule { }
