import { Injectable } from "@angular/core";
import { LocalStorageUtils } from './localstorage';

@Injectable({
  providedIn: 'root'
})
export class SetDefaultLanguageService {

  public LocalStorage = new LocalStorageUtils();

  constructor( )
  { }

  setDefaultLanguage() {
    let language: string | null;
    language = this.LocalStorage.getCurrentLanguage();

    if (language != 'pt-BR') {
     return language = 'es'
    }
    else{
      return language ='br'}

  }

}
