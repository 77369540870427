<div class="div-card-principal">
  <div class="row col-sm-12">
    <div class="col-sm-9">
      <h1 class="brand-title">{{'Gerenciar Orçamentos' | translate}}</h1>
    </div>
  </div>
  <div class="separator-breadcrumb border-top"></div>
  <div>
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['../home']" routerLinkActive="router-link-active">{{'Home'}}</a>
        </li>
        <li class="active breadcrumb-item" aria-current="page">{{'Gerenciar Orçamentos' | translate}}</li>
      </ol>
    </nav>
  </div>
  <app-not-found *ngIf="!canAccess" firstMessage="Apenas Representante Comercial tem acesso a esta função." secondMessage="Caso você precise de acesso, deverá solicitar via Service Now."></app-not-found>
  <div *ngIf="canAccess">
    <div>
      <div>
        <mat-card>
          <mat-card-content>
            <div class="search-header ">
              <div class="header-container">
                <div class="col-sm-6 form-group mb-8 padding">
                  <div class="div-search">
                    <input class="input-search" type="text" [(ngModel)]="searchTerm" (keyup.enter)="search()"
                    placeholder="{{'Digite o código ou a descrição do orçamento' | translate}}"
                    ngbTooltip="{{'Para buscar, digite um dado, conforme título da tabela abaixo.' | translate }}">
                    <volvo-system-icon class="icon-search" icon="search" size="24" (click)="search()"></volvo-system-icon>
                  </div>
                </div>
                <div class="col-sm-6 form-group mb-8 div-filter-icon">
                  <volvo-system-icon icon="filter" size="30" class="pointer" (click)="onClickFilter()"></volvo-system-icon>
                </div>
              </div>
            </div>
            <table mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)">
              <ng-container matColumnDef="dealerGroup">
                <th mat-header-cell *matHeaderCellDef> {{'Grupo Econômico' | translate}} </th>
                <td mat-cell *matCellDef="let quotation"> {{quotation.dealer.dealerGroup.description}} </td>
              </ng-container>
              <ng-container matColumnDef="dealer">
                <th mat-header-cell *matHeaderCellDef> {{'Concessionária' | translate}} </th>
                <td mat-cell *matCellDef="let quotation"> {{quotation.dealer.corporateName}} </td>
              </ng-container>
              <ng-container matColumnDef="code">
                <th mat-header-cell *matHeaderCellDef> {{'Código Orçamento' | translate}} </th>
                <td mat-cell class="click-cell" *matCellDef="let quotation" [routerLink]="['../editQuotation', quotation.id]"> {{quotation.code}} </td>
              </ng-container>
              <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef> {{'Descrição Orçamento' | translate}} </th>
                <td mat-cell *matCellDef="let quotation"> {{quotation.name}} </td>
              </ng-container>
              <ng-container matColumnDef="statusQuotation.name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by statusQuotation.name"> {{'Status Orçamento' | translate}} </th>
                <td mat-cell *matCellDef="let quotation" [ngClass]="getStatusColorClass(quotation.statusQuotation.id)"> {{quotation.statusQuotation.name | translate}} </td>
              </ng-container>
              <ng-container matColumnDef="createdAt">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by createdAt"> {{'Data Orçamento' | translate}} </th>
                <td mat-cell *matCellDef="let quotation"> {{quotation.createdAt | date:'dd/MM/yyyy'}} </td>
              </ng-container>
              <ng-container matColumnDef="planningCode">
                <th mat-header-cell *matHeaderCellDef> {{'Código Planejamento' | translate}} </th>
                <td mat-cell class="click-cell" *matCellDef="let quotation" [routerLink]="['../editPlanning', quotation.planning?.id]"> {{quotation.planning?.code}} </td>
              </ng-container>
              <ng-container matColumnDef="planning.status.name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by planning.status.name"> {{'Status Planejamento' | translate}} </th>
                <td mat-cell *matCellDef="let quotation"> {{quotation.planning?.status?.name | translate}} </td>
              </ng-container>
              <ng-container matColumnDef="itemsCount">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by itemsCount"> {{'Qte itens' | translate}} </th>
                <td mat-cell *matCellDef="let quotation"> {{quotation.itemsCount | translate}} </td>
              </ng-container>
              <ng-container matColumnDef="quotationId">
                <th mat-header-cell *matHeaderCellDef> </th>
                <td mat-cell *matCellDef="let quotation" class="td-delete"> <span class="span-delete" (click)="confirmDeleteQuotation(quotation.id)" *ngIf="quotation.canDelete" ><i><volvo-system-icon icon="trash" size="24"></volvo-system-icon></i></span> </td>
              </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row  class="queue-element-row" *matRowDef="let row; columns: displayedColumns;"></tr>
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="displayedColumns.length">{{'Orçamento não encontrado.' | translate}}</td>
              </tr>
            </table>
            <mat-paginator [length]="service.total | async" [pageSizeOptions]="[10,20,50,100]" (page)="onPageChange($event)"
            showFirstLastButtons
            aria-label="Select page of quotations">
            </mat-paginator>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
    <div class="row ">
      <div class="div-button">
        <volvo-button variant="primary"  size="large" [routerLink]="['/newQuotation']" >{{'Novo Orçamento' | translate}}</volvo-button>
      </div>
    </div>
  </div>
</div>
