import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { GridDataSource, PagedRequest } from 'src/app/services/grid-data-source.service';
import { DealerGroup } from '../../models/dealer-group';
import { ParametersService } from '../../services/parameters.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatTableDataSource } from '@angular/material/table';
import { DecimalPipe } from '@angular/common';
import { CommercialParameters } from '../../models/commercial-parameters';
import { ParametersQuotationComponent } from './parameters-quotation/parameters-quotation.component';
import { ParametersPlanningComponent } from './parameters-planning/parameters-planning.component';
import { GetTranslationService } from 'src/app/shared/utils/get-translation.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { Router } from '@angular/router';
import { CommercialParametersService } from 'src/app/shared/services/commercial-parameters.service';
import { LocalStorageUtils } from 'src/app/shared/utils/localstorage';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-parameters-commercial',
  templateUrl: './parameters-commercial.component.html',
  styleUrls: ['./parameters-commercial.component.css'],
  providers: [GridDataSource, DecimalPipe]
})
export class ParametersCommercialComponent implements OnInit{

  @ViewChild(ParametersQuotationComponent) parametersQuotationComponent: ParametersQuotationComponent | undefined;
  @ViewChild(ParametersPlanningComponent) parametersPlanningComponent: ParametersPlanningComponent | undefined;
  @Output() clickMenuItem = new EventEmitter<string>();

  dealerGroups: DealerGroup[] = [];
  dataSource = new MatTableDataSource<DealerGroup>(this.dealerGroups);
  commercialParameters!: CommercialParameters;
  edited: boolean = false;
  isSalesRepresentativeAdmin = false;

  public LocalStorage = new LocalStorageUtils();
  invalidFunctionalBox!: boolean;

  constructor(public service: GridDataSource<DealerGroup>,
    public dataService: ParametersService,
    private spinner: NgxSpinnerService,
    public getTranslationSvc: GetTranslationService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private router:Router,
    public commercialParametersService: CommercialParametersService){

    this.setIsSalesRepresentativeAdmin();
    var _dataService = this.dataService;
    this.service.configureDataSource((request: PagedRequest<DealerGroup>) => _dataService.listDayWeekDealerGroup());
    this.service.datasource.subscribe(
      response => {
       this.spinner.show();
       this.dealerGroups = response;
       this.dataSource = new MatTableDataSource<DealerGroup>(this.dealerGroups);
       this.spinner.hide();
      });

      commercialParametersService.getCommercialParameters().subscribe(response =>{
      this.spinner.show();
       this.commercialParameters = response.data;
       this.fillParameters();
       this.spinner.hide();
    });
  }

  ngOnInit(): void {
    this.clickMenuItem.emit('commercialParameters');
  }

  fillParameters(): void {
    if(!this.commercialParameters){
      return;
    }
    this.fillParametersQuotation();
    this.fillParametersPlanning();
  }

  fillParametersQuotation(): void{
    if(!this.parametersQuotationComponent){
      return;
    }

    this.parametersQuotationComponent.commercialParameters = this.commercialParameters;
    this.parametersQuotationComponent.invalidFunctionalBox = this.invalidFunctionalBox;
    this.parametersQuotationComponent.fillParameters();
  }

  fillParametersPlanning(): void{
    if(!this.parametersPlanningComponent){
      return;
    }

    this.parametersPlanningComponent.commercialParameters = this.commercialParameters;
    this.parametersPlanningComponent.fillParameters();
  }

  onTabChanged(){
    this.fillParameters();
  }

  save(){
    this.spinner.show();
    var _dataService = this.dataService;
    _dataService.putCommercialParameters(this.commercialParameters).subscribe((success) =>{
      this.saveDayWeekDealerGroup();
    },
    (error) =>{
      this.showErrorMessage(error);
    }
   );
  }

  saveDayWeekDealerGroup(){
    var _dataService = this.dataService;

    this.dealerGroups.forEach(dg => {
      dg.dayWeek = Number(dg.dayWeek);
    });

    _dataService.putDayWeekDealerGroup(this.dealerGroups).subscribe((success) =>{
      this.saveSuccess(success);
    },
    (error) =>{
      this.showErrorMessage(error);
    }
   );
  }

  saveSuccess(success: any){
    this.spinner.hide();
    if(!success.success)
      return;

    if(success.success == false)
      return;

    this.toastr.success(this.getTranslationSvc.getTranslation('Parâmetros salvos com sucesso!'), this.getTranslationSvc.getTranslation('Sucesso!'));
  }

  showErrorMessage(error: any){
    this.spinner.hide();

    if(error.error.message){
      this.toastr.error(error.error.message.toString(), this.getTranslationSvc.getTranslation('Erro'));
      return;
    }

    if(error.error.data){
      this.toastr.error(error.error.data.toString(), this.getTranslationSvc.getTranslation('Erro'));
      return;
    }

    if(!error.error.errors){
      this.toastr.error(this.getTranslationSvc.getTranslation('Um erro não identificado ocorreu'), this.getTranslationSvc.getTranslation('Erro'));
    }

    if(!error.error.errors.title){
      this.toastr.error(this.getTranslationSvc.getTranslation('Um erro não identificado ocorreu'), this.getTranslationSvc.getTranslation('Erro'));
    }

    this.toastr.error(error.error.errors.title.toString(), this.getTranslationSvc.getTranslation('Erro'));
  }

  confirm(
    title: string,
    message: string,
    btnOkText: string = this.getTranslationSvc.getTranslation("Sim"),
    btnCancelText: string = this.getTranslationSvc.getTranslation("Não"),
    dialogSize: 'sm' | 'lg' = 'lg'): Promise<boolean> {
    const modalRef = this.modalService.open(ConfirmationDialogComponent, { size: dialogSize });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.btnOkText = btnOkText;
    modalRef.componentInstance.btnCancelText = btnCancelText;

    return modalRef.result;
  }

  onSaveClick(){
    if(this.invalidFunctionalBox){
      this.toastr.error(this.getTranslationSvc.getTranslation('E-mail inválido!'), this.getTranslationSvc.getTranslation('Erro'));
      return;
    }

    this.confirm(this.getTranslationSvc.getTranslation("Salvar"), this.getTranslationSvc.getTranslation("Deseja salvar a alteração?"))
        .then((confirmed) => {
          if(confirmed)
            this.save();
        });
  }

  onEdit(){
    this.edited = true;
  }

  onBackClick(){
    if(this.edited){
      this.confirm(this.getTranslationSvc.getTranslation("Voltar"), this.getTranslationSvc.getTranslation("Foram realizadas alterações que não foram salvas. Para salvá-las é necessário clicar no botão Salvar. Deseja sair mesmo assim?"))
        .then((confirmed) => {
          if(confirmed)
            this.goBack();
            return;
        });
    } else {
      this.goBack();
    }
  }

  goBack(){
    this.router.navigate(['/home']);
  }

  setIsSalesRepresentativeAdmin(){
    this.isSalesRepresentativeAdmin = this.LocalStorage.searchGroup(environment.SALES_REPRESENTATIVE_ADMIN_GROUP_ID);
  }
  onChangeInvalidFunctionalBox(event: boolean){
    this.invalidFunctionalBox = event;
  }
}

