<div class="div-card-principal">
  <div class="row col-sm-12">
    <div class="col-sm-9">
      <h1 class="brand-title">{{'Gerenciar Planejamentos' | translate}}</h1>
    </div>
  </div>
  <div class="separator-breadcrumb border-top"></div>
  <div>
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['../home']" routerLinkActive="router-link-active">{{'Home'}}</a>
        </li>
        <li class="active breadcrumb-item" aria-current="page">{{'Gerenciar Planejamentos' | translate}}</li>
      </ol>
    </nav>
  </div>
  <app-not-found *ngIf="!canAccess" firstMessage="Apenas Representante Comercial tem acesso a esta função." secondMessage="Caso você precise de acesso, deverá solicitar via Service Now."></app-not-found>
  <div *ngIf="canAccess">
    <div>
      <div>
        <mat-card>
          <mat-card-content>
            <div class="search-header ">
              <div class="header-container">
                <div class="col-sm-6 form-group mb-8 padding">
                  <div class="div-search">
                    <input class="input-search" type="text" [(ngModel)]="searchTerm" (keyup.enter)="search()" (input)="inputSearch()"
                    placeholder="{{'Buscar por Grupo Econômico, Concessionária, Código Planejamento, Status, Data Planejamento, Orçamento Base ou Data Orçamento' | translate}}"
                    ngbTooltip="{{'Para buscar, digite um dado, conforme título da tabela abaixo.' | translate }}">
                    <volvo-system-icon class="icon-search" icon="search" size="24" (click)="search()"></volvo-system-icon>
                  </div>
                </div>
                <div class="col-sm-6 form-group mb-8 div-filter-icon">
                  <volvo-system-icon icon="filter" size="30" class="pointer" (click)="onClickFilter()"></volvo-system-icon>
                </div>
              </div>
            </div>
            <table mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)">
              <ng-container matColumnDef="Quotation.Dealer.DealerGroup.Code">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Quotation.Dealer.DealerGroup.Code"> {{'Grupo Econômico' | translate}} </th>
                <td mat-cell *matCellDef="let planning"> {{planning.quotation.dealer?.dealerGroup?.description}} </td>
              </ng-container>
              <ng-container matColumnDef="Quotation.Dealer.CorporateName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Quotation.Dealer.CorporateName"> {{'Concessionária' | translate}} </th>
                <td mat-cell *matCellDef="let planning"> {{planning.quotation.dealer?.corporateName}} </td>
              </ng-container>
              <ng-container matColumnDef="code">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by code"> {{'Código Planejamento' | translate}} </th>
                <td mat-cell class="click-cell" *matCellDef="let planning" [routerLink]="['../editPlanning', planning.id]">
                  {{planning.code}}
                  <span *ngIf="planning.hasInconsistencies" matTooltip="{{'Planejamento possui inconsistências' | translate}}">
                    <i class="bi bi-exclamation-diamond-fill red-alert exclamation-icon-size"></i>
                  </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="Status.Name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Status.Name"> {{'Status' | translate}} </th>
                <td mat-cell *matCellDef="let planning" [ngClass]="getStatusColorClass(planning.status?.id)"> {{planning.status?.name | translate}} </td>
              </ng-container>
              <ng-container matColumnDef="createdAt">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by createdAt"> {{'Data Planejamento' | translate}} </th>
                <td mat-cell *matCellDef="let planning"> {{planning.createdAt | date:'dd/MM/yyyy'}} </td>
              </ng-container>
              <ng-container matColumnDef="Quotation.Name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Quotation.Name"> {{'Orçamento Base' | translate}} </th>
                <td mat-cell *matCellDef="let planning"> {{planning.quotation.name}} </td>
              </ng-container>
              <ng-container matColumnDef="Quotation.createdAt">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Quotation.createdAt"> {{'Data Orçamento' | translate}} </th>
                <td mat-cell *matCellDef="let planning"> {{planning.quotation.createdAt | date:'dd/MM/yyyy'}} </td>
              </ng-container>
              <ng-container matColumnDef="itemsCount">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by itemsCount"> {{'Qte itens' | translate}} </th>
                <td mat-cell *matCellDef="let planning"> {{planning.itemsCount | translate}} </td>
              </ng-container>
              <ng-container matColumnDef="planningId">
                <th mat-header-cell *matHeaderCellDef> </th>
                <td mat-cell *matCellDef="let planning" class="td-delete"> <span class="span-delete" (click)="clickDeletePlanning(planning.id)" *ngIf="planning.canDelete" ><i><volvo-system-icon icon="trash" size="24"></volvo-system-icon></i></span> </td>
              </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row  class="queue-element-row" *matRowDef="let row; columns: displayedColumns;"></tr>
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="displayedColumns.length">{{'Planejamento não encontrado.' | translate}}</td>
              </tr>
            </table>
            <mat-paginator [length]="service.total | async" [pageSizeOptions]="[10,20,50,100]" (page)="onPageChange($event)"
            showFirstLastButtons
            aria-label="Select page of plannings">
            </mat-paginator>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
    <div class="row ">
      <div class="div-button">
        <volvo-button variant="primary"  size="large" (click)="openChooseQuotationModal()">{{'Novo Planejamento' | translate}}</volvo-button>
      </div>
    </div>
  </div>
</div>
