import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-quotation-dialog',
  templateUrl: './quotation-dialog.component.html',
  styleUrls: ['./quotation-dialog.component.css']
})
export class QuotationDialogComponent {
  @Input() goToQuotation!: Function;
  @Input() saveAndGoToQuotation!: Function;
  @Input() quotationId!: string;

  constructor(private activeModal: NgbActiveModal) { }

  public dismiss() {
    this.activeModal.dismiss();
  }

  public discard() {
    if (this.goToQuotation) {
      this.goToQuotation(this.quotationId);
      this.dismiss();
    }
  }

  public save() {
    if (this.saveAndGoToQuotation) {
      this.saveAndGoToQuotation(this.quotationId);
      this.dismiss();
    }
  }
}
