import { Component, Input } from '@angular/core';
import { WarningPart } from '../../models/warningPart';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'message-list-modal.component',
  templateUrl: './message-list-modal.component.html',
  styleUrls: ['./message-list-modal.component.css']
})
export class MessageListModalComponent {
  @Input() messageList!: string[];
  @Input() titleText!: string;
  @Input() messageText!: string;

  constructor(private activeModal: NgbActiveModal) { }

  public dismiss() {
    this.activeModal.dismiss();
  }
}
