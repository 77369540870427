
export class LocalStorageUtils {
  constructor() {

  }
  public getUserToken(): string | null {
    let key = <any>localStorage.getItem('TokenKey');

    if(key==null)
      return "";

    let token = <any>localStorage.getItem(key.toString());
    return JSON.parse(token).secret;
  }

  getAccessToken(): string  {
    let key = <any>localStorage.getItem('TokenAccess');

    if(key==null)
      return "";

    let token = <any>localStorage.getItem(key.toString());
    return JSON.parse(token).secret;
    }

  public getCurrentLanguage(): string | null {
    let language = <any>localStorage.getItem('language');
    if(language != null && language != '' && language != undefined)
    {
      switch(language) {
        case 'br': {
           return 'pt-BR'
        }
        case 'us': {
          return 'en-US'
       }
        case 'es':{
          return 'es-ES'
        }
        default: {
          return 'pt-BR'
        }
     }
    }else{
      return 'pt-BR';
    }
  }

  public getCurrentCountryCode(): string {
    let countryCode = <any>localStorage.getItem('countryCode');
    if(countryCode != null && countryCode != '' && countryCode != undefined)
    {
      return countryCode;
    }else{
      return 'BR';
    }
  }

  public getUserGroups(): string[] {
    let claimKey = <any>localStorage.getItem('TokenClaims');
    if (claimKey == null)
      return [];
    let claimTokenGrops = <any>localStorage.getItem(claimKey);
    return JSON.parse(claimTokenGrops).idTokenClaims.groups;
  }

  public searchGroup(groupId: string) : boolean{
    let groupIds = this.getUserGroups();
    let findResult = groupIds.find(x => x === groupId );
    if(findResult === undefined)
      return false;
    return true;
  }

  public getGraphAccessToken(): string | null {
    return <any>localStorage.getItem('GraphAccessToken');;
  }
}
