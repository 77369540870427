import { HttpHeaders } from "@angular/common/http";
import { throwError } from "rxjs";
import { environment } from "src/environments/environment";
import { AppComponent } from "../app.component";
import { LocalStorageUtils } from "../shared/utils/localstorage";
import { TimezoneUtils } from "../shared/utils/timezone";

export abstract class BaseService {

  protected UrlCustomerApi: string = ""
  public LocalStorage = new LocalStorageUtils();
  public timezone = new TimezoneUtils();
  roleId: string = '';


  protected GetHeaderJson() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Language-Code': `${this.LocalStorage.getCurrentLanguage()}`
      })
    };
  }
  protected imageHeader() {
    let ocpToken = environment.OcpToken;
    return {
      headers: new HttpHeaders({
        'Language-Code': `${this.LocalStorage.getCurrentLanguage()}`,
        'Ocp-Apim-Subscription-Key': ocpToken,
        'Authorization': `Bearer ${this.LocalStorage.getUserToken()}`,
        'roleId':  AppComponent.globalRole[0]
      })
    };
  }


  protected GetAuthTokenHeader() {
    let ocpToken = environment.OcpToken;
    return {
      headers: new HttpHeaders({
        'Ocp-Apim-Subscription-Key': ocpToken,
        'Authorization': `Bearer ${this.LocalStorage.getUserToken()}`,
        'Language-Code': `${this.LocalStorage.getCurrentLanguage()}`,
        'Timezone-Offset': `${this.timezone.getTimezoneOffset()}`,
        // 'roleId':  AppComponent.globalRole[0]
      })
    };
  }


  protected GetAuthTokenHeaderOnlyToken() {
    return {
      headers: new HttpHeaders({
        'Authorization': `Bearer ${this.LocalStorage.getUserToken()}`
      })
    };
  }

  protected GetAuthTokenHeaderJson() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Ocp-Apim-Subscription-Key': environment.OcpToken,
        'Authorization': `Bearer ${this.LocalStorage.getUserToken()}`,
        'Language-Code': `${this.LocalStorage.getCurrentLanguage()}`,
        'roleId':  AppComponent.globalRole[0]
      })
    };
  }

  protected GetAuthTokenForReport() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/octet-stream',
        'Ocp-Apim-Subscription-Key': environment.OcpToken,
        'Authorization': `Bearer ${this.LocalStorage.getUserToken()}`,
        'roleId':  AppComponent.globalRole[0]
      })
    };
  }

  protected extract(response: any) {
    return response || {};
  }

  protected serviceError(response: Response | any) {
    let customError: string[] = [];

    if (response.statusText === "Unknown Error") {
      customError.push("Ocorreu um erro desconhecido");
      response.error.errors = customError;
    }

    if(response.status === 401){
        localStorage.clear();
        document.location.href="/";
    }
    return throwError(response);
  }

  protected GetGraphAccessAuthTokenHeader() {
    let ocpToken = environment.OcpToken;
    return {
      headers: new HttpHeaders({
        'Ocp-Apim-Subscription-Key': ocpToken,
        'Authorization': `Bearer ${this.LocalStorage.getUserToken()}`,
        'Language-Code': `${this.LocalStorage.getCurrentLanguage()}`,
        'graph_access_token':  `${this.LocalStorage.getGraphAccessToken()}`
      })
    };
  }

}
