<div>
  <mat-card>
    <mat-card-content>
      <div class="search-header">
        <div class="header-container">
          <div class="col-sm-6 form-group mb-8 padding">
            <div class="div-search">
              <input class="input-search" type="text" [(ngModel)]="searchTerm" (keyup.enter)="search()"
              placeholder="{{'Digite o código ou a descrição da peça' | translate}}"
              ngbTooltip="{{'Para buscar, digite um dado, conforme título da tabela abaixo.' | translate }}">
              <volvo-system-icon class="icon-search" icon="search" size="24" (click)="search()"></volvo-system-icon>
            </div>
          </div>
        </div>
        <div class="div-export">
          <div class="div-export-icon" (click)="downloadReport()"><volvo-system-icon icon="export" size="24"></volvo-system-icon></div>
          <div class="div-export-span" (click)="downloadReport()"><span>{{'Gerar arquivo csv' | translate}}</span></div>
        </div>
      </div>

      <table mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)">
        <ng-container matColumnDef="part.partCode">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by id"> {{'Código' | translate}} </th>
          <td mat-cell *matCellDef="let partRestriction"> {{partRestriction.partNumber}} </td>
        </ng-container>
        <ng-container matColumnDef="part.description">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by description"> {{'Descrição' | translate}} </th>
          <td mat-cell *matCellDef="let partRestriction"> {{partRestriction.description}} </td>
        </ng-container>
        <ng-container matColumnDef="risk">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by risk"> {{'Risco' | translate}} </th>
          <td mat-cell *matCellDef="let partRestriction"> {{partRestriction.risk}} </td>
        </ng-container>
        <ng-container matColumnDef="crisisReason">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by crisis reason"> {{'Motivo da Crise' | translate}} </th>
          <td mat-cell *matCellDef="let partRestriction"> {{partRestriction.crisisReason}} </td>
        </ng-container>
        <ng-container matColumnDef="emergencyRoutine">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by emergency routine"> {{'Rotina de Emergência' | translate}} </th>
          <td mat-cell *matCellDef="let partRestriction"> {{partRestriction.emergencyRoutineDisplay}} </td>
        </ng-container>
       <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row  class="queue-element-row" *matRowDef="let row; columns: displayedColumns;"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="displayedColumns.length">{{'Peça não encontrada.' | translate}}</td>
        </tr>
      </table>
      <mat-paginator [length]="service.total | async" [pageSizeOptions]="[10,20,50,100]" (page)="onPageChange($event)"
      showFirstLastButtons
      aria-label="Select page of parts">
      </mat-paginator>
    </mat-card-content>
  </mat-card>
</div>
<app-part-restriction-import (refreshGrid)="refreshGrid()" *ngIf="isLogistic"></app-part-restriction-import>
<div class="div-last-update" *ngIf="showLastUpdate">
  <span>{{'Última atualização em ' | translate}}{{ updatedAt | date:'dd/MM/yyyy hh:mm'}}</span>
</div>
