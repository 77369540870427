<div class="div-import-list">
    <div class="div-import">
      <div class="div-import-icon">
        <span class="div-import-span" (click)="fileUpload.click()">
          <volvo-system-icon icon="export" size="24"></volvo-system-icon>
          &nbsp;{{fileName || "Importar lista de peças"  | translate}}
        </span>
      </div>
      <div>
        <volvo-button size="small" variant="primary" [disabled]="importDisabled" (click)="handleFileInput()">
          {{'Atualizar' | translate}}
        </volvo-button></div>
    </div>
  </div>
  <input type="file" class="file-input" accept=".xlsx"
         (change)="onFileSelected($event)" #fileUpload>

