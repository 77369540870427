import { Component, Input, OnInit } from '@angular/core';
import { PromotionNewsletterService } from '../../services/promotion-newsletter.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { GetTranslationService } from 'src/app/shared/utils/get-translation.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProfileType } from 'src/app/Core/Navigation/Component/profile/profileTypeModel';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-promotion-newsletter-create',
  templateUrl: './promotion-newsletter-create.component.html',
  styleUrls: ['./promotion-newsletter-create.component.css']
})
export class PromotionNewsletterCreateComponent implements OnInit  {

  form!: FormGroup;
  profile!: ProfileType;

  options = {
    enableHtml: true,
    closeButton: true,
    timeOut: 5000,
    positionClass: 'toast-top-right',
    tapToDismiss: false,
    extendedTimeOut: 5000
  };

  constructor(
    public dataService: PromotionNewsletterService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private getTranslationSvc: GetTranslationService,
    private modalService: NgbModal,
    private http: HttpClient){

      this.buildForm();
    }

  ngOnInit(): void {
    this.getProfile();

  }

  getProfile() {
    this.http.get<ProfileType>(environment.GRAPH_ENDPOINT)
      .subscribe(profile => {
        this.profile = profile;
        this.form.get('email')?.setValue(this.profile.mail);
      });
  }

  protected buildForm(): void {
    this.form = this.formBuilder.group({
      email: ['', Validators.required]
    });
  }

  save(){
    this.dataService.createNewsletter(this.form.value).subscribe(
      () =>{
        this.closeModal();
          const message = `<p><strong>${this.getTranslationSvc.getTranslation('E-mail cadastrado com sucesso!')}</strong></p>`+
          `<p>${this.getTranslationSvc.getTranslation('Você receberá o boletim de peças em promoção toda vez que uma ')}`+
          `${this.getTranslationSvc.getTranslation('promoção for lançada ou uma promoção vigente for atualizada.')}</p>`;
           this.toastr.success(this.getTranslationSvc.getTranslation(message),
              this.getTranslationSvc.getTranslation('Sucesso'),
              this.options)
      },
      (error) =>{
        this.closeModal();
        let erro = <any>error;
        if(error?.status == 400)
          this.toastr.error(erro.error.details[0].erros[0], this.getTranslationSvc.getTranslation('Erro'), { timeOut: 5000, extendedTimeOut: 5000 });
        else
          this.toastr.error(error.error.message, this.getTranslationSvc.getTranslation('Erro'), { timeOut: 5000, extendedTimeOut: 5000 })
      }
     );

  }

  delete(){
    this.dataService.deleteNewsletter(this.form.get('email')?.value).subscribe(
      (success) =>{
        this.toastr.success(success.message,
                            this.getTranslationSvc.getTranslation('Sucesso'),
                            { timeOut: 3000, extendedTimeOut: 3000 })
      },
      (error) =>{
        let erro = <any>error;
        if(error?.status == 400)
          this.toastr.error(erro.error.details[0].erros[0], this.getTranslationSvc.getTranslation('Erro'), { timeOut: 5000, extendedTimeOut: 5000 });
        else
          this.toastr.error(error.error.message, this.getTranslationSvc.getTranslation('Erro'), { timeOut: 5000, extendedTimeOut: 5000 })

      }
     );
  }

  closeModal() {
    this.form.get('email')?.reset();
    this.modalService.dismissAll();
  }

}
