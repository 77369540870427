import { DecimalPipe } from "@angular/common";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { debounceTime, delay, switchMap, tap } from "rxjs/operators";
import { SortColumn, SortDirection } from "../Core/Navigation/Component/grid-sortable/sortable.directive";

interface State {
  page: number;
  pageSize: number;
  searchTerm: string;
  sortColumn: SortColumn;
  sortDirection: SortDirection;
}
export interface Sort<T>{
  field: string,
  order: string
}

export interface PagedRequest<T>
{
  page: number,
  size: number,
  sort?: Sort<T>
}

export interface PagedResponse<T>
{

  success: boolean,
  message: string,
  statusCode: number,
  data:{
    total: number,
    page: number,
    limit: number,
    data:T[]
  }
}

export interface ResponseResult<T>
{

  success: boolean,
  message: string,
  statusCode: number,
  data: T
  
}

export type DataSource<T, TF> = (req: PagedRequest<T>, filter: TF) => Observable<PagedResponse<T>>

@Injectable({providedIn: 'root'})
export class GridDataSource<T> {
  private _pagedRequest!: DataSource<T, any>;
  private _myDataSOurce!: Observable<PagedResponse<T>>;
  private _loading$ = new BehaviorSubject<boolean>(true);
  private _search$ = new Subject<void>();
  private _data$ = new BehaviorSubject<T[]>([]);
  private _total$ = new BehaviorSubject<number>(0);
  public count: number = 0;
  private _sortCustomer!: Sort<T>;
  private _request: PagedRequest<T> = {
    page: 1,
    size: 10,
    sort: this._sortCustomer
  }

  private _state: State = {
    page: 1,
    pageSize: 10,
    searchTerm: '',
    sortColumn: '',
    sortDirection: ''
  };

  constructor(private pipe: DecimalPipe) {
    this._total$ = new BehaviorSubject<number>(0)
    this._search$.pipe(
      tap(() => this._loading$.next(true)),
      debounceTime(200),
      switchMap(() => this._search()),
      delay(200),
      tap(() => this._loading$.next(false))
    ).subscribe(result => {
      this._data$.next(result.data.data);
      this._total$.next(result.data.total);
    });
  }

  get datasource () {
    if(this._myDataSOurce == null)
      this._search$.next();
    return this._data$.asObservable();
  }

  get total() { return this._total$.asObservable(); }

  get loading$() { return this._loading$.asObservable(); }
  get page() { return this._state.page; }
  get pageSize() { return this._state.pageSize; }
  get searchTerm() { return this._state.searchTerm; }

  set page(page: number) { this._set({page}); }
  set pageSize(pageSize: number) { this._set({pageSize}); }
  set searchTerm(searchTerm: string) { this._set({searchTerm}); }
  set sortColumn(sortColumn: SortColumn) {
    if(this._sortCustomer === undefined || this._sortCustomer == null){
      this._sortCustomer = {
        field: "",
        order: "asc"
      }
    }
    this._sortCustomer.field = sortColumn.toString();
    this._set({sortColumn});
  }
  set sortDirection(sortDirection: SortDirection) {
    if(this._sortCustomer === undefined || this._sortCustomer == null){
      this._sortCustomer = {
        field: "",
        order: "asc"
      }
    }
    this._sortCustomer.order = sortDirection.toString();
    this._set({sortDirection});
  }

  private _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }

  private _search(): Observable<PagedResponse<T>> {
    if((this._sortCustomer || { order: ""}).order !== "")
      this._request.sort = this._sortCustomer;
    else
      this._request.sort = undefined;
    this._request.page = this._state.page;
    this._request.size = this._state.pageSize;
    this._myDataSOurce = this._pagedRequest(this._request, this._state.searchTerm);
    return this._myDataSOurce;
  }

  public configureDataSource(pagedRequest: DataSource<T, any>){
    this._pagedRequest = pagedRequest;
  }

  refresh() {
    this._myDataSOurce = this._pagedRequest(this._request, this.searchTerm);
    this._search$.next();
  }
}
