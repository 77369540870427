import { Pipe, PipeTransform } from '@angular/core';
import { QuotationPartPromotionDto } from '../models/quotationPartPromotion';
import { CurrencyPipe } from '@angular/common';

@Pipe({
  name: 'partPromotionData'
})

export class PartPromotionDataPipe implements PipeTransform {

  transform(value: QuotationPartPromotionDto | undefined, currencyCode: string, digitsInfo?: string): string {
    let partPromotionData = '';

    if(!value){
      return partPromotionData;
    }

    if(value.promotionName){
      partPromotionData = value.promotionName;
    }

    const pipe = new CurrencyPipe('pt-BR');

    if(value.discountPrice){
      const formattedPrice = pipe.transform(value.discountPrice, currencyCode, '', digitsInfo);
      partPromotionData = partPromotionData + ' R$ ' + formattedPrice!.replace(/[^0-9.,]/g, '');
    }

    if(value.discountPrice && value.discountPercent){
      partPromotionData = partPromotionData + ' -';
    }

    if(value.discountPercent){
      const formattedPercent = pipe.transform(value.discountPercent, currencyCode, '', digitsInfo);
      partPromotionData = partPromotionData + ' %' + formattedPercent!.replace(/[^0-9.,]/g, '');
    }

    return partPromotionData;
  }

}
