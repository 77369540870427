import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { GridDataSource, PagedRequest, PagedResponse } from 'src/app/services/grid-data-source.service';
import { Sort} from '@angular/material/sort';
import { DecimalPipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { QuotationService } from '../../services/quotation.service';
import { MatPaginator } from '@angular/material/paginator';
import { GetTranslationService } from 'src/app/shared/utils/get-translation.service';
import { Quotation } from 'src/app/shared/models/quotation';
import { EnumQuotationStatus } from 'src/app/shared/models/enumQuotationStatus';

@Component({
  selector: 'app-copy-quotation',
  templateUrl: './copy-quotation.component.html',
  styleUrls: ['./copy-quotation.component.css'],
  providers: [GridDataSource, DecimalPipe]
})
export class CopyQuotationComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator!: MatPaginator;
  quotations: Quotation[] = [];
  dataSource = new MatTableDataSource<Quotation>(this.quotations);
  displayedColumns: string[] = ['checked', 'dealer.corporateName', 'code', 'name', 'statusQuotation.name', 'createdAt'];
  filterObject: any = {};
  maskDate: string = 'dd/MM/YYYY';
  selectedQuotation!: string | null;
  disableCopy: boolean = true;
  searchTerm!: string;

  constructor(
    public service: GridDataSource<Quotation>,
    private spinner: NgxSpinnerService,
    public activeModal: NgbActiveModal,
    public dataService: QuotationService,
    private getTranslationSvc: GetTranslationService){

      var _dataService = this.dataService;
      this.filterObject.limit = 10;
      this.filterObject.page = 1;
      this.filterObject.fieldNameOrderBy = 'createdAt';
      this.filterObject.isOrderByDesc = true;
      this.service.configureDataSource((request: PagedRequest<Quotation>) => _dataService.getAllQuotation(this.filterObject));
      this.service.datasource.subscribe(
      response => {
       this.spinner.show();
       this.quotations = response;
       this.dataSource = new MatTableDataSource<Quotation>(this.quotations);
       setTimeout(() => {
         this.spinner.hide();
       }, 1000);
      });

  }

  ngOnInit(): void {
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction == 'asc') {
      this.filterObject.fieldNameOrderBy = sortState.active;
      this.filterObject.isOrderByDesc = false;
    } else if (sortState.direction == 'desc') {
      this.filterObject.fieldNameOrderBy = sortState.active;
      this.filterObject.isOrderByDesc = true;
    } else {
      this.filterObject.fieldNameOrderBy = 'id';
      this.filterObject.isOrderByDesc = false;
    }
    this.refreshGrid();
  }

  copy(){
    this.activeModal.close(this.selectedQuotation);
  }

  onQuotationChange(quotationId: string): void {
    this.disableCopy = true;
    if (quotationId === this.selectedQuotation) {
      this.selectedQuotation = null;
    } else {
      this.selectedQuotation = quotationId;
      this.disableCopy = false;
    }
  }

  cancel(){
    this.activeModal.close();
  }

  search(){
    delete this.filterObject.searchKey;
    if(this.searchTerm)
      this.filterObject.searchKey = this.searchTerm;

    this.refreshGrid();
  }

  onPageChange(event: any) {
    this.filterObject.page = event.pageIndex+1
    this.refreshGrid();
  }

  refreshGrid(){
    this.filterObject.limit = this.paginator.pageSize;
    this.service.refresh();
  }

  ngAfterViewChecked(){
    this.paginator._intl.itemsPerPageLabel = this.getTranslationSvc.getTranslation("Itens por página:");
    this.paginator._intl.firstPageLabel = this.getTranslationSvc.getTranslation("Primeira página");
    this.paginator._intl.lastPageLabel = this.getTranslationSvc.getTranslation("Última página");
    this.paginator._intl.nextPageLabel = this.getTranslationSvc.getTranslation("Próxima página");
    this.paginator._intl.previousPageLabel = this.getTranslationSvc.getTranslation("Página anterior");
  }

  getStatusColorClass(statusId: string): string {
    switch (statusId.toLowerCase()) {
      case EnumQuotationStatus.Negociation.toLowerCase():
        return 'text-color-negociation';
      case EnumQuotationStatus.Finished.toLowerCase():
        return 'text-color-finished';
      case EnumQuotationStatus.Planning.toLowerCase():
        return 'text-color-planning';
      default:
        return '';
    }
  }
}
