import { Component, ViewChild } from '@angular/core';
import { PlanningService } from '../../services/planning.service';
import { GridDataSource, PagedRequest } from 'src/app/services/grid-data-source.service';
import { Quotation } from 'src/app/shared/models/quotation';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { Sort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DecimalPipe } from '@angular/common';
import { EnumQuotationStatus } from 'src/app/shared/models/enumQuotationStatus';

@Component({
  selector: 'app-choose-quotation',
  templateUrl: './choose-quotation.component.html',
  styleUrls: ['./choose-quotation.component.css'],
  providers: [GridDataSource, DecimalPipe]
})
export class ChooseQuotationComponent {
  @ViewChild(MatPaginator, {static: true}) paginator!: MatPaginator;

  searchTerm!: string;
  filterObject: any = {};
  quotations: Quotation[] = [];
  dataSource = new MatTableDataSource<Quotation>(this.quotations);
  selectedQuotation!: string | null;
  disableConfirm: boolean = true;
  maskDate: string = 'dd/MM/YYYY';
  displayedColumns: string[] = ['checked', 'dealer.corporateName', 'code', 'name', 'createdAt'];

  constructor(
    public service: GridDataSource<Quotation>,
    public dataService: PlanningService,
    private spinner: NgxSpinnerService,
    public activeModal: NgbActiveModal){

      var _dataService = this.dataService;
      this.filterObject.limit = 10;
      this.filterObject.page = 1;
      this.filterObject.fieldNameOrderBy = 'createdAt';
      this.filterObject.isOrderByDesc = true;
      this.filterObject.statusQuotationId = EnumQuotationStatus.Negociation.toLowerCase();
      this.service.configureDataSource((request: PagedRequest<Quotation>) => _dataService.getAllQuotation(this.filterObject));
      this.service.datasource.subscribe(
      response => {
       this.spinner.show();
       this.quotations = response;
       this.dataSource = new MatTableDataSource<Quotation>(this.quotations);
       setTimeout(() => {
         this.spinner.hide();
       }, 1000);
      });

  }

  search(){
    delete this.filterObject.searchKey;
    if(this.searchTerm)
      this.filterObject.searchKey = this.searchTerm;

    this.refreshGrid();
  }

  refreshGrid(){
    this.filterObject.limit = this.paginator.pageSize;
    this.service.refresh();
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction == 'asc') {
      this.filterObject.fieldNameOrderBy = sortState.active;
      this.filterObject.isOrderByDesc = false;
    } else if (sortState.direction == 'desc') {
      this.filterObject.fieldNameOrderBy = sortState.active;
      this.filterObject.isOrderByDesc = true;
    } else {
      this.filterObject.fieldNameOrderBy = 'id';
      this.filterObject.isOrderByDesc = false;
    }
    this.refreshGrid();
  }

  onQuotationChange(quotationId: string): void {
    this.disableConfirm = true;
    if (quotationId === this.selectedQuotation) {
      this.selectedQuotation = null;
    } else {
      this.selectedQuotation = quotationId;
      this.disableConfirm = false;
    }
  }

  onPageChange(event: any) {
    this.filterObject.page = event.pageIndex+1
    this.refreshGrid();
  }

  cancel(){
    this.activeModal.close();
  }

  confirm(){
    this.activeModal.close(this.selectedQuotation);
  }
}
