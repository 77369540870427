import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { GridDataSource, PagedRequest } from 'src/app/services/grid-data-source.service';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { SetDefaultLanguageService } from 'src/app/shared/utils/setDefaultLanguage';
import { QuotationService } from '../../services/quotation.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe, DecimalPipe } from '@angular/common';
import { stringToBool } from 'src/app/shared/utils/booleansUtil';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { QuotationGridFilterComponent } from '../quotation-grid-filter/quotation-grid-filter.component';
import { EnumQuotationStatus } from 'src/app/shared/models/enumQuotationStatus';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { GetTranslationService } from 'src/app/shared/utils/get-translation.service';
import { ToastrService } from 'ngx-toastr';
import { Quotation } from 'src/app/shared/models/quotation';
import { Dealer } from 'src/app/shared/models/dealer';
import { StatusQuotation } from 'src/app/shared/models/statusQuotation';
import { DealerGroup } from 'src/app/shared/models/dealerGroup';
import { EnumPlanningStatus } from 'src/app/shared/models/enumPlanningStatus';
import { environment } from 'src/environments/environment';
import { LocalStorageUtils } from 'src/app/shared/utils/localstorage';
import { DealerService } from 'src/app/shared/services/dealer.service';
import { DealerGroupService } from 'src/app/shared/services/dealer-group.service';

@Component({
  selector: 'app-quotation-grid',
  templateUrl: './quotation-grid.component.html',
  styleUrls: ['./quotation-grid.component.css'],
  providers: [GridDataSource, DecimalPipe]
})
export class QuotationGridComponent implements OnInit{
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @Output() clickMenuItem = new EventEmitter<string>();

  filterObject: any = {};
  displayedColumns: string[] = ['dealerGroup', 'dealer', 'code', 'description', 'statusQuotation.name', 'createdAt', 'planningCode', 'planning.status.name', 'itemsCount', 'quotationId'];
  searchTerm!: string;
  quotations: Quotation[] = [];
  dataSource = new MatTableDataSource<Quotation>(this.quotations);
  dealerGroupFilters!: DealerGroup[];
  dealerList!: Dealer[];
  statusFilters!: StatusQuotation[];
  datePipe: DatePipe = new DatePipe('en-US');
  public LocalStorage = new LocalStorageUtils();
  canAccess = false;

  constructor(
    public service: GridDataSource<Quotation>,
    private translateService: TranslateService,
    private setLang: SetDefaultLanguageService,
    public dataService: QuotationService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    public getTranslationSvc: GetTranslationService,
    private toastr: ToastrService,
    public dealerService: DealerService,
    public dealerGroupService: DealerGroupService
  ){
    this.translateService.setDefaultLang(this.setLang.setDefaultLanguage());
    this.translateService.use(localStorage.getItem('language') || this.setLang.setDefaultLanguage());

    this.setCanAccess();

    this.fillFilters();

    var _dataService = this.dataService;
    this.filterObject.limit = 10;
    this.filterObject.page = 1;
    this.filterObject.fieldNameOrderBy = 'createdAt';
    this.filterObject.isOrderByDesc = true;
    this.service.configureDataSource((request: PagedRequest<Quotation>) => _dataService.getAllQuotation(this.filterObject));
    this.service.datasource.subscribe(
      response => {
       this.spinner.show();
       this.quotations = response;
       this.dataSource = new MatTableDataSource<Quotation>(this.quotations);
       this.setCanDelete();
       this.spinner.hide();
      });
  }

  ngOnInit(): void {
    this.clickMenuItem.emit('quotation');
  }

  onPageChange(event: any) {
    this.filterObject.page = event.pageIndex+1
    this.filterObject.limit = event.pageSize;
    this.refreshGrid();
  }

  refreshGrid(){
    this.setCanDelete();
    this.service.refresh();
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction == 'asc') {
      this.filterObject.fieldNameOrderBy = sortState.active;
      this.filterObject.isOrderByDesc = false;
    } else if (sortState.direction == 'desc') {
      this.filterObject.fieldNameOrderBy = sortState.active;
      this.filterObject.isOrderByDesc = true;
    } else {
      this.filterObject.fieldNameOrderBy = 'createdAt';
      this.filterObject.isOrderByDesc = true;
    }
    this.refreshGrid();
  }

  search() {
    delete this.filterObject.searchKey;
    if(this.searchTerm)
      this.filterObject.searchKey = this.searchTerm;
    this.refreshGrid();
  }

  onClickFilter(){
    const modalRef = this.modalService.open(QuotationGridFilterComponent, { windowClass: 'modal-right' });
    modalRef.componentInstance.dealerGroupFilters = this.dealerGroupFilters;
    modalRef.componentInstance.dealerList = this.dealerList;
    modalRef.componentInstance.statusFilters = this.statusFilters;
    modalRef.componentInstance.filterObject = this.filterObject;
    modalRef.componentInstance.setFilters();
    modalRef.result.then((result) => {
      this.setFilters(result);
    }).catch((error) => {
      console.log(error);
    });
  }

  fillFilters(){
    this.dealerGroupService.listDealerGroupsByUser().subscribe(response => {
      this.dealerGroupFilters = response.data.data;
    });

    this.dealerService.listDealersByUser().subscribe(response => {
      this.dealerList = response.data.data;
    });

    this.statusFilters = this.listStatusQuotation();
  }

  listStatusQuotation(): StatusQuotation[]{
    let response: StatusQuotation[] = [];
    response.push({id: 'CA9628F4-8380-49B0-942A-58A33A839659', name: this.translateService.instant("In planning")});
    response.push({id: '7913F29A-D201-45DD-8FA3-5FDE06FF99A1', name: this.translateService.instant("Finished")});
    response.push({id: '81747041-8446-43D1-BD41-99ED9F6A9C03', name: this.translateService.instant("In negociation")});
    return response;
  }

  setFilters(filter: any){
    if(filter.initialDate){
      this.filterObject.initialDate = this.datePipe.transform(this.filterObject.initialDate, 'yyyy-MM-dd');
    }

    if(filter.finalDate){
      this.filterObject.finalDate = this.datePipe.transform(this.filterObject.finalDate, 'yyyy-MM-dd');
    }

    this.search();
  }

  confirmDeleteQuotation(id: string){
    this.confirm(this.getTranslationSvc.getTranslation("Excluir"), this.getTranslationSvc.getTranslation("Deseja realmente excluir o orçamento? Obs: caso este orçamento já tenha um planejamento associado, este também será excluído"))
        .then((confirmed) => {
          if(confirmed)
            this.deleteQuotation(id);
        });
  }

  deleteQuotation(id: string){
    this.spinner.show();
    this.dataService.deleteQuotation(id).subscribe(
      () =>{
        this.spinner.hide();
        this.toastr.success(this.getTranslationSvc.getTranslation("Orçamento excluído com sucesso!"))
        this.refreshGrid();
      },
      (error) =>{
        this.spinner.hide();
        let erro = <any>error;
        if(error?.status == 400)
          this.toastr.error(erro.error.details[0].erros[0], this.getTranslationSvc.getTranslation('Erro'), { timeOut: 5000, extendedTimeOut: 5000 });
        else
          this.toastr.error(error.error.message, this.getTranslationSvc.getTranslation('Erro'), { timeOut: 5000, extendedTimeOut: 5000 })

         return;
      }
     );
  }

  confirm(
    title: string,
    message: string,
    btnOkText: string = this.getTranslationSvc.getTranslation("Sim"),
    btnCancelText: string = this.getTranslationSvc.getTranslation("Não"),
    dialogSize: 'sm' | 'lg' = 'lg'): Promise<boolean> {
    const modalRef = this.modalService.open(ConfirmationDialogComponent, { size: dialogSize });
    modalRef.componentInstance.title = this.getTranslationSvc.getTranslation(title);
    modalRef.componentInstance.message = this.getTranslationSvc.getTranslation(message);
    modalRef.componentInstance.btnOkText = btnOkText;
    modalRef.componentInstance.btnCancelText = btnCancelText;

    return modalRef.result;
  }

  setCanDelete(){
    this.quotations.forEach(quotation => {
      quotation.canDelete = false;
      if(quotation.statusQuotation.id.toLowerCase() == EnumQuotationStatus.Negociation.toLowerCase() &&
        (!quotation.planning))
        quotation.canDelete = true;

      if(!quotation.planning)
        return;

      if(quotation.planning.status.id.toLowerCase() == EnumPlanningStatus.Registered.toLowerCase() &&
        quotation.statusQuotation.id.toLowerCase() == EnumQuotationStatus.Planning.toLowerCase()){
        quotation.canDelete = true;
      }
    });
  }

  getStatusColorClass(statusId: string): string {
    switch (statusId.toLowerCase()) {
      case EnumQuotationStatus.Negociation.toLowerCase():
        return 'text-color-negociation';
      case EnumQuotationStatus.Finished.toLowerCase():
        return 'text-color-finished';
      case EnumQuotationStatus.Planning.toLowerCase():
        return 'text-color-planning';
      default:
        return '';
    }
  }

  setCanAccess(){
    var isSalesRepresentative = this.LocalStorage.searchGroup(environment.SALES_REPRESENTATIVE_GROUP_ID);
    var isExternal = this.LocalStorage.searchGroup(environment.EXTERNAL_USERS_GROUP_ID);
    var isProduction = stringToBool(environment.IS_PRODUCTION);

    this.canAccess = isSalesRepresentative || (isExternal && !isProduction);
  }

  ngAfterViewChecked(): void {
    this.setPaginatorLabels();
  }

  setPaginatorLabels() {
    if (!this.paginator) {
      return;
    }
    this.paginator._intl.itemsPerPageLabel = this.getTranslationSvc.getTranslation("Itens por página:");
    this.paginator._intl.firstPageLabel = this.getTranslationSvc.getTranslation("Primeira página");
    this.paginator._intl.lastPageLabel = this.getTranslationSvc.getTranslation("Última página");
    this.paginator._intl.nextPageLabel = this.getTranslationSvc.getTranslation("Próxima página");
    this.paginator._intl.previousPageLabel = this.getTranslationSvc.getTranslation("Página anterior");
  }
}
