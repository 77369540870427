import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirmation-delete-reason-dialog',
  templateUrl: './confirmation-delete-reason-dialog.component.html',
  styleUrls: ['./confirmation-delete-reason-dialog.component.css']
})
export class ConfirmationDeleteReasonDialogComponent {
  inputValue = '';

  constructor(public activeModal: NgbActiveModal) {}
}
