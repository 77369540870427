import { Component, EventEmitter, Injector, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { Promotion } from '../../models/promotion';
import { PromotionType } from '../../models/promotionType';
import { TranslateService } from '@ngx-translate/core';
import { GetTranslationService } from 'src/app/shared/utils/get-translation.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PromotionService } from '../../services/promotion.service';
import { SetDefaultLanguageService } from 'src/app/shared/utils/setDefaultLanguage';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IDropdownSettings, MultiSelectComponent } from 'ng-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';
import { DatePickerComponent } from 'ng2-date-picker';
import { DatePipe } from '@angular/common';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';



@Component({
  selector: 'app-promotion-create',
  templateUrl: './promotion-create.component.html',
  styleUrls: ['./promotion-create.component.css']
})

export class PromotionCreateComponent implements OnInit {

  @ViewChild(MatPaginator, {static: true}) paginator!: MatPaginator;
  @Output() refreshGrid = new EventEmitter<void>();
  @ViewChild('promotionTypedropdown') dropdown!: MultiSelectComponent;
  @ViewChild('datePicker') public datePicker!: DatePickerComponent;

  form!: FormGroup;
  promotion!: Promotion;
  promotionTypes!: PromotionType[];
  selectedFile: File = new File([], '');
  importDisabled: boolean = true;
  fileName = '';
  promotionTypeDropdownSettings!: IDropdownSettings;
  locale: string = 'pt-BR';
  minDate!: Date;
  maxDate!: Date;
  datePipe: DatePipe = new DatePipe('en-US');
  promotionId!: number;
  filterObject: any = {};
  erroMsg: boolean= false;


  constructor(
     private translateService: TranslateService,
     public getTranslationSvc: GetTranslationService,
     public activeModal: NgbActiveModal,
     public dataService: PromotionService,
     private setLang: SetDefaultLanguageService,
     private formBuilder: FormBuilder,
     public toastrService: ToastrService,
     private toastr: ToastrService,
     private spinner: NgxSpinnerService,
     private router: Router){

      this.translateService.setDefaultLang(this.setLang.setDefaultLanguage());
      this.translateService.use(localStorage.getItem('language') || this.setLang.setDefaultLanguage());
      this.buildForm();

      this.form.controls['initialDate'].valueChanges.subscribe(startDate => {
        if (startDate) {
          this.minDate = new Date(startDate)
          this.minDate.setDate(this.minDate.getDate() + 1);
        }
      });

      this.form.controls['finalDate'].valueChanges.subscribe(finalDate => {
        if (finalDate) {
          this.maxDate = new Date(finalDate);
          this.maxDate.setDate(this.maxDate.getDate() + 1);
        }
      });

      this.form.valueChanges.subscribe(change =>{

        if ((this.fileName) && (this.form.valid))
          this.importDisabled = false;
      });
  }

  protected buildForm(): void {
    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      promotionTypeId:['', Validators.required],
      initialDate:['', Validators.required],
      finalDate:['', Validators.required],
      createdBy: [''],
    }, { validators: this.validateDates });
  }

  ngOnInit(): void {
    this.fillPromotionTypes();
    this.initializeSettings();
  }

  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0];
    this.importDisabled = true;

    if (this.selectedFile) {
      this.erroMsg = !this.form.valid;
      this.fileName = this.selectedFile.name;
      if(this.form.valid)
        this.importDisabled = false;
    }

    event.target.value = null;
  }

  handleFileInput() {
    if(this.selectedFile)
      this.save();
  }

  save() {
    this.spinner.show();
    const formData = new FormData();
    formData.append('file', this.selectedFile, this.selectedFile.name);

    this.dataService.createPromotion(this.form.value, formData).subscribe({
      next: (success: any) => this.processSuccess(success),
      error: (fail: any) => this.processFail(fail, this.selectedFile.type),
    });
  }

  processSuccess(response: any){
    this.spinner.hide();
    let idAndFileName = this.parseHeaders(response.headers);
    let newPromotionId = this.getPromotionId(idAndFileName);
    let fileName = this.getFileName(idAndFileName, newPromotionId);

    if(fileName.toLowerCase().includes('failed')){
      this.dataService.downloadFile(response.body, 'text/csv', fileName);
      this.toastr.error(this.getTranslationSvc.getTranslation('Ocorreram problemas na leitura do arquivo!'), this.getTranslationSvc.getTranslation('Erro!'));
    }

    if(fileName.toLowerCase().includes('notfound')){
      this.dataService.downloadFile(response.body, 'text/csv', fileName);
      this.toastr.warning(this.getTranslationSvc.getTranslation('Algumas peças não foram encontradas na base, porém a promoção foi criada com sucesso!'), this.getTranslationSvc.getTranslation('Aviso!'));
      this.router.navigate([`/partpromotion/${newPromotionId}`]);
    }

    if(fileName.toLowerCase().includes('no-content')){
      this.toastr.success(this.getTranslationSvc.getTranslation('Importação realizada com sucesso!'), this.getTranslationSvc.getTranslation('Sucesso!'));
      this.router.navigate([`/partpromotion/${newPromotionId}`]);
    }

    this.importCompleted();
  }

  processFail(response: any, fileType: string) {
    this.spinner.hide();

    const arrayBuffer = response.error;
    const textDecoder = new TextDecoder();
    const decodedString: string = textDecoder.decode(arrayBuffer);
    const error: any = JSON.parse(decodedString);

    if (fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'){
      this.toastr.error(this.getTranslationSvc.getTranslation(error.data.toString()),this.getTranslationSvc.getTranslation('Erro'));
    }
    else{
      this.toastr.error(this.getTranslationSvc.getTranslation("O arquivo de importação deve ser do tipo excel"),this.getTranslationSvc.getTranslation('Erro'));
    }
  }
  parseHeaders(responseHeader: any) {
		let contentDisposition = responseHeader.get('content-disposition');
		return contentDisposition.split(';')[1].split('=')[1];
	}

  importCompleted() {
    this.fileName = '';
    this.importDisabled = true;
    this.refreshGrid.emit();
  }

  addEventInitialDate(type: string, event: MatDatepickerInputEvent<Date>) {
    const formattedDate = this.datePipe.transform(event.value, 'yyyy-MM-dd');
    this.form.get('initialDate')?.setValue(formattedDate);
    this.revalidateForm();
  }


  addEventFinalDate(type: string, event: MatDatepickerInputEvent<Date>) {
    const formattedDate = this.datePipe.transform(event.value, 'yyyy-MM-dd');
    this.form.get('finalDate')?.setValue(formattedDate);
    this.revalidateForm();
  }

  initializeSettings() {
    this.promotionTypeDropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      selectAllText: this.translateService.instant('Selecionar tudo'),
      unSelectAllText: this.translateService.instant('Limpar'),
      noDataAvailablePlaceholderText: this.translateService.instant('Nenhum dado encontrado'),
      itemsShowLimit: 2,
      allowSearchFilter: false
    };
  }

  fillPromotionTypes() {
    this.dataService.getAllPromotionType().subscribe((res) => {
      this.promotionTypes = res.data.data;
    });
  }

  onpromotionTypeSelect(item: any){
    this.form.get('promotionTypeId')?.setValue(item['id']);
    this.dropdown.closeDropdown();
  };

  validateDates(formGroup: FormGroup) {
    const dataInicio = formGroup.get('initialDate')?.value;
    const dataFim = formGroup.get('finalDate')?.value;

    if (dataInicio && dataFim) {
      return dataInicio <= dataFim ? null : { invalidDates: true };
    }

    return null;
  }

  revalidateForm() {
    if(this.selectedFile?.name === '')
      return;

    this.erroMsg = !this.form.valid;
    this.importDisabled = !this.form.valid;
  }

  getFileName(idAndFileName: string, newPromotionId: string | null) {
    if(!newPromotionId)
      return idAndFileName;

    return idAndFileName.replace(newPromotionId,"").replace("_","");
	}

  getPromotionId(idAndFileName: string) {
    if(idAndFileName.includes("_"))
		  return idAndFileName.split("_")[0].replace("PartsPromotion-","");

    return null;
	}
}
