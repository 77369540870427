<div class="div-card-principal">
  <h1 class="brand-title">{{'Inconsistências' | translate}}</h1>
  <div class="separator-breadcrumb border-top"></div>
    <div>
      <div>
        <mat-card>
          <mat-card-content>
            <table mat-table [dataSource]="dataSource">
              <ng-container matColumnDef="partCode">
                <th mat-header-cell *matHeaderCellDef> {{'Cód da peça' | translate}} </th>
                <td mat-cell *matCellDef="let inconsistency"> {{inconsistency.partCode}} </td>
              </ng-container>
              <ng-container matColumnDef="reason">
                <th mat-header-cell *matHeaderCellDef> {{'Motivo da recusa' | translate}} </th>
                <td mat-cell *matCellDef="let inconsistency"> {{inconsistency.reason}} </td>
              </ng-container>
              <ng-container matColumnDef="releaseDate">
                <th mat-header-cell *matHeaderCellDef> {{'Dt. liberação' | translate}} </th>
                <td mat-cell *matCellDef="let inconsistency"> {{inconsistency.releaseDate | date:'dd/MM/yyyy'}} </td>
              </ng-container>
              <ng-container matColumnDef="checked">
                <th mat-header-cell *matHeaderCellDef>{{'Manter alerta' | translate}}</th>
                <td mat-cell *matCellDef="let inconsistency">
                  <input
                    type="checkbox"
                    [ngModel]="inconsistency.warningActive"
                    (ngModelChange)="warningActiveChange(inconsistency)">
                </td>
              </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row  class="queue-element-row" *matRowDef="let row; columns: displayedColumns;"></tr>
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="displayedColumns.length">{{'Inconsistência não encontrada' | translate}}</td>
              </tr>
            </table>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="button-panel">
        <div class="div-button">
          <volvo-button variant="primary" class="button-icon" (click)="close()">{{'Fechar' | translate}}</volvo-button>
        </div>
      </div>
    </div>
</div>
