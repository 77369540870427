import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'numberWithoutRounding'
})
export class NumberWithoutRoundingPipe implements PipeTransform {
    transform(value: number | null | undefined, decimalPlaces: number = 0): string {
      const minusString = '-';

      if (value === null || value === undefined) {
        value = 0;
      }

      let stringValue = value.toString();
      let negativeNumber = false;

      if(stringValue.indexOf('-') >= 0){
        negativeNumber = true;
      }

      stringValue = stringValue.replace('-', '');

      if(stringValue.indexOf('.') === -1){
        stringValue = stringValue.concat('.0');
      }

      const dotIndex = stringValue.indexOf('.')
      const totalLength = dotIndex + decimalPlaces + 1;
      let returnString = stringValue.substring(0, totalLength);

      while (returnString.length < totalLength) {
        returnString = returnString.concat('0');
      }

      if(negativeNumber){
        returnString = minusString.concat(returnString);
      }

      return returnString;
    }
}
