import { DecimalPipe } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { GridDataSource } from 'src/app/services/grid-data-source.service';
import { LocalStorageUtils } from 'src/app/shared/utils/localstorage';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-promotion',
  templateUrl: './promotion.component.html',
  styleUrls: ['./promotion.component.css'],
  providers: [GridDataSource, DecimalPipe]
})
export class PromotionComponent implements OnInit {
  @Output() clickMenuItem = new EventEmitter<string>();

  isPricingManager = false;
  public LocalStorage = new LocalStorageUtils();

  constructor() {
    this.setIsPricingManager();
  }

  ngOnInit(): void {
    this.clickMenuItem.emit('promotion');
  }

  setIsPricingManager(){
    this.isPricingManager = this.LocalStorage.searchGroup(environment.PRINCING_MANAGER_GROUP_ID);
  }

}
