import { NgModule,CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { NavigationModule } from './Core/Navigation/navigation.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from '@angular/common/http';
import { MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalInterceptor, MsalInterceptorConfiguration, MsalModule, MsalRedirectComponent, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG } from '@azure/msal-angular';
import { BrowserCacheLocation, InteractionType, IPublicClientApplication, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';
import { ToastrModule } from 'ngx-toastr';
import localePt from '@angular/common/locales/pt';
import {registerLocaleData } from '@angular/common';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxSpinnerModule } from "ngx-spinner";

import '@volvo/vcdk'; //

//Angular Material Modules
import {MatCardModule} from '@angular/material/card';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatSelectModule} from '@angular/material/select';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {MatNativeDateModule, DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { OverlayModule } from '@angular/cdk/overlay';
import {MatChipsModule} from '@angular/material/chips';
import {MatTabsModule} from '@angular/material/tabs';
import { PartRestrictionGridComponent } from './modules/part-restriction/component/part-restriction-grid/part-restriction-grid.component';
import { PartRestrictionImportComponent } from './modules/part-restriction/component/part-restriction-import/part-restriction-import.component';
import { PartRestrictionComponent } from './modules/part-restriction/component/part-restriction.component';
import { MatSortModule } from '@angular/material/sort';
import { UserDealerComponent } from './modules/user-dealer/component/user-dealer.component';
import { UserDealerGridComponent } from './modules/user-dealer/component/user-dealer-grid/user-dealer-grid.component';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UserDealerModalComponent } from './modules/user-dealer/component/user-dealer-modal/user-dealer-modal.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ConfirmationDialogComponent } from './shared/confirmation-dialog/confirmation-dialog.component';
import { PromotionCreateComponent } from './modules/promotion/component/promotion-create/promotion-create.component';
import { PromotionDetailComponent } from './modules/promotion/component/promotion-detail/promotion-detail.component';
import { PromotionGridComponent } from './modules/promotion/component/promotion-grid/promotion-grid.component';
import { PartPromotionComponent } from './modules/part-promotion/component/part-promotion/part-promotion.component';
import { PartPromotionCreateComponent } from './modules/part-promotion/component/part-promotion-create/part-promotion-create.component';
import { PartPromotionGridComponent } from './modules/part-promotion/component/part-promotion-grid/part-promotion-grid.component';
import { PartPromotionImportComponent } from './modules/part-promotion/component/part-promotion-import/part-promotion-import.component';
import { PromotionComponent } from './modules/promotion/component/promotion/promotion.component';
import { CurrencyWithoutSymbolPipe } from './shared/utils/currency-without-symbol.pipe';
import { NumberWithoutRoundingPipe } from './shared/utils/number-without-rounding.pipe';
import { PercentagePipe } from './shared/utils/percentage.pipe';
import { PartStatusPipe } from './shared/utils/part-status.pipe';
import { PromotionNewsletterCreateComponent } from './modules/promotion-newsletter/component/promotion-newsletter-create/promotion-newsletter-create.component';
import { PromotionNewsletterDeleteComponent } from './modules/promotion-newsletter/component/promotion-newsletter-delete/promotion-newsletter-delete.component';
import { NgxCurrencyDirective } from 'ngx-currency';
import { ConfirmationDeleteReasonDialogComponent } from './shared/confirmation-delete-reason-dialog/confirmation-delete-reason-dialog.component';
import { NewQuotationComponent } from './modules/quotation/component/new-quotation/new-quotation.component';
import { QuotationComponent } from './modules/quotation/component/quotation.component';
import { CopyQuotationComponent } from './modules/quotation/component/copy-quotation/copy-quotation.component';
import { PartPromotionDeleteComponent } from './modules/part-promotion/component/part-promotion-delete/part-promotion-delete.component';
import { QuotationGridComponent } from './modules/quotation/component/quotation-grid/quotation-grid.component';
import { QuotationGridFilterComponent } from './modules/quotation/component/quotation-grid-filter/quotation-grid-filter.component';
import { ParametersCommercialComponent } from './modules/parameters/component/parameters-commercial/parameters-commercial.component';
import { ParametersProductsAndPricesComponent } from './modules/parameters/component/parameters-products-and-prices/parameters-products-and-prices.component';
import { ParametersQuotationComponent } from './modules/parameters/component/parameters-commercial/parameters-quotation/parameters-quotation.component';
import { ParametersPlanningComponent } from './modules/parameters/component/parameters-commercial/parameters-planning/parameters-planning.component';
import { CreatePlanningComponent } from './modules/planning/component/create-planning/create-planning.component';
import { PlanningGridComponent } from './modules/planning/component/planning-grid/planning-grid.component';
import { PlanningGridFilterComponent } from './modules/planning/component/planning-grid-filter/planning-grid-filter.component';
import { ChooseQuotationComponent } from './modules/planning/component/choose-quotation/choose-quotation.component';
import { BlockedPartsComponent } from './modules/quotation/component/blocked-parts/blocked-parts.component';
import { WarningsPartComponent } from './shared/component/warnings-part/warnings-part.component';
import { PartOtherPlanningsComponent } from './shared/component/part-other-plannings/part-other-plannings.component';
import { PartDetailsComponent } from './modules/planning/component/part-details/part-details.component';
import { DeletePlanningComponent } from './modules/planning/component/delete-planning/delete-planning.component';
import { PartsInformationComponent } from './modules/planning/component/parts-information/parts-information.component';
import { PlanningInconsistenciesComponent } from './modules/planning/component/planning-inconsistencies/planning-inconsistencies.component';
import { QuotationDialogComponent } from './modules/planning/component/quotation-dialog/quotation-dialog.component';
import { KitListComponent } from './shared/component/kit-list/kit-list.component';
import { PlanningDialogComponent } from './modules/quotation/component/planning-dialog/planning-dialog.component';
import { PartPromotionDataPipe } from './shared/utils/part-promotion-data.pipe';
import { ParametersSmlComponent } from './modules/parameters/component/parameters-sml/parameters-sml.component';
import { ParametersSystemsComponent } from './modules/parameters/component/parameters-systems/parameters-systems.component';
import { AppInsightsService } from 'src/azure-monitor';
import { MessageListModalComponent } from './shared/component/message-list-modal/message-list-modal.component';

registerLocaleData(localePt);

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
const protectedResourceMap = new Map<string, Array<string>>();
protectedResourceMap.set("https://graph.microsoft.com/v1.0/me", ["user.read"]);

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.clientId,
      authority: environment.authority,
      redirectUri: environment.redirectUri,
      postLogoutRedirectUri: environment.postLogoutRedirectUri
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read']
    }
  };
}


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".txt");
}


@NgModule({
  declarations: [
    AppComponent,
    PartRestrictionComponent,
    PartRestrictionGridComponent,
    PartRestrictionImportComponent,
    UserDealerComponent,
    UserDealerGridComponent,
    UserDealerModalComponent,
    ConfirmationDialogComponent,
    PromotionComponent,
    PromotionCreateComponent,
    PromotionDetailComponent,
    PromotionGridComponent,
    PartPromotionComponent,
    PartPromotionCreateComponent,
    PartPromotionGridComponent,
    PartPromotionImportComponent,
    CurrencyWithoutSymbolPipe,
    NumberWithoutRoundingPipe,
    PercentagePipe,
    PartStatusPipe,
    PromotionNewsletterCreateComponent,
    PromotionNewsletterDeleteComponent,
    ConfirmationDeleteReasonDialogComponent,
    QuotationComponent,
    NewQuotationComponent,
    CopyQuotationComponent,
    PartPromotionDeleteComponent,
    QuotationGridComponent,
    QuotationGridFilterComponent,
    ParametersCommercialComponent,
    ParametersProductsAndPricesComponent,
    ParametersQuotationComponent,
    ParametersPlanningComponent,
    CreatePlanningComponent,
    PlanningGridComponent,
    PlanningGridFilterComponent,
    ChooseQuotationComponent,
    BlockedPartsComponent,
    WarningsPartComponent,
    PartOtherPlanningsComponent,
    PartDetailsComponent,
    DeletePlanningComponent,
    PartsInformationComponent,
    PlanningInconsistenciesComponent,
    QuotationDialogComponent,
    KitListComponent,
    PlanningDialogComponent,
    PartPromotionDataPipe,
    ParametersSmlComponent,
    ParametersSystemsComponent,
    MessageListModalComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    NavigationModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MsalModule,
    NgxSpinnerModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    MatListModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatExpansionModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatTableModule,
    MatPaginatorModule,
    OverlayModule,
    MatChipsModule,
    MatTabsModule,
    MatSortModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
  }),
    NgbModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgxCurrencyDirective
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: AppInsightsService,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
     {
       provide: MSAL_INSTANCE,
       useFactory: MSALInstanceFactory
     },
     {
       provide: MSAL_GUARD_CONFIG,
       useFactory: MSALGuardConfigFactory
     },
     {
       provide: MSAL_INTERCEPTOR_CONFIG,
       useFactory: MSALInterceptorConfigFactory
     },

    {
      provide: LOCALE_ID,
      useValue: 'pt-BR'
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    HttpClient,
    NgbActiveModal
  ],
  exports: [
    TranslateModule
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }


