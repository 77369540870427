<div class="div-card-principal">
  <div class="row col-sm-12">
    <div class="col-sm-9">
      <h1 class="brand-title">{{'Parâmetros - Comercial' | translate}}</h1>
    </div>
  </div>
  <div class="separator-breadcrumb border-top"></div>
  <div>
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['../home']" routerLinkActive="router-link-active">{{'Home' | translate}}</a>
        </li>
        <li class="active breadcrumb-item" aria-current="page">{{'Parâmetros - Comercial' | translate}}</li>
      </ol>
    </nav>
  </div>
  <app-not-found *ngIf="!isSalesRepresentativeAdmin" firstMessage="Apenas Representante Comercial com perfil administrador tem acesso a esta função." secondMessage="Caso você precise de acesso, deverá solicitar via Service Now."></app-not-found>
  <div *ngIf="isSalesRepresentativeAdmin" class="div-tab-group">
    <mat-tab-group animationDuration="100ms" (selectedTabChange)="onTabChanged()">
      <mat-tab>
        <ng-template mat-tab-label>
          <volvo-typography variant='heading6'>
            {{'Orçamento' | translate}}
          </volvo-typography>
        </ng-template>
        <ng-template matTabContent>
          <app-parameters-quotation [commercialParameters]="commercialParameters" (changeInvalidFunctionalBox)="onChangeInvalidFunctionalBox($event)" (saveEvent)="onSaveClick()" (editEvent)="onEdit()" (goBackEvent)="onBackClick()"></app-parameters-quotation>
        </ng-template>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <volvo-typography variant='heading6'>
            {{'Planejamento' | translate}}
          </volvo-typography>
        </ng-template>
        <ng-template matTabContent>
          <app-parameters-planning [dealerGroups]="dealerGroups" [dataSource]="dataSource" [commercialParameters]="commercialParameters" (saveEvent)="onSaveClick()" (editEvent)="onEdit()" (goBackEvent)="onBackClick()"></app-parameters-planning>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
