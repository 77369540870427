export class TimezoneUtils{
  constructor(){}

  getTimezoneOffset(): number{
    return this.getHoursTimezoneOffset();
  }

  getMinutesTimezoneOffset(): number{
    return new Date().getTimezoneOffset();
  }

  getHoursTimezoneOffset(): number{
    return this.getMinutesTimezoneOffset() / 60;
  }
}
