<div class="modal-header">
  <h1 class="brand-title">{{ 'Atenção' | translate }}</h1>
  <button type="button" class="close" aria-label="Close" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <span style="white-space: pre-wrap">{{ 'Você está prestes a sair do planejamento e acessar o orçamento correspondente.' | translate }}</span>
  </div>
  <div class="row second-row">
    <span style="white-space: pre-wrap">{{ 'Caso tenha alterado o planejamento, você deseja salvar ou descartar as alterações?' | translate }}</span>
  </div>
</div>
<div class="modal-footer">
  <volvo-button variant="primary" class="button-icon" (click)="save()">{{ 'Salvar' | translate }}</volvo-button>
  <volvo-button variant="primary" class="button-icon" (click)="discard()">{{ 'Descartar' | translate }}</volvo-button>
  <volvo-button variant="primary" class="button-icon" (click)="dismiss()">{{ 'Cancelar' | translate }}</volvo-button>
</div>
