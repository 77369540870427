import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { GridDataSource, PagedRequest } from 'src/app/services/grid-data-source.service';
import { GetTranslationService } from 'src/app/shared/utils/get-translation.service';
import { LocalStorageUtils } from 'src/app/shared/utils/localstorage';
import { SetDefaultLanguageService } from 'src/app/shared/utils/setDefaultLanguage';
import { Sort } from '@angular/material/sort';
import { UserDealerService } from '../../services/user-dealer.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Dealer, DealerGroup, User } from '../../models/user-dealer';
import { MatPaginator } from '@angular/material/paginator';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserDealerModalComponent } from '../user-dealer-modal/user-dealer-modal.component';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-user-dealer-grid',
  templateUrl: './user-dealer-grid.component.html',
  styleUrls: ['./user-dealer-grid.component.css'],
    animations: [
      trigger('detailExpand', [
        state('collapsed', style({ height: '0px', minHeight: '0' })),
        state('expanded', style({ height: '*' })),
        transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      ]),
    ],
})
export class UserDealerGridComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator!: MatPaginator;

  filterObject: any = {};
  userDealers: User[] = [];
  public LocalStorage = new LocalStorageUtils();

  displayedColumns: string[] = ['name', 'expand'];
  displayedExpandedColumns: string[] = ['dealerGroupName','corporateName'];
  dataSource = new MatTableDataSource<User>(this.userDealers);
  icon = "chevron-down";
  dealerGroupFilters!: DealerGroup[];
  dealerList!: Dealer[];
  dealerFilters!: Dealer[];
  dealerGroupDropdownSettings!: IDropdownSettings;
  dealerDropdownSettings!: IDropdownSettings;
  selectedDealerGroup!: DealerGroup[];
  selectedDealer!: Dealer[];
  selectedDealerGroupId!: string;
  selectedDealerId!: string;
  existingIds!: string[];

  constructor(
    public service: GridDataSource<User>,
    public dataService: UserDealerService,
    private translateService: TranslateService,
    private setLang: SetDefaultLanguageService,
    private spinner: NgxSpinnerService,
    private getTranslationSvc: GetTranslationService,
    private modalService: NgbModal
  ) {
    this.translateService.setDefaultLang(this.setLang.setDefaultLanguage());
    this.translateService.use(localStorage.getItem('language') || this.setLang.setDefaultLanguage());

    var _dataService = this.dataService;
    this.service.configureDataSource((request: PagedRequest<User>) => _dataService.getAllUserDealer(request.page, this.paginator.pageSize, this.filterObject));
    this.service.datasource.subscribe(
      response => {
       this.spinner.show();
       this.userDealers = response;
       this.setExistingIds();
       this.refresh();
       this.dataSource = new MatTableDataSource<User>(this.userDealers);
       this.spinner.hide();
      });
  }

  ngOnInit(): void {
    // this.spinner.show();
    this.initializeSettings();
    this.fillDealerGroup();
    // setTimeout(() => {
    //   this.spinner.hide();
    // }, 1000);
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction == 'asc') {
      this.filterObject.fieldNameOrderBy = sortState.active;
      this.filterObject.isOrderByDesc = false;
    } else if (sortState.direction == 'desc') {
      this.filterObject.fieldNameOrderBy = sortState.active;
      this.filterObject.isOrderByDesc = true;
    } else {
      this.filterObject.fieldNameOrderBy = 'id';
      this.filterObject.isOrderByDesc = false;
    }
    this.service.refresh();
  }

  toggleRow(element: { expanded: boolean; icon: string; }) {
    var action = !element.expanded;
    this.userDealers.forEach(row => {
      row.expanded = false;
      row.icon = "chevron-down";
    });
    element.expanded = action;
    if(action){
      element.icon = "chevron-up";
    }
  }

  refresh(){
    this.userDealers.forEach(row => {
      row.expanded = false;
      row.icon = "chevron-down";
    });
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  ngAfterViewChecked(){
    this.paginator._intl.itemsPerPageLabel = this.getTranslationSvc.getTranslation("Itens por página:");
    this.paginator._intl.firstPageLabel = this.getTranslationSvc.getTranslation("Primeira página");
    this.paginator._intl.lastPageLabel = this.getTranslationSvc.getTranslation("Última página");
    this.paginator._intl.nextPageLabel = this.getTranslationSvc.getTranslation("Próxima página");
    this.paginator._intl.previousPageLabel = this.getTranslationSvc.getTranslation("Página anterior");
  }

  onClickEdit(item: any){

  }

  openCreateModal() {
    const modalRef = this.modalService.open(UserDealerModalComponent, {size: 'lg' });
    modalRef.componentInstance.existingIds = this.existingIds;
    modalRef.componentInstance.updateMode = false;
    modalRef.result.then((response) => {
      if(response){
        this.clearFilters();
      }
    }, (reason) => { });
  }

  openUpdateModal(toUpdate: User) {
    const modalRef = this.modalService.open(UserDealerModalComponent, {size: 'lg' });
    modalRef.componentInstance.updateMode = true;
    modalRef.componentInstance.toUpdate = toUpdate;
    modalRef.result.then((response) => {
      if(response){
        this.clearFilters();
      }
    }, (reason) => { });
  }

  onDealerDeSelect(item: any){
    this.selectedDealerId = "";
    this.refreshGrid();
  }

  onDealerSelect(item: any){
    this.selectedDealerId = item.dealerId;
    this.refreshGrid();
  }

  onDealerGroupDeSelect(item: any){
    this.selectedDealerGroupId = "";
    this.selectedDealerId = "";
    this.selectedDealer = [];
    this.refreshGrid();
    this.addDealersFromGroups();
  }

  onDealerGroupSelect(item: any){
    this.selectedDealerGroupId = item.dealerGroupId;
    this.selectedDealerId = "";
    this.selectedDealer = [];
    this.refreshGrid();
    this.addDealersFromGroups();
  }

  initializeSettings() {
    this.fillDealers();
    this.dealerGroupDropdownSettings = {
      singleSelection: true,
      idField: 'dealerGroupId',
      textField: 'description',
      selectAllText: this.translateService.instant('Selecionar tudo'),
      unSelectAllText: this.translateService.instant('Limpar'),
      noDataAvailablePlaceholderText: this.translateService.instant('Nenhum dado encontrado'),
      itemsShowLimit: 1,
      allowSearchFilter: false,
      closeDropDownOnSelection: true
    };

    this.dealerDropdownSettings = {
      singleSelection: true,
      idField: 'dealerId',
      textField: 'corporateName',
      selectAllText: this.translateService.instant('Selecionar tudo'),
      unSelectAllText: this.translateService.instant('Limpar'),
      noDataAvailablePlaceholderText: this.translateService.instant('Selecione o grupo econômico'),
      itemsShowLimit: 1,
      allowSearchFilter: false,
      closeDropDownOnSelection: true
    };
  }

  fillDealerGroup(){
    this.spinner.show();
    this.dataService.listDealerGroups().subscribe(response => {
      this.dealerGroupFilters = response.data.data;
      this.spinner.hide();
    });
  }

  fillDealers(){
    this.dataService.listDealers().subscribe(response => {
      this.dealerList = response.data.data;
      this.addDealersFromGroups();
    });
  }

  addDealersFromGroups(){
    this.dealerFilters = [];

    if(!this.dealerList)
      return;

    if(!this.selectedDealerGroupId)
      return;

    if(this.selectedDealerGroupId === "")
      return;

    this.dealerFilters = this.dealerList.filter(d => d.dealerGroupId === this.selectedDealerGroupId)
  }

  refreshGrid(){
    this.spinner.show();
    this.setFilters();
    this.service.refresh();
    this.refresh();
    this.spinner.hide();
  }

  setFilters(){
    this.setDealerIdFilter();
    this.setDealerGroupIdFilter();
  }

  setDealerIdFilter(){
    delete this.filterObject.dealerId;

    if(!this.selectedDealerId)
      return;

    if(this.selectedDealerId === "")
      return;

    this.filterObject.dealerId = this.selectedDealerId;
  }

  setDealerGroupIdFilter(){
    delete this.filterObject.dealerGroupId;

    if(!this.selectedDealerGroupId)
      return;

    if(this.selectedDealerGroupId === "")
      return;

    this.filterObject.dealerGroupId = this.selectedDealerGroupId;
  }

  setExistingIds(){
    let actualIds = this.userDealers.map(x => x.userId);

    if(!this.existingIds)
      this.existingIds = actualIds;

    if(this.existingIds.length === 0)
      this.existingIds = actualIds;

    actualIds.forEach(id => {
      if (!this.existingIds.includes(id)) {
        this.existingIds.push(id);
      }
    });
  }

  clearFilters(){
    this.selectedDealerGroup = [];
    this.selectedDealerGroupId = "";
    this.selectedDealer = [];
    this.selectedDealerId = "";
    this.existingIds = [];

    this.refreshGrid();
  }
}
