<div class="search-header ">
    <div class="header-container">
      <div class="col-md-6 form-group mb-6">
        <label class="search-label" for="searchTerm">{{'Buscar' | translate}}</label>
        <div class="div-search">
          <input class="input-search" type="text" [(ngModel)]="searchTerm" (keyup.enter)="search()"
          placeholder="{{'Buscar por Código, Descrição, Preços e Desconto' | translate}}"
          ngbTooltip="{{'Para buscar, digite um dado, conforme título da tabela abaixo.' | translate }}">
          <volvo-system-icon class="icon-search" icon="search" size="24" (click)="search()"></volvo-system-icon>
        </div>
      </div>
      <div class="col-md-3 form-group mb-3">
      </div>
      <div class="col-md-3 form-group mb-3">
        <div class="div-export">
          <div class="div-export-icon" (click)="downloadReport()"><volvo-system-icon icon="export" size="24"></volvo-system-icon></div>
          <div class="div-export-span" (click)="downloadReport()"><span>{{'Gerar arquivo csv' | translate}}</span></div>
        </div>
      </div>

    </div>
  </div>
  <table mat-table [dataSource]="dataSource" id="dataSource"  matSort (matSortChange)="announceSortChange($event)">
    <ng-container matColumnDef="part.partCode">
      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort"> {{'Código' | translate}} </th>
      <td mat-cell *matCellDef="let partpromotion"> {{partpromotion.part.partCode}} </td>
    </ng-container>
    <ng-container matColumnDef="part.description">
      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort"> {{'Descrição' | translate}} </th>
      <td mat-cell *matCellDef="let partpromotion"> {{partpromotion.part.description}} </td>
    </ng-container>
    <ng-container matColumnDef="price">
      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by risk"> {{'R$ De' | translate}} </th>
      <td mat-cell *matCellDef="let partpromotion"> {{partpromotion.price | CurrencyWithoutSymbol:'BRL' }} </td>
    </ng-container>
    <ng-container matColumnDef="discountPrice">
      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort"> {{'R$ Por' | translate}} </th>
      <td mat-cell *matCellDef="let partpromotion"> {{partpromotion.discountPrice | CurrencyWithoutSymbol:'BRL'}} </td>
    </ng-container>
    <ng-container matColumnDef="discountPercent">
      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort"> {{'Desconto' | translate}} </th>
      <td mat-cell *matCellDef="let partpromotion"> {{partpromotion.discountPercent | percentage}} </td>
    </ng-container>
    <ng-container matColumnDef="isActive">
      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort"> {{'Status Peça' | translate}} </th>
      <td mat-cell *matCellDef="let partpromotion"> {{partpromotion.isActive | partStatus | translate}} </td>
    </ng-container>
    <ng-container matColumnDef="initialDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort"> {{'Vigente na promo em' | translate}} </th>
      <td mat-cell *matCellDef="let partpromotion"> {{getDate(partpromotion.initialDate)  | date: maskDate}} </td>
    </ng-container>
    <ng-container matColumnDef="finalDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort"> {{'Vigente na promo até' | translate}} </th>
      <td mat-cell *matCellDef="let partpromotion"> {{getFinalDate(partpromotion)  | date: maskDate}} </td>
    </ng-container>
    <ng-container matColumnDef="createdAt">
      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort"> {{'Inserida na promo em' | translate}} </th>
      <td mat-cell *matCellDef="let partpromotion"> {{getDate(partpromotion.createdAt)  | date: maskDate}} </td>
    </ng-container>
    <ng-container matColumnDef="updatedAt">
      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort"> {{'Removida da promo em' | translate}} </th>
      <td mat-cell *matCellDef="let partpromotion"> {{partpromotion.updatedAt  | date: maskDate}} </td>
    </ng-container>
    <ng-container matColumnDef="promotionId">
      <th mat-header-cell *matHeaderCellDef> </th>
      <td mat-cell *matCellDef="let partpromotion" class="td-delete"> <span class="span-delete" (click)="deletePartPromotion(partpromotion.id)"><i *ngIf="!(partpromotion.updatedAt) && isPricingManager && activePromotion"><volvo-system-icon icon="trash" size="24"></volvo-system-icon></i></span> </td>
    </ng-container>
   <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row  class="queue-element-row" *matRowDef="let row; columns: displayedColumns;"></tr>
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="displayedColumns.length">{{'Peça não encontrada.' | translate}}</td>
    </tr>
  </table>
  <mat-paginator [length]="service.total | async" [pageSizeOptions]="[10,20,50,100]" (page)="onPageChange($event)"
  showFirstLastButtons
  aria-label="Select page of parts">
  </mat-paginator>
