import { Component } from '@angular/core';
import { CommercialParameters } from '../../models/commercial-parameters';
import { CommercialParametersService } from 'src/app/shared/services/commercial-parameters.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ParametersService } from '../../services/parameters.service';
import { GetTranslationService } from 'src/app/shared/utils/get-translation.service';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageUtils } from 'src/app/shared/utils/localstorage';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-parameters-sml',
  templateUrl: './parameters-sml.component.html',
  styleUrls: ['./parameters-sml.component.css']
})
export class ParametersSmlComponent {

  commercialParameters!: CommercialParameters;
  loadedForecastLimit!: number;
  isLogisticManager = false;

  public LocalStorage = new LocalStorageUtils();

  constructor(
    public dataService: ParametersService,
    private spinner: NgxSpinnerService,
    public getTranslationSvc: GetTranslationService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private router:Router,
    public commercialParametersService: CommercialParametersService){

      this.setIsLogisticManager();

      commercialParametersService.getCommercialParameters().subscribe(response =>{
        this.spinner.show();
         this.commercialParameters = response.data;
         this.fillParameters();
         this.spinner.hide();
      });
  }

  fillParameters(): void {
    if(!this.commercialParameters){
      return;
    }

    this.loadedForecastLimit = this.commercialParameters.forecastLimit;
  }

  onForecastLimitBlur(event: Event) {
    this.commercialParameters.forecastLimit = this.sanitizeValue(event);
  }

  sanitizeValue(event: Event): number {
    const inputValue = (event.target as HTMLInputElement).value;
    const sanitizedValue = inputValue.split('.')[0].replace(/[^\d]/g, '');

    let numberSanitized = Number(sanitizedValue)

    if(numberSanitized > 100)
      numberSanitized = 100;

      return numberSanitized;
  }

  onBackClick(){
    if(this.commercialParameters.forecastLimit !== this.loadedForecastLimit){
      this.confirm(this.getTranslationSvc.getTranslation("Voltar"), this.getTranslationSvc.getTranslation("Foram realizadas alterações que não foram salvas. Para salvá-las é necessário clicar no botão Salvar. Deseja sair mesmo assim?"))
        .then((confirmed) => {
          if(confirmed)
            this.goBack();
            return;
        });
    } else {
      this.goBack();
    }
  }

  goBack(){
    this.router.navigate(['/home']);
  }

  confirm(
    title: string,
    message: string,
    btnOkText: string = this.getTranslationSvc.getTranslation("Sim"),
    btnCancelText: string = this.getTranslationSvc.getTranslation("Não"),
    dialogSize: 'sm' | 'lg' = 'lg'): Promise<boolean> {
    const modalRef = this.modalService.open(ConfirmationDialogComponent, { size: dialogSize });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.btnOkText = btnOkText;
    modalRef.componentInstance.btnCancelText = btnCancelText;

    return modalRef.result;
  }

  save(){
    this.spinner.show();
    var _dataService = this.dataService;
    _dataService.putCommercialParameters(this.commercialParameters).subscribe((success) =>{
      this.saveSuccess(success);
    },
    (error) =>{
      this.showErrorMessage(error);
    }
   );
  }

  saveSuccess(success: any){
    this.spinner.hide();
    if(!success.success)
      return;

    if(success.success == false)
      return;

    this.toastr.success(this.getTranslationSvc.getTranslation('Parâmetros salvos com sucesso!'), this.getTranslationSvc.getTranslation('Sucesso!'));
  }

  showErrorMessage(error: any){
    this.spinner.hide();

    if(error.error.message){
      this.toastr.error(error.error.message.toString(), this.getTranslationSvc.getTranslation('Erro'));
      return;
    }

    if(error.error.data){
      this.toastr.error(error.error.data.toString(), this.getTranslationSvc.getTranslation('Erro'));
      return;
    }

    if(!error.error.errors){
      this.toastr.error(this.getTranslationSvc.getTranslation('Um erro não identificado ocorreu'), this.getTranslationSvc.getTranslation('Erro'));
    }

    if(!error.error.errors.title){
      this.toastr.error(this.getTranslationSvc.getTranslation('Um erro não identificado ocorreu'), this.getTranslationSvc.getTranslation('Erro'));
    }

    this.toastr.error(error.error.errors.title.toString(), this.getTranslationSvc.getTranslation('Erro'));
  }

  setIsLogisticManager(){
    this.isLogisticManager = this.LocalStorage.searchGroup(environment.logisticManagerGroupId);
  }
}
