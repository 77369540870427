import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { QuotationService } from '../../services/quotation.service';
import { PartQuotation } from 'src/app/shared/models/partQuotation';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-blocked-parts',
  templateUrl: './blocked-parts.component.html',
  styleUrls: ['./blocked-parts.component.css']
})
export class BlockedPartsComponent implements OnInit {
  @Input() partsQuotation: PartQuotation[] = [];
  dataSource = new MatTableDataSource<PartQuotation>(this.partsQuotation);
  displayedColumns: string[] = ['partCode', 'description', 'reason'];

  constructor(private translateService: TranslateService,
    private modalService: NgbModal,
    private dataService: QuotationService,
    private toastr: ToastrService){
    this.dataSource = new MatTableDataSource<PartQuotation>(this.partsQuotation);
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource<PartQuotation>(this.partsQuotation);
    this.setPartsQuotationWarnings();
  }

  onCloseClick(){
    this.confirm(this.translateService.instant("Atenção"), this.translateService.instant("Ao fechar esta tela, não será mais possível verificar a lista de peças não importadas/copiadas nesse orçamento, somente o relatório completo de peças com bloqueio disponível em Consultas/Lista de peças com bloqueios. Deseja continuar?"))
        .then((confirmed) => {
          if(confirmed)
            this.closeModal();
        });
  }

  confirm(
    title: string,
    message: string,
    btnOkText: string = this.translateService.instant("Sim"),
    btnCancelText: string = this.translateService.instant("Não"),
    dialogSize: 'sm' | 'lg' = 'lg'): Promise<boolean> {
    const modalRef = this.modalService.open(ConfirmationDialogComponent, { size: dialogSize });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.btnOkText = btnOkText;
    modalRef.componentInstance.btnCancelText = btnCancelText;

    return modalRef.result;
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  downloadReport() {
    this.confirm(this.translateService.instant("Atenção"), this.translateService.instant("Deseja fazer download do arquivo .csv?"))
      .then((confirmed) => {
        if(confirmed)
          this.getImportPartsWithReason();
      });
	}

  getImportPartsWithReason(){
    this.dataService.getImportPartsWithReason(this.partsQuotation).subscribe(
      response => {
        let fileName = this.parseHeaders(response.headers)
        this.dataService.downloadFile(response.body, 'text/csv', fileName);
      }, error => {
        this.toastr.error(this.translateService.instant("Problema ao tentar fazer download do relatório"), this.translateService.instant('Erro'), { timeOut: 3000, extendedTimeOut: 3000 });
      })
  }

  parseHeaders(responseHeader: any) {
		let contentDisposition = responseHeader.get('content-disposition');
		return contentDisposition.split(';')[1].split('=')[1];
	}

  setPartsQuotationWarnings(){
    this.partsQuotation.forEach((pq) => {
      pq.warningsPart = pq.warningsPart ? pq.warningsPart : [];
    });
  }
}
