<div class="div-card-principal">
  <h1 class="brand-title">{{headerTitle}}</h1>
  <div class="separator-breadcrumb border-top"></div>
  <div>
    <div class="div-breadcrumb">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a [routerLink]="['/home']" routerLinkActive="router-link-active">{{'Home'}}</a>
          </li>
          <li class="breadcrumb-item"><a [routerLink]="['/quotation']" routerLinkActive="router-link-active">{{'Gerenciar Orçamentos' | translate}}</a>
          </li>
          <li class="active breadcrumb-item" aria-current="page">{{headerTitle}}</li>
        </ol>
      </nav>
      <div class="div-export" *ngIf="canAccess">
        <div class="div-export-icon" (click)="downloadReport()"><volvo-system-icon icon="export" size="24"></volvo-system-icon></div>
        <div class="div-export-span" (click)="downloadReport()"><span>{{'Gerar arquivo csv' | translate}}</span></div>
      </div>
    </div>
    <app-not-found *ngIf="!canAccess" firstMessage="Apenas Representante Comercial tem acesso a esta função." secondMessage="Caso você precise de acesso, deverá solicitar via Service Now."></app-not-found>
    <app-new-quotation #newQuotationComponent (setHeaderTitle)="setHeaderTitle($event)" *ngIf="canAccess"></app-new-quotation>
  </div>
</div>
