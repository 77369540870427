import { DatePipe, DecimalPipe } from '@angular/common';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { GridDataSource, PagedRequest } from 'src/app/services/grid-data-source.service';
import {Sort} from '@angular/material/sort';
import { Promotion } from '../../models/promotion';
import { LocalStorageUtils } from 'src/app/shared/utils/localstorage';
import { MatTableDataSource } from '@angular/material/table';
import { PromotionService } from '../../services/promotion.service';
import { TranslateService } from '@ngx-translate/core';
import { SetDefaultLanguageService } from 'src/app/shared/utils/setDefaultLanguage';
import { NgxSpinnerService } from 'ngx-spinner';
import { GetTranslationService } from 'src/app/shared/utils/get-translation.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { PromotionCreateComponent } from '../promotion-create/promotion-create.component';
import { PromotionNewsletterCreateComponent } from 'src/app/modules/promotion-newsletter/component/promotion-newsletter-create/promotion-newsletter-create.component';
import { MatPaginator } from '@angular/material/paginator';
import { HttpClient } from '@angular/common/http';
import { ProfileType } from 'src/app/Core/Navigation/Component/profile/profileTypeModel';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
  selector: 'app-promotion-grid',
  templateUrl: './promotion-grid.component.html',
  styleUrls: ['./promotion-grid.component.css'],
  providers: [GridDataSource, DecimalPipe]
})
export class PromotionGridComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator!: MatPaginator;
  @Output() clickMenuItem = new EventEmitter<string>();

  maskDate: string = 'dd/MM/YYYY';
  panelOpenState = false;
  displayedColumns: string[] = ['promotionType.name', 'name', 'initialDate', 'finalDate', 'promotionStatus.name', 'updatedAt', 'newsletterSendDate'];
  filterObject: any = {};
  promotion: Promotion[] = [];
  updatedAt!: Date;
  showLastUpdate = false;
  public LocalStorage = new LocalStorageUtils();
  isPricingManager = false;
  labelRegisterNewsletter: string = '';
  registedNewsletter = false;
  profile!:ProfileType;
  searchTerm!: string;
  datePipe: DatePipe = new DatePipe('en-US');
  initialDateValue: Date|null = null;
  finalDateValue: Date|null = null;
  validDates: boolean = true;
  minDate!: Date;
  maxDate!: Date;

  dataSource = new MatTableDataSource<Promotion>(this.promotion);

  constructor(
    private http: HttpClient,
    public service: GridDataSource<Promotion>,
    public dataService: PromotionService,
    private translateService: TranslateService,
    private setLang: SetDefaultLanguageService,
    private spinner: NgxSpinnerService,
    private getTranslationSvc: GetTranslationService,
    private toastr: ToastrService,
    private modalService: NgbModal
  ) {
    this.setIsPricingManager();
    this.translateService.setDefaultLang(this.setLang.setDefaultLanguage());
    this.translateService.use(localStorage.getItem('language') || this.setLang.setDefaultLanguage());

    var _dataService = this.dataService;
    this.filterObject.limit = 10;
    this.service.configureDataSource((request: PagedRequest<Promotion>) => _dataService.getAllPromotion(this.filterObject));
    this.service.datasource.subscribe(
      response => {
       this.spinner.show();
       this.promotion = response;

       this.promotion.forEach(promo => {
        if (promo.newsletterSendDate) {
          const newsletterSendDate = this.datePipe.transform(promo.newsletterSendDate, 'yyyy-MM-dd')?.toString();
          const invalidDate = '0001-01-01';
          if(newsletterSendDate === invalidDate){
            promo.newsletterSendDate = null;
          }
        }
      });

       this.dataSource = new MatTableDataSource<Promotion>(this.promotion);
       this.lastUpdated();
       setTimeout(() => {
         this.spinner.hide();
       }, 1000);
      });
  }

  ngOnInit(): void {
    this.clickMenuItem.emit('promotion');
    this.getProfile();
    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
    }, 1000);

  }

  getProfile() {
    this.http.get<ProfileType>(environment.GRAPH_ENDPOINT)
      .subscribe(profile => {
        this.profile = profile;
        this.dataService.getByEmailNewsletter(this.profile.mail).subscribe(
          () =>{
            this.registedNewsletter = true;
            this.labelRegisterNewsletter = this.getTranslationSvc.getTranslation("Deixar de receber boletim")
          },
          () =>{
            this.registedNewsletter = false;
            this.labelRegisterNewsletter = this.getTranslationSvc.getTranslation("Receber boletim")
          }
        );
      });

  }

  search() {
    if(!this.validDates)
      return;

    delete this.filterObject.searchKey;
    if(this.searchTerm)
      this.filterObject.searchKey = this.searchTerm;
    this.refreshGrid();
  }


  refreshGrid(){
    this.filterObject.limit = this.paginator.pageSize;
    this.service.refresh();
    this.lastUpdated();
  }

  lastUpdated(){
    this.showLastUpdate = false;
    if(this.promotion.length > 0){
      this.updatedAt = this.promotion[0].initialDate;
      this.showLastUpdate = true;
    }
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction == 'asc') {
      this.filterObject.fieldNameOrderBy = sortState.active;
      this.filterObject.isOrderByDesc = false;
    } else if (sortState.direction == 'desc') {
      this.filterObject.fieldNameOrderBy = sortState.active;
      this.filterObject.isOrderByDesc = true;
    } else {
      this.filterObject.fieldNameOrderBy = 'id';
      this.filterObject.isOrderByDesc = false;
    }
    this.refreshGrid();
  }

  parseHeaders(responseHeader: any) {
		let contentDisposition = responseHeader.get('content-disposition');
		return contentDisposition.split(';')[1].split('=')[1];
	}

  openCreateModal() {
    this.modalService.open(PromotionCreateComponent, { size: 'lg' });
  }

  openNewsletterModal(){
    if(this.registedNewsletter){
      this.spinner.show();
      this.dataService.deleteNewsletter(this.profile.mail).subscribe({
        next: (success: any) =>{
          this.spinner.hide();
          this.toastr.success(success.message,this.getTranslationSvc.getTranslation('Sucesso'), { timeOut: 5000, extendedTimeOut: 5000, enableHtml: true })
        },
        error: (error: any) => {
          this.spinner.hide();
          this.toastr.error(error.error.message, this.getTranslationSvc.getTranslation('Erro'), { timeOut: 5000, extendedTimeOut: 5000 })
        }
      });
    }
    else
      this.modalService.open(PromotionNewsletterCreateComponent, { size: 'lg' });
  }

  ngAfterViewChecked(){
    this.paginator._intl.itemsPerPageLabel = this.getTranslationSvc.getTranslation("Itens por página:");
    this.paginator._intl.firstPageLabel = this.getTranslationSvc.getTranslation("Primeira página");
    this.paginator._intl.lastPageLabel = this.getTranslationSvc.getTranslation("Última página");
    this.paginator._intl.nextPageLabel = this.getTranslationSvc.getTranslation("Próxima página");
    this.paginator._intl.previousPageLabel = this.getTranslationSvc.getTranslation("Página anterior");
  }

  onStatusSelect(item: any) {

    this.refreshGrid();
  }

  onPageChange(event: any) {
    this.filterObject.page = event.pageIndex+1
    this.refreshGrid();
  }

  setIsPricingManager(){
    this.isPricingManager = this.LocalStorage.searchGroup(environment.PRINCING_MANAGER_GROUP_ID);
  }

  setInitialDate(type: string, event: MatDatepickerInputEvent<Date>) {
    delete this.filterObject.initialDate;
    this.initialDateValue = event.value;
    if(this.initialDateValue){
      this.filterObject.initialDate = this.datePipe.transform(this.initialDateValue, 'yyyy-MM-dd');
      this.minDate = new Date(this.initialDateValue);
      this.minDate.setDate(this.minDate.getDate());
    }

    this.validateDates();
  }

  setFinalDate(type: string, event: MatDatepickerInputEvent<Date>) {
    delete this.filterObject.finalDate;
    this.finalDateValue = event.value;
    if(this.finalDateValue){
      this.filterObject.finalDate = this.datePipe.transform(this.finalDateValue, 'yyyy-MM-dd');
      this.maxDate = new Date(this.finalDateValue);
      this.maxDate.setDate(this.maxDate.getDate());
    }

    this.validateDates();
  }

  validateDates(){
    this.validDates = true;

    if(!this.initialDateValue)
      return;

    if(!this.finalDateValue)
      return;

    if(this.initialDateValue > this.finalDateValue)
    this.validDates = false;
  }
}
