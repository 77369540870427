import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PartPromotionService } from '../../services/part-promotion.service';
import { ToastrService } from 'ngx-toastr';
import { GetTranslationService } from 'src/app/shared/utils/get-translation.service';

@Component({
  selector: 'app-part-promotion-delete',
  templateUrl: './part-promotion-delete.component.html',
  styleUrls: ['./part-promotion-delete.component.css']
})
export class PartPromotionDeleteComponent {
  @Output() refreshGrid: EventEmitter<void> = new EventEmitter<void>();
  title!: string;
  message!: string;
  dateLabel!: string;
  partId!: string;
  promotionId!: string;
  buttonDisabled: boolean = true;
  initialDate: Date | null = null;
  datePipe: DatePipe = new DatePipe('en-US');

  constructor(
    private modalService: NgbModal,
    public dataService: PartPromotionService,
    private toastr: ToastrService,
    public getTranslationSvc: GetTranslationService){
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  save(){
    const date = this.datePipe.transform(this.initialDate, 'yyyy-MM-dd');

    if(!date)
      return;

    this.dataService.deletePartPromotion(this.partId, date.toString(), this.promotionId).subscribe(
            (success) =>{
              this.checkSuccess(success);
            },
            (error) =>{
              this.checkError(error);
            }
           );
  }

  checkSuccess(success: any){
    this.toastr.success(success.message,this.getTranslationSvc.getTranslation('Sucesso!'));
    this.refreshGrid.emit();
    this.closeModal();
  }

  checkError(error: any){
    if(error.error.data){
      this.toastr.error(error.error.data.toString(), this.getTranslationSvc.getTranslation('Erro'));
      return;
    }

    if(error.error.message){
      this.toastr.error(error.error.message, this.getTranslationSvc.getTranslation('Erro'));
      return;
    }

    this.toastr.error(this.getTranslationSvc.getTranslation('Não foi possível realizar a atualização'), this.getTranslationSvc.getTranslation('Erro'));
  }
  revalidateForm(){
    this.buttonDisabled = true;
    let year = this.initialDate?.getFullYear();

    if(!year)
      return;

    if(year < 2000 || year > 2100)
      return;

    this.buttonDisabled = false;
  }

  setDate() {
    this.revalidateForm();
  }
}
