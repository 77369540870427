<div class="div-card-principal">
  <div class="row">
    <h1 class="brand-title">{{title | translate}}</h1>
    <div class="separator-breadcrumb border-top"></div>
  </div>
  <div>
    <div>
      {{message | translate}}
    </div>
    <div class="date-picker-container">
      <label>{{dateLabel | translate}}</label>
      <div class="date-filters">
        <mat-form-field class="date-filter">
          <input matInput
          [matDatepicker]="pickerInitial"
          (click)="pickerInitial.open()"
          [placeholder]="'Data' | translate"
          (blur)="revalidateForm()"
          (dateInput)="setDate()"
          [(ngModel)]="initialDate">
          <mat-datepicker-toggle matSuffix [for]="pickerInitial"></mat-datepicker-toggle>
          <mat-datepicker #pickerInitial></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
    <div class="button-panel">
      <div class="div-button">
        <volvo-button variant="secondary" class="button-icon" (click)="closeModal()">{{'Não' | translate}}</volvo-button>
        <div class="div-spacing"></div>
        <volvo-button variant="primary" class="button-icon" (click)="save()" [disabled]="buttonDisabled">{{'Sim' | translate}}</volvo-button>
      </div>
    </div>
  </div>
</div>
