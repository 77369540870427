<div class="modal-header">
  <h1 class="brand-title">{{ titleText }}</h1>
  <button type="button" class="close" aria-label="Close" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <span style="white-space: pre-wrap">{{ messageText }}</span>
  <div class="div-list">
    <ul>
      <li *ngFor="let message of messageList" clss="list-item">
        {{ message }}
      </li>
    </ul>
  </div>
</div>
<div class="modal-footer">
  <volvo-button variant="primary" class="button-icon" (click)="dismiss()">{{ 'Fechar' | translate }}</volvo-button>
</div>
