import { environment } from './environments/environment.dev';
import { Injectable } from '@angular/core';
import { ApplicationInsights, IEventTelemetry } from '@microsoft/applicationinsights-web';
 
@Injectable({
  providedIn: 'root',
})
export class AppInsightsService {
 
  private static appInsights: ApplicationInsights = new ApplicationInsights({
    config: {
      instrumentationKey: environment.APPLICATIONINSIGHTS_INSTRUMENTATION_KEY,
      enableAutoRouteTracking: true, // option to log all route changes
    },
  });
 
  public static loadAppInsights() {
    this.appInsights.loadAppInsights();
    this.appInsights.trackPageView();
  }
 
  public static logFeatureUsage(featureName: string) {
    this.appInsights.trackEvent({ name: featureName } as IEventTelemetry);
  }
}