<div class="div-card-principal">
  <h1 class="brand-title">{{'Informações adicionais' | translate}}</h1>
  <div class="separator-breadcrumb border-top"></div>
    <div>
      <div class="first-table">
        <div class="div-title">
          <volvo-typography variant='heading6'>
            {{'Informações de preço no fechamento do orçamento:' | translate}}
          </volvo-typography>
        </div>
        <div class="div-row">
          <div class="div-row-label">
            <volvo-typography variant='body'>
              {{'Código da peça:' | translate}}
            </volvo-typography>
          </div>
          <div class="div-row-value">
            <volvo-typography variant='body'>
              {{partPlanning?.partQuotation?.partCode}}
            </volvo-typography>
          </div>
        </div>
        <div class="div-row">
          <div class="div-row-label">
            <volvo-typography variant='body'>
              {{'Preço sem desconto:' | translate}}
            </volvo-typography>
          </div>
          <div class="div-row-value">
            <volvo-typography variant='body'>
              R$ {{partPlanning?.partQuotation?.priceWithoutDiscount | CurrencyWithoutSymbol:'BRL'}}
            </volvo-typography>
          </div>
        </div>
        <div class="div-row">
          <div class="div-row-label">
            <volvo-typography variant='body'>
              {{'Tipo de desconto:' | translate}}
            </volvo-typography>
          </div>
          <div class="div-row-value">
            <volvo-typography variant='body' *ngIf="partPlanning.partQuotation.fixedPrice > 0">
              {{'Preço Fixo' | translate}}
            </volvo-typography>
            <volvo-typography variant='body' *ngIf="partPlanning.partQuotation.discountPercent > 0">
              {{'Percentual' | translate}}
            </volvo-typography>
          </div>
        </div>
        <div class="div-row">
          <div class="div-row-label">
            <volvo-typography variant='body'>
              {{'% de desconto:' | translate}}
            </volvo-typography>
          </div>
          <div class="div-row-value">
            <volvo-typography variant='body'>
              {{partPlanning?.partQuotation?.discountPercent | CurrencyWithoutSymbol:'BRL'}} %
            </volvo-typography>
          </div>
        </div>
        <div class="div-row">
          <div class="div-row-label">
            <volvo-typography variant='body'>
              {{'Preço fixo:' | translate}}
            </volvo-typography>
          </div>
          <div class="div-row-value">
            <volvo-typography variant='body'>
              R$ {{partPlanning?.partQuotation?.fixedPrice | CurrencyWithoutSymbol:'BRL'}}
            </volvo-typography>
          </div>
        </div>
        <div class="div-row">
          <div class="div-row-label">
            <volvo-typography variant='body'>
              {{'Preço com desconto:' | translate}}
            </volvo-typography>
          </div>
          <div class="div-row-value">
            <volvo-typography variant='body'>
              R$ {{partPlanning?.partQuotation?.priceWithDiscount | CurrencyWithoutSymbol:'BRL'}}
            </volvo-typography>
          </div>
        </div>
      </div>
      <div class="margin-top-30">
        <div class="div-title">
          <volvo-typography variant='heading6'>
            {{'Informações gerais:' | translate}}
          </volvo-typography>
        </div>
        <div class="div-row">
          <div class="div-row-label">
            <volvo-typography variant='body' class="orange-text">
              {{'Preço atual da peça:' | translate}}
            </volvo-typography>
          </div>
          <div class="div-row-value">
            <volvo-typography variant='body' class="orange-text">
              R$ {{partPlanning?.partQuotation?.currentPartPrice | CurrencyWithoutSymbol:'BRL'}}
            </volvo-typography>
          </div>
        </div>
        <div class="div-row">
          <div class="div-row-label">
            <volvo-typography variant='body'>
              {{'Código e nome do programador da peça:' | translate}}
            </volvo-typography>
          </div>
          <div class="div-row-value">
            <volvo-typography variant='body'>
              {{partPlanning?.developerName}}
            </volvo-typography>
          </div>
        </div>
      </div>
      <div class="margin-top-30">
        <div class="div-title">
          <volvo-typography variant='heading6'>
            {{'Informações dos pedidos e NAs no CICS:' | translate}}
          </volvo-typography>
        </div>
      </div>
      <div class="div-table-info">
        <table>
          <thead>
            <tr class="table-header table-row">
              <th></th>
              <th *ngFor="let w of partPlanning.weeksPartPlanning" class="td-header table-cell">W{{w.week}}</th>
            </tr>
          </thead>
          <tbody>
            <tr class="table-row">
              <td class="first-column table-cell">{{'Orçam-Cics-Sorc' | translate}}</td>
              <td class="table-cell" *ngFor="let w of partPlanning.weeksPartPlanning">{{w.code ? w.code : '-'}}</td>
            </tr>
            <tr class="table-row">
              <td class="first-column  table-cell" >{{'NA Planej' | translate}}</td>
              <td class="table-cell" *ngFor="let w of partPlanning.weeksPartPlanning">{{w.nacics ? w.nacics : '-'}}</td>
            </tr>
            <tr class="table-row">
              <td class="first-column table-cell">{{'NA Pedj' | translate}}</td>
              <td class="table-cell" *ngFor="let w of partPlanning.weeksPartPlanning">{{w.codeOrder ? w.codeOrder : '-'}}</td>
            </tr>
            <tr class="table-row">
              <td class="first-column table-cell">{{'NA BO' | translate}}</td>
              <td class="table-cell" *ngFor="let w of partPlanning.weeksPartPlanning">{{w.codeBO ? w.codeBO : '-'}}</td>
            </tr>
            <tr class="table-row">
              <td class="first-column table-cell">{{'Preço Faturado' | translate}}</td>
              <td class="table-cell" *ngFor="let w of partPlanning.weeksPartPlanning">{{w.invoicedPrice ? (w.invoicedPrice | CurrencyWithoutSymbol:'BRL' ) : '-'}}</td>
            </tr>
            <tr class="table-row">
              <td class="first-column table-cell">{{'Qtd Faturado' | translate}}</td>
              <td class="table-cell" *ngFor="let w of partPlanning.weeksPartPlanning">{{w.invoicedAmount ? w.invoicedAmount : '-'}}</td>
            </tr>
            <tr class="table-row">
              <td class="first-column table-cell">{{'Qtd BO' | translate}}</td>
              <td class="table-cell" *ngFor="let w of partPlanning.weeksPartPlanning">{{w.boAmount ? w.boAmount : '-'}}</td>
            </tr>
            <tr class="table-row">
              <td class="first-column table-cell">{{'Qtd Plan' | translate}}</td>
              <td class="table-cell" *ngFor="let w of partPlanning.weeksPartPlanning">{{w.amount ? w.amount : '-'}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="button-panel">
        <volvo-button variant="primary" (click)="close()">{{'Fechar' | translate}}</volvo-button>
      </div>
    </div>
</div>
