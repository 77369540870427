import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Dealer, DealerGroup, User, UserDealer } from '../../models/user-dealer';
import { GetTranslationService } from 'src/app/shared/utils/get-translation.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { UserDealerService } from '../../services/user-dealer.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-user-dealer-modal',
  templateUrl: './user-dealer-modal.component.html',
  styleUrls: ['./user-dealer-modal.component.css']
})
export class UserDealerModalComponent {
  @Input() existingIds!: string[];
  @Input() updateMode!: boolean;
  @Input() toUpdate!: User;
  @ViewChild('falseClick') falseClick!: ElementRef<HTMLElement>;

  userDropdownSettings!: IDropdownSettings;
  dealerGroupDropdownSettings!: IDropdownSettings;
  dealerDropdownSettings!: IDropdownSettings;
  selectedUser!: User | null;
  userFilters!: User[];
  dealerGroupFilters!: DealerGroup[];
  dealerFilters!: Dealer[];
  dealerList!: Dealer[];
  selectedDealerGroup!: DealerGroup[];
  selectedDealerGroupIds!: string[];
  selectedDealer!: Dealer[];
  userDealer!: UserDealer;
  canSave: boolean = false;
  userDisable: boolean = false;
  userDropdownText!: string;
  dropdownText!: string;
  saveButtonTitle!: string;
  saveButtonMessage!: string;
  edited: boolean = false;

  constructor(
    private translateService: TranslateService,
    public getTranslationSvc: GetTranslationService,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    public dataService: UserDealerService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService){

    this.initializeSettings();
  }

  ngOnInit() {
    this.initializeSettings();
    this.fillUser();
    this.getDealersList();
    this.fillDealerGroup();
    this.setUpdateMode();
  }

  initializeSettings() {
    this.userDropdownText = this.getTranslationSvc.getTranslation("Selecione");
    this.dropdownText = this.getTranslationSvc.getTranslation("Selecione");

    this.userDropdownSettings = {
      singleSelection: true,
      idField: 'userId',
      textField: 'name',
      selectAllText: this.translateService.instant('Selecionar tudo'),
      unSelectAllText: this.translateService.instant('Limpar'),
      noDataAvailablePlaceholderText: this.translateService.instant('Nenhum dado encontrado'),
      itemsShowLimit: 2,
      allowSearchFilter: true,
      searchPlaceholderText: this.translateService.instant('Buscar'),
    };

    this.dealerGroupDropdownSettings = {
      singleSelection: false,
      idField: 'dealerGroupId',
      textField: 'description',
      selectAllText: this.translateService.instant('Selecionar tudo'),
      unSelectAllText: this.translateService.instant('Limpar'),
      noDataAvailablePlaceholderText: this.translateService.instant('Nenhum dado encontrado'),
      itemsShowLimit: 1,
      allowSearchFilter: true,
      searchPlaceholderText: this.translateService.instant('Buscar'),
    };

    this.dealerDropdownSettings = {
      singleSelection: false,
      idField: 'dealerId',
      textField: 'corporateName',
      selectAllText: this.translateService.instant('Selecionar tudo'),
      unSelectAllText: this.translateService.instant('Limpar'),
      noDataAvailablePlaceholderText: this.translateService.instant('Selecione o grupo econômico'),
      itemsShowLimit: 1,
      allowSearchFilter: true,
      searchPlaceholderText: this.translateService.instant('Buscar'),
    };
  }

  onUserSelect(item: any){
    this.edited = true;
    this.selectUser(item.userId);
    this.triggerFalseClick();
  }

  triggerFalseClick() {
    let el: HTMLElement = this.falseClick.nativeElement;
    el.click();
  }

  selectUser(userId: string){
    this.canSave = false;
    let userData = this.userFilters.find(u => u.userId === userId);
    if (userData !== undefined){
      this.selectedUser = userData;
      this.verifyCanSave();
      this.userDisable = this.updateMode;
    }
  }

  onUserDeSelect(item: any){
    this.edited = true;
    this.canSave = false;
    this.selectedUser = null;
  }

  onDealerSelect(item: any){
    this.edited = true;
    this.verifyCanSave();
  }

  onDealerGroupSelect(item: any){
    this.edited = true;
    this.fillDealers();
  }

  saveData(){
    this.confirm(this.saveButtonTitle, this.saveButtonMessage)
      .then((confirmed) => this.save(confirmed));
  }

  closeModal() {
    if(!this.edited){
      this.close(true);
      return;
    }

    this.confirm(this.getTranslationSvc.getTranslation("Cancelar"), this.getTranslationSvc.getTranslation("As alterações realizadas não serão salvas. Deseja continuar?"))
      .then((confirmed) => this.close(confirmed));
  }

  save(confirmed: boolean) {
    if (confirmed == true) {
      this.spinner.show();
      let dealersIds: string[] = [];
      if(this.selectedDealer){
        dealersIds = this.selectedDealer.map(d => d.dealerId);
      }

      if(!this.selectedUser)
        return;

      this.dataService.updateUserDealer({userId: this.selectedUser.userId, userName: this.selectedUser.name, userEmail: this.selectedUser.email, dealersId: dealersIds, createdBy: ''}).subscribe({
        next: (success: any) => this.processSuccess(success),
        error: (fail: any) => this.processSuccess(fail)
      });
    }
  }

  processSuccess(response: any) {
    this.spinner.hide();
    if (response?.success == true) {
      this.toastr.success(response.message,this.getTranslationSvc.getTranslation('Sucesso'), { timeOut: 5000, extendedTimeOut: 5000 });
      this.activeModal.close(true);
    } else if (response?.success == false) {
      this.toastr.error(response.message, this.getTranslationSvc.getTranslation('Erro'), { timeOut: 5000, extendedTimeOut: 5000 });
    } else {
      this.toastr.error(this.getTranslationSvc.getTranslation("Edição não realizada"),this.getTranslationSvc.getTranslation('Erro'), { timeOut: 5000, extendedTimeOut: 5000 });
    }
  }

  close(confirmed: boolean) {
    if (confirmed == true) {
      this.activeModal.close(false);
    }
  }

  confirm(
    title: string,
    message: string,
    btnOkText: string = this.getTranslationSvc.getTranslation("Sim"),
    btnCancelText: string = this.getTranslationSvc.getTranslation("Não"),
    dialogSize: 'sm' | 'lg' = 'lg'): Promise<boolean> {
    const modalRef = this.modalService.open(ConfirmationDialogComponent, { size: dialogSize });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.btnOkText = btnOkText;
    modalRef.componentInstance.btnCancelText = btnCancelText;

    return modalRef.result;
  }

  fillUser(){
    this.spinner.show();
    this.dataService.listUsers().subscribe(response => {
      let responseData = response.data.data;
      this.userFilters = [];
      for (const user of responseData) {
        if(this.updateMode && user.userId == this.toUpdate.userId){
          this.userFilters.push(user);
          this.selectUser(this.toUpdate.userId);
          this.userDropdownText = user.name;
        }
        if((!this.updateMode) && (!this.existingIds.includes(user.userId))){
          this.userFilters.push(user);
        }
      }
      this.spinner.hide();
    });
  }

  fillDealerGroup(){
    this.dataService.listDealerGroups().subscribe(response => {
      this.dealerGroupFilters = response.data.data;

      if(!this.updateMode)
        return;

      var dealerGroupsIds = this.toUpdate.dealers.map(x => x.dealerGroup.dealerGroupId);
      let selecteds = this.dealerGroupFilters.filter(d => dealerGroupsIds.includes(d.dealerGroupId));
      if(selecteds){

        this.selectedDealerGroup = [];
        selecteds.forEach(s => {this.selectedDealerGroup.push(s)});
      }
      this.fillDealers();
    });
  }

  getDealersList(){
    this.dataService.listDealers().subscribe(response => {
      this.dealerList = response.data.data;
      this.addDealersFromGroups();
    });
  }

  fillDealers(){
    this.dataService.listDealers().subscribe(response => {
      this.dealerList = response.data.data;
    });
    if(this.selectedDealerGroup)
      this.selectedDealerGroupIds = this.selectedDealerGroup.map(d => d.dealerGroupId);

    this.addDealersFromGroups();
  }

  fillDealersAll(select: boolean){
    this.selectedDealerGroupIds = [];

    if(select)
      this.selectedDealerGroupIds = this.dealerGroupFilters.map(d => d.dealerGroupId);

    this.addDealersFromGroups();
  }

  addDealersFromGroups(){
    if(!this.dealerList)
      return;

    if(!this.selectedDealerGroupIds)
      return;

    this.dealerFilters = this.dealerList.filter(d => this.selectedDealerGroupIds.includes(d.dealerGroupId))
    this.reloadSelectedDealer();
    this.loadSelectedDealerToUpdate()
  }

  reloadSelectedDealer(){
    if(!this.selectedDealer)
      return;

    let oldSelectedIds = this.selectedDealer.map(d => d.dealerId);
    this.selectedDealer = this.dealerFilters.filter(d => oldSelectedIds.includes(d.dealerId));
    this.verifyCanSave();
  }

  onDealerGroupSelectAll(select: boolean){
    this.edited = true;
    this.fillDealersAll(select);
  }

  loadSelectedDealerToUpdate(){
    if(!this.updateMode)
      return;

    let dealersToUpdateIds = this.toUpdate.dealers.map(d => d.dealerId);

    if(this.selectedDealer){
      dealersToUpdateIds = this.selectedDealer.map(d => d.dealerId);
    }

    this.selectedDealer = this.dealerFilters.filter(d => dealersToUpdateIds.includes(d.dealerId));

    this.verifyCanSave();
  }

  setUpdateMode(){
    this.saveButtonTitle = this.getTranslationSvc.getTranslation("Salvar");
    this.saveButtonMessage = this.getTranslationSvc.getTranslation("Deseja salvar a alteração?");

    if(this.updateMode)
      return;

    this.saveButtonTitle = this.getTranslationSvc.getTranslation("Vincular");
    this.saveButtonMessage = this.getTranslationSvc.getTranslation("O vínculo Usuário x Concessionária será salvo. Deseja continuar?");
  }

  verifyCanSave(){
    this.canSave = false;

    if(!this.selectedUser)
      return;

    if(!this.selectedUser.userId)
      return;

    if(this.updateMode) {
      this.canSave = true;
      return;
    }

    if(!this.selectedDealer)
      return;

    if(!(this.selectedDealer.length > 0))
      return;

    this.canSave = true;
  }

  onDealerSelectAll(item: any){
    this.selectedDealer = item;
    this.edited = true;
    this.verifyCanSave();
  }
}

