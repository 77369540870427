<div class="div-card-principal">
  <h1 class="brand-title">{{'Promoção de Peças' | translate}}</h1>
  <div class="separator-breadcrumb border-top"></div>
  <div>
    <div>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a [routerLink]="['../home']" routerLinkActive="router-link-active">{{'Home'}}</a></li>
          <li class="breadcrumb-item"><a [routerLink]="['../promotionGrid']" routerLinkActive="router-link-active">{{'Promoção de Peças' | translate}}</a></li>
          <li class="active breadcrumb-item" aria-current="page">{{'Nova Promoção' | translate}}</li>
        </ol>
      </nav>
    </div>
    <app-promotion-create *ngIf="isPricingManager"></app-promotion-create>
  </div>
</div>
