<div class="div-card-principal">
  <div class="row col-sm-12">
    <div class="col-sm-9">
      <h1 class="brand-title">{{'Parâmetros - SML' | translate}}</h1>
    </div>
  </div>
  <div class="separator-breadcrumb border-top"></div>
  <div>
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['../home']" routerLinkActive="router-link-active">{{'Home' | translate}}</a>
        </li>
        <li class="active breadcrumb-item" aria-current="page">{{'Parâmetros - SML' | translate}}</li>
      </ol>
    </nav>
  </div>
  <app-not-found *ngIf="!isLogisticManager" firstMessage="Apenas Logistic Manager tem acesso a esta função." secondMessage="Caso você precise de acesso, deverá solicitar via Service Now."></app-not-found>
  <div class="div-forecast" *ngIf="isLogisticManager">
    <div class="div-text">
      <volvo-typography variant='heading6'>
        {{'Limite baseado no prognóstico mensal de peças' | translate}}
      </volvo-typography>
      <volvo-system-icon icon="info" size="24" class="margin-left-10" ngbTooltip="{{'Tooltip Limite baseado no prognóstico mensal de peças' | translate }}"></volvo-system-icon>
    </div>
    <input class="input-week" type="number" [maxlength]="3" [(ngModel)]="commercialParameters.forecastLimit" min="0" max="100" step="1" (blur)="onForecastLimitBlur($event)">
      <div class="div-percent-text">
        <volvo-typography variant='subtitle2'>
          %
        </volvo-typography>
      </div>
  </div>
  <div class="div-filler" *ngIf="isLogisticManager"></div>
  <div class="row " *ngIf="isLogisticManager">
    <div class="div-button">
      <volvo-button variant="primary" size="large" (click)="onBackClick()" class="back-button">{{'Voltar' | translate}}</volvo-button>
      <volvo-button variant="primary" size="large" (click)="save()">{{'Salvar' | translate}}</volvo-button>
    </div>
  </div>
</div>
